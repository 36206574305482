import PropTypes from 'prop-types';
import { Box, Typography, Stack, Collapse, Divider } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useState } from 'react';
import Iconify from '../../../../components/Iconify';

const PlayerCard = ({ player, type, showTransition }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box
      sx={{
        p: 2,
        mb: 1,
        height: 76, // Fixed height when not expanded
        borderRadius: 1,
        position: 'relative',
        bgcolor: (theme) => {
          const color = type === 'left' ? theme.palette.error.main : 
                       type === 'joined' ? theme.palette.success.main : 
                       theme.palette.text.primary;
          return alpha(color, theme.palette.mode === 'dark' ? 0.2 : 0.1);
        },
        cursor: 'pointer',
        '&:hover': {
          bgcolor: (theme) => {
            const color = type === 'left' ? theme.palette.error.main : 
                         type === 'joined' ? theme.palette.success.main : 
                         theme.palette.text.primary;
            return alpha(color, theme.palette.mode === 'dark' ? 0.3 : 0.15);
          }
        }
      }}
      onClick={() => setExpanded(!expanded)}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <Iconify 
          icon={
            type === 'left' ? 'mdi:account-minus' :
            type === 'joined' ? 'mdi:account-plus' :
            'mdi:account'
          }
          sx={{ 
            color: type === 'left' ? 'error.main' :
                   type === 'joined' ? 'success.main' :
                   'text.secondary'
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle2">{player.name}</Typography>
          <Typography variant="caption" color="text.secondary">
            {player.email || 'No email'}
          </Typography>
        </Box>
        {showTransition && (
          <Box
            sx={{
              position: 'absolute',
              height: 2,
              width: 40,
              right: type === 'left' ? -40 : 'auto',
              left: type === 'joined' ? -40 : 'auto',
              top: '50%',
              transform: 'translateY(-50%)',
              bgcolor: type === 'left' ? 'error.main' : 'success.main',
              '&::after': {
                content: '""',
                position: 'absolute',
                right: -6,
                top: -4,
                borderStyle: 'solid',
                borderWidth: '5px 0 5px 8px',
                borderColor: (theme) => `transparent transparent transparent ${
                  type === 'left' ? theme.palette.error.main : theme.palette.success.main
                }`
              }
            }}
          />
        )}
      </Stack>

      <Collapse in={expanded}>
        <Divider sx={{ my: 2 }} />
        <Stack spacing={1}>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Status: {player.status}
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Subscriptions: {player.subscriptionCount}
          </Typography>
          {player.snapshotDate && (
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              Last Active: {new Date(player.snapshotDate).toLocaleDateString()}
            </Typography>
          )}
        </Stack>
      </Collapse>
    </Box>
  );
};

PlayerCard.propTypes = {
  player: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['left', 'joined', 'common']).isRequired,
  showTransition: PropTypes.bool
};

PlayerCard.defaultProps = {
  showTransition: false
};

export default PlayerCard; 
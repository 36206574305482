import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAssessmentById, getAssessmentTemplateByAssessmentId, getPlayerRatingsByAssessmentId, getPlayersByAssessmentId } from '../actions/assessmentAction';
import { assessmentAdapter, getPopupData } from '../../utils/assessment';


const badmintonPlayers = [
    { id: 1, name: "Kento Momota" },
    { id: 2, name: "Taufik Hidayat" },
    { id: 3, name: "Lin Dan" },
    { id: 4, name: "Lakshya Sen" },
    { id: 5, name: "Prakash Padukone" },
    { id: 6, name: "Kidambi Srikanth" },
    { id: 7, name: "Parupalli Kashyap" },
    { id: 8, name: "H.S. Prannoy" },
    { id: 9, name: "Sameer Verma" },
    { id: 10, name: "B. Sai Praneeth" },
    { id: 11, name: "Chirag Shetty" },
    { id: 12, name: "Satwiksairaj Rankireddy" },
    { id: 14, name: "Saina Nehwal" },
    { id: 15, name: "P.V. Sindhu" },
    { id: 16, name: "Ashwini Ponnappa" },
    { id: 17, name: "N. Sikki Reddy" },
    { id: 18, name: "Jwala Gutta" },
    { id: 22, name: "Aakarshi Kashyap" },
    { id: 23, name: "Chen Long" },
    { id: 24, name: "Viktor Axelsen" },
    { id: 26, name: "Anthony Ginting" },
    { id: 27, name: "Anders Antonsen" },
    { id: 28, name: "Jonatan Christie" },
    { id: 31, name: "Ng Ka Long Angus" },
    { id: 33, name: "Jan O Jorgensen" },
    { id: 37, name: "Carolina Marin" },
    { id: 38, name: "Tai Tzu Ying" },
    { id: 39, name: "Nozomi Okuhara" }]

const assessmentSlice = createSlice({
    name: 'assessment',
    initialState: {
        assessmentDetailAPIStatus: 'idle',
        assessmentDetails: {},
        assessmentTemplateAPIStatus: 'idle',
        assessmentTemplateDetails: {},
        assessmentPlayerRatingAPIStatus: 'idle',
        assessmentPlayerRatingsDetails: {},
        assessmentPlayerAPIStatus: 'idle',
        assessmentPlayers: [],
        groups: [],
        assessmentState: {},
        modalState: { isVisible: false },
    },
    reducers: {
        setAssessmentGroups: (state, action) => {
            state.groups = Array.isArray(action.payload) ? action.payload : []
        },
        setAssessmentModalState: (state, action) => {
            switch (action.payload.type) {
                case 'start': {
                    state.modalState.isVisible = true;
                    state.modalState.currentStep = 'selectBatch'
                    break;
                }
                case 'closeModal': {
                    state.modalState.isVisible = false;
                    state.modalState.currentStep = 'selectBatch'
                    break;
                }
                case 'groupSelect': {
                    state.modalState.selectedGroupId = action.payload.id
                    state.groups = state.groups.map(group => {
                        return {
                            ...group,
                            isSelected: group.id === action.payload.id
                        }
                    })
                    break;
                }
                case 'groupDelete': {
                    state.groups = state.groups.filter(g => g.id !== action.payload.id);
                    break;
                }
                case 'addNewGroup': {
                    state.modalState.currentStep = 'addBatch';
                    break;
                }
                case 'reorderSkillGroupandSkills': {
                    state.modalState.currentStep = 'reorderSkillGroupandSkills';
                    break;
                }
                case 'AddGroupAndStartAssessment': {
                    let i = 0;
                    const groupIds = state.groups.map(group => group.id);
                    let groupId = null
                    while (i < 100) {
                        i += 1;
                        groupId = Math.floor(Math.random() * (badmintonPlayers.length - 1)) + 1;
                        if (!groupIds.includes(groupId)) break

                    }
                    if (!groupId) throw Error('Something went wrong');

                    state.groups = [...state.groups.map(group => {
                        return { ...group, isSelected: false }
                    }), {
                        players: action.payload.players,
                        assessment: action.payload.assessment,
                        ...badmintonPlayers.find(player => player.id === groupId),
                        isSelected: true
                    }]
                    state.modalState.selectedGroupId = groupId;
                    state.modalState.currentStep = 'assessment';
                    state.assessmentState = getPopupData({ ASSESSMENT: action.payload.assessment, ASSESSMENT_PLAYERS: action.payload.players});
                    break;
                }
                case 'startAssessmentForCurrentGroup': {
                    const selectedGroup = state.groups.find(group => group.isSelected);
                    state.modalState.currentStep = 'assessment';
                    state.assessmentState = getPopupData({ ASSESSMENT: selectedGroup.assessment, ASSESSMENT_PLAYERS: selectedGroup.players });
                    break;
                }
                case 'assessment_card_change': {
                    const popUpData = action.payload.data.reduce((acc, curr) => {
                        const selectedGroup = state.groups.find(group => group.isSelected);
                        const { playerId, sectionId, levelSkillGroupId, attributeId } = acc;
                        return getPopupData({
                            playerId, sectionId, levelSkillGroupId, attributeId, type: curr,
                            ASSESSMENT: selectedGroup.assessment, ASSESSMENT_PLAYERS: selectedGroup.players,
                        })
                    }, state.assessmentState);

                    state.assessmentState = { ...popUpData }
                    break
                }
                default: {
                    throw Error('Something went wrong');
                }
            }
        },
        updateAssessmentRatingCell: (state, action) => {
            const { data: { id, assessmentPlayerId, skillId, skillsGroupId, rating, comments }, type } = action.payload;
            if (type === 'updated') {
                state.assessmentPlayerRatingsDetails[`${assessmentPlayerId}-${skillsGroupId}-${skillId}`] = {
                    id, assessmentPlayerId, skillId,
                    skillsGroupId, rating: parseInt(rating, 10), comments, cellState: 'updated'
                };
            } else if (type === 'updating') {
                state.assessmentPlayerRatingsDetails[`${assessmentPlayerId}-${skillsGroupId}-${skillId}`] = {
                    id, assessmentPlayerId, skillId,
                    skillsGroupId, rating: parseInt(rating, 10), comments, cellState: 'updating'
                };
            }
        }

    },
    extraReducers: (builder) => {
        // builder.addCase(getAssessmentById.pending, (state) => {
        //     state.assessmentDetailAPIStatus = 'loading';
        // });
        // builder.addCase(getAssessmentById.fulfilled, (state, action) => {
        //     state.assessmentDetailAPIStatus = 'succeeded';
        //     state.assessmentDetails = action?.payload?.data;
        // });
        // builder.addCase(getAssessmentById.rejected, (state) => {
        //     state.assessmentDetailAPIStatus = 'failed';
        // });
        builder.addCase(getAssessmentTemplateByAssessmentId.pending, (state) => {
            state.assessmentTemplateAPIStatus = 'loading';
        });
        builder.addCase(getAssessmentTemplateByAssessmentId.fulfilled, (state, action) => {
            state.assessmentTemplateAPIStatus = 'succeeded';
            const assessmentTemplateDetails = action?.payload?.data || {};
            state.assessmentDetails = assessmentAdapter({ assessmentTemplateDetails });
        });
        builder.addCase(getAssessmentTemplateByAssessmentId.rejected, (state) => {
            state.assessmentTemplateAPIStatus = 'failed';
        });
        builder.addCase(getPlayerRatingsByAssessmentId.pending, (state) => {
            state.assessmentPlayerRatingAPIStatus = 'loading';
        });
        builder.addCase(getPlayerRatingsByAssessmentId.fulfilled, (state, action) => {
            state.assessmentPlayerRatingAPIStatus = 'succeeded';
            const playerRatingList = action?.payload?.data || [];
            playerRatingList.forEach(ratingEntity => {
                const { assessmentPlayerId, skillId, skillsGroupId, rating, comments, id } = ratingEntity;
                state.assessmentPlayerRatingsDetails[`${assessmentPlayerId}-${skillsGroupId}-${skillId}`] = {
                    id, assessmentPlayerId, skillId,
                    skillsGroupId, rating: parseInt(rating, 10), comments, cellState: 'updated'
                };
            });
        });
        builder.addCase(getPlayerRatingsByAssessmentId.rejected, (state) => {
            state.assessmentPlayerRatingAPIStatus = 'failed';
        });
        builder.addCase(getPlayersByAssessmentId.pending, (state) => {
            state.assessmentPlayerAPIStatus = 'loading';
        });
        builder.addCase(getPlayersByAssessmentId.fulfilled, (state, action) => {
            state.assessmentPlayerAPIStatus = 'succeeded';
            state.assessmentPlayers = action?.payload?.data;
        });
        builder.addCase(getPlayersByAssessmentId.rejected, (state) => {
            state.assessmentPlayerAPIStatus = 'failed';
        });
    }
})


export const { setAssessmentGroups, setAssessmentModalState, updateAssessmentRatingCell } = assessmentSlice.actions;

export default assessmentSlice.reducer;
import { useRef, forwardRef, useImperativeHandle } from 'react';
import { Typography, Stack, Card,Box, CardHeader, Divider, Button, TextField, IconButton } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Iconify from '../../../components/Iconify';
import { useCreateEvent } from './useCreateEvent';

export const EventFaqs = forwardRef((_, ref) => {
  const faqs = useSelector((state) => state.eventCreate.faqs);
  const { nextStep, handleFaqs } = useCreateEvent();
  const formRef = useRef(null);

  const methods = useForm({
    defaultValues: {
      faqs: faqs.length > 0 ? faqs : [{ question: '', answer: '' }]
    }
  });

  useImperativeHandle(ref, () => ({
    onNextStep: () => {
      methods.handleSubmit(onSubmit)();
    },
  }));

  const handleAddFaq = () => {
    const currentFaqs = methods.getValues('faqs');
    methods.setValue('faqs', [...currentFaqs, { question: '', answer: '' }]);
  };

  const handleRemoveFaq = (index) => {
    const currentFaqs = methods.getValues('faqs');
    if (currentFaqs.length > 1) {
      const newFaqs = currentFaqs.filter((_, i) => i !== index);
      methods.setValue('faqs', newFaqs);
    }
  };

  const onSubmit = (data) => {
    handleFaqs(data.faqs);
    nextStep();
  };

  return (
    <FormProvider {...methods} ref={formRef}>
      <Box>
        <CardHeader title="FAQs" subheader="Add frequently asked questions" sx={{ mb: 3 }} />
        <Divider />
        <Stack spacing={3} sx={{ p: 3 }}>
          {methods.watch('faqs')?.map((_, index) => (
            <Card key={index}>
                <Stack direction="row" justifyContent="flex-end" sx={{ m: 2 }}>
                    {methods.watch('faqs').length > 1 && (
               <IconButton 
                onClick={() => handleRemoveFaq(index)}
              >
                <Iconify icon="eva:close-fill" width={20} height={20} />
              </IconButton>
              )}
                </Stack>
              <Stack spacing={3} sx={{ px:3, pb:3 }}>
                <TextField
                  fullWidth
                  label="Question"
                  {...methods.register(`faqs.${index}.question`)}
                />
                <TextField
                  fullWidth
                  label="Answer"
                  multiline
                  rows={3}
                  {...methods.register(`faqs.${index}.answer`)}
                />
              </Stack>
            </Card>
          ))}

          <Stack direction="row" justifyContent="center">
            <Button
              startIcon={<Iconify icon="eva:plus-fill" width={20} height={20} />}
              onClick={handleAddFaq}
              sx={{ width: '200px' }}
            >
              Add more questions
            </Button>
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  );
});

export default EventFaqs;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { format } from 'date-fns'; // Assuming this is the correct import for format

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  payouts: [],
  settlementDate: '',
  payoutDetails: null,
  payoutDate: format(new Date(), 'dd-MM-yyyy')
};

export const getPayouts = createAsyncThunk(
  'payouts/getPayouts',
  async (settlementDate, { rejectWithValue }) => {
    try {
      // Convert the date format from DD-MM-YYYY to MM-D-YYYY
      const [day, month, year] = settlementDate.split('-');
      const formattedDate = `${month}-${parseInt(day, 10)}-${year}`;

      const response = await axios.get(`https://api.krida.club/pg/payment/cc_avenue/payout-summary?settlementDate=${formattedDate}`);
      return Array.isArray(response.data.data) ? response.data.data : [response.data.data].filter(Boolean);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPayoutDetails = createAsyncThunk(
  'payouts/getPayoutDetails',
  async (payId, { rejectWithValue }) => {
    const url = `https://api.krida.club/pg/payment/cc_avenue/pay-id-details/${payId}?pageNumber=1`;
    console.log('Fetching payout details from:', url);
    try {
      const response = await axios.get(url);
      console.log('Payout details response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching payout details:', error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const payoutsSlice = createSlice({
  name: 'payouts',
  initialState,
  reducers: {
    setSettlementDate(state, action) {
      state.settlementDate = action.payload;
    },
    clearPayoutDetails(state) {
      state.payoutDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPayouts.pending, (state,action) => {
        state.isLoading = true;
        state.payouts = [];
        state.payoutDate = action.meta.arg;
      })
      .addCase(getPayouts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.payouts = (action.payload || []).filter(Boolean).filter((item) => ['psm', 'psb', 'psmaditya'].includes(item.sub_acc_Id));
      })
      .addCase(getPayouts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.message;
      })
      .addCase(getPayoutDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.payoutDetails = null; // Clear previous details
      })
      .addCase(getPayoutDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.payoutDetails = action.payload.data;
      })
      .addCase(getPayoutDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.message || 'An error occurred';
      });
  },
});

// Reducer
export default payoutsSlice.reducer;

// Actions
export const { setSettlementDate, clearPayoutDetails } = payoutsSlice.actions;
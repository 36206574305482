import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import moment from 'moment';
import { sentenceCase } from 'change-case';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Table,
  Avatar,
  Button,
  Divider,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  Typography,
  IconButton,
  TableContainer,
  Alert,
} from '@mui/material';
// utils
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { fetchPlayersDataAction } from '../../../../redux/slices/players'
import { fCurrency } from '../../../../utils/formatNumber';
// _mock

// components
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import Scrollbar from '../../../../components/Scrollbar';
import MenuPopover from '../../../../components/MenuPopover';

// ----------------------------------------------------------------------

const LatestEnrollments = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { isLoading, playerData } = useSelector((state) => state.players)

  useEffect(() => {
    dispatch(fetchPlayersDataAction({ limit: 5, pageNo: 0 }))
  }, [])

  const isLight = theme.palette.mode === 'light';

  const handleViewAll = () => {
    navigate(PATH_DASHBOARD.players, { replace: true });
  }

  return (
    <Card>
      <CardHeader title="Latest Enrollments" sx={{ mb: 3 }} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Player Name</TableCell>
                <TableCell>Mobile</TableCell>
                {/* <TableCell>Email</TableCell> */}
                <TableCell>Subscription</TableCell>
                <TableCell>Subscription status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {playerData?.players?.map((row) => {
                const { user, subscription } = row
                const { firstName, lastName, email, mobile } = user
                const { status, planMetadata } = subscription[0] || {}
                const { name : subscriptionName } = planMetadata || {}
                return (
                  <TableRow key={row?.id}>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ position: 'relative' }}>
                          {renderAvatar(row?.metadata?.playerName, '1')}
                          <Box
                            sx={{
                              right: 0,
                              bottom: 0,
                              width: 18,
                              height: 18,
                              display: 'flex',
                              borderRadius: '50%',
                              position: 'absolute',
                              alignItems: 'center',
                              color: 'common.white',
                              bgcolor: 'error.main',
                              justifyContent: 'center',
                              ...(row?.type === 'Income' && {
                                bgcolor: 'success.main',
                              }),
                            }}
                          >
                            <Iconify
                              icon={
                                row?.type === 'Income'
                                  ? 'eva:diagonal-arrow-left-down-fill'
                                  : 'eva:diagonal-arrow-right-up-fill'
                              }
                              width={16}
                              height={16}
                            />
                          </Box>
                        </Box>
                        <Box sx={{ ml: 2 }}>
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {firstName} {lastName}
                          </Typography>
                          <Typography variant="subtitle2"> {row?.category}</Typography>
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell>
                      {/* <Typography variant="subtitle2">{format(new Date(row?.date), 'dd MMM yyyy')}</Typography> */}
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {mobile}
                      </Typography>
                    </TableCell>
                    {/* <TableCell>{email}</TableCell> */}
                    <TableCell>{subscriptionName || 'N.A.'}</TableCell>

                    <TableCell>
                      <Label
                        variant={isLight ? 'ghost' : 'filled'}
                        // color={
                        //   ((row?.dueDate > row?.paymentTime) ? 'error' : 'success')
                        // }
                      >
                        {sentenceCase(status || 'No Subscription')}
                      </Label>
                    </TableCell>
                    {/* <TableCell align="right">
                      <MoreMenuButton />
                    </TableCell> */}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button size="small" color="inherit" endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}
          onClick={handleViewAll}>
          View All
        </Button>
      </Box>
    </Card>
  );
};

export default LatestEnrollments;

// ----------------------------------------------------------------------

const AvatarIcon = ({ icon }) => {
  return (
    <Avatar
      sx={{
        width: 48,
        height: 48,
        color: 'text.secondary',
        bgcolor: 'background.neutral',
      }}
    >
      <Iconify icon={icon} width={24} height={24} />
    </Avatar>
  );
};

AvatarIcon.propTypes = {
  icon: PropTypes.string.isRequired,
};

// ----------------------------------------------------------------------

const renderAvatar = (category, avatar) => {
  return avatar ? (
    <Avatar alt={category} src={avatar} sx={{ width: 48, height: 48, boxShadow: (theme) => theme.customShadows.z8 }} />
  ) : null;
};

// ----------------------------------------------------------------------

const MoreMenuButton = () => {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  return (
    <>
      <IconButton size="large" onClick={handleOpen}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: 200,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:download-fill'} sx={{ ...ICON }} />
          Download Invoice
        </MenuItem>

        {/* <MenuItem>
          <Iconify icon={'eva:printer-fill'} sx={{ ...ICON }} />
          Print
        </MenuItem> */}

        {/* <MenuItem>
          <Iconify icon={'eva:share-fill'} sx={{ ...ICON }} />
          Share
        </MenuItem> */}

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        {/* <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ ...ICON }} />
          Delete
        </MenuItem> */}
      </MenuPopover>
    </>
  );
};

import { useState, useCallback, forwardRef, useImperativeHandle, useEffect } from 'react';
import {
  Card,
  Table,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Stack,
  TableHead,
  IconButton,
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import { fDate } from '../../../utils/formatTime';
import { useCreateEvent } from './useCreateEvent';
import { EventFilters } from './EventFilters';
import{ AddEventDialog }from './add-event-dialog';
import {
  addSubEvent,
  updateSubEvent,
  deleteSubEvent,
  bulkUpdateSubEvents,
} from '../../../redux/slices/eventCreate';
import config from './config.json';

// ----------------------------------------------------------------------


export const SubEventList = forwardRef((_, ref) => {
  const dispatch = useDispatch();
  const subEvents = useSelector((state) => state.eventCreate.subEvents);
  const [selected, setSelected] = useState([]);
  const [bulkAction, setBulkAction] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [bulkEditValue, setBulkEditValue] = useState({
    startDate: null,
    endDate: null,
    value: ''
  });
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null);
  const { nextStep } = useCreateEvent();
  const [filterGender, setFilterGender] = useState([]);
  const [filterAge, setFilterAge] = useState([]);
  const [filterCategory, setFilterCategory] = useState([]);
  const { TABLE_HEAD } = config;

useEffect(() => {
  console.log(subEvents);
}, [subEvents]);

  useImperativeHandle(ref, () => ({
    onNextStep: () => {
      console.log('rules next');
      nextStep();
    },
  }));

  const handleAddEvent = (newEvent) => {
    dispatch(addSubEvent({
      ...newEvent,
      startDate: dayjs(newEvent.startDate).format(),
      endDate: dayjs(newEvent.endDate).format(),
      playersBornAfter: newEvent.playersBornAfter ? dayjs(newEvent.playersBornAfter).format() : null,
      playersBornBefore: newEvent.playersBornBefore ? dayjs(newEvent.playersBornBefore).format() : null
    }));
  };

  const filteredSubEvents = subEvents.filter((event) => {
    const genderFilter = filterGender.includes('all') || 
                        filterGender.length === 0 || 
                        filterGender.includes(event.gender);
    const ageFilter = filterAge.includes('all') || 
                     filterAge.length === 0 || 
                     filterAge.includes(event.ageGroup.value || event.ageGroup);
    const categoryFilter = filterCategory.includes('all') || 
                          filterCategory.length === 0 || 
                          filterCategory.includes(event.type);

    return genderFilter && ageFilter && categoryFilter;
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredSubEvents.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleEditRow = (id) => {
    const eventToEdit = subEvents.find(event => event.id === id);
    if (eventToEdit) {
      const formattedEvent = {
        ...eventToEdit,
        startDate: dayjs(eventToEdit.startDate),
        endDate: dayjs(eventToEdit.endDate),
        playersBornAfter: eventToEdit.playersBornAfter ? dayjs(eventToEdit.playersBornAfter) : null,
        playersBornBefore: eventToEdit.playersBornBefore ? dayjs(eventToEdit.playersBornBefore) : null
      };
      setEditingEvent(formattedEvent);
      setOpenEditDialog(true);
    }
  };

  const handleEditComplete = (updatedEvent) => {
    dispatch(updateSubEvent({
      ...updatedEvent,
      startDate: dayjs(updatedEvent.startDate).format(),
      endDate: dayjs(updatedEvent.endDate).format(),
      playersBornAfter: updatedEvent.playersBornAfter ? dayjs(updatedEvent.playersBornAfter).format() : null,
      playersBornBefore: updatedEvent.playersBornBefore ? dayjs(updatedEvent.playersBornBefore).format() : null
    }));

    setOpenEditDialog(false);
    setEditingEvent(null);
  };

  const handleBulkActionChange = (actionType) => {
    if (!actionType) return;
    
    setBulkAction(actionType);
    setOpenDialog(true);
    
    // Reset values
    setBulkEditValue({
      startDate: null,
      endDate: null,
      value: ''
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setBulkAction('');
    setBulkEditValue({
      startDate: null,
      endDate: null,
      value: ''
    });
  };

  const handleApplyBulkEdit = () => {
    if (!Array.isArray(selected) || selected.length === 0) return;

    switch (bulkAction) {
      case 'setDate':
        if (bulkEditValue.startDate && bulkEditValue.endDate) {
          dispatch(bulkUpdateSubEvents({
            ids: selected,
            updates: {
              startDate: dayjs(bulkEditValue.startDate).format(),
              endDate: dayjs(bulkEditValue.endDate).format()
            }
          }));
        }
        break;
      case 'setMaxParticipants':
      case 'setPrice':
        if (bulkEditValue.value) {
          dispatch(bulkUpdateSubEvents({
            ids: selected,
            updates: { 
              [bulkAction === 'setPrice' ? 'price' : 'maxParticipants']: Number(bulkEditValue.value) 
            }
          }));
        }
        break;
        default:
        break;
    }
    handleCloseDialog();
  };

  const handleFilterChange = (type, value) => {
    switch (type) {
      case 'gender':
        setFilterGender(value);
        break;
      case 'ageGroup':
        setFilterAge(value);
        break;
      case 'category':
        setFilterCategory(value);
        break;
      default:
        break;
    }
  };

  const handleDeleteRow = (id) => {
    dispatch(deleteSubEvent(id));
  };

  return (
    <Box>
      <Stack>
        <EventFilters
          filterGender={filterGender}
          filterAge={filterAge}
          filterCategory={filterCategory}
          onFilterChange={handleFilterChange}
          onAddEvent={handleAddEvent}
        />

        {selected.length > 1 && (
          <Stack 
            direction="row" 
            spacing={2}
            justifyContent="flex-end" 
            alignItems="center"
            sx={{ mb:  1 }}
          >
            <Typography>{selected.length} selected</Typography>
            <Button
              variant="outlined"
              onClick={() => handleBulkActionChange('setDate')}
            >
              Set Date
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleBulkActionChange('setMaxParticipants')}
            >
              Set Max Participants
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleBulkActionChange('setPrice')}
            >
              Set Price
            </Button>
          </Stack>
        )}

        <Scrollbar>
          <TableContainer>
            <Table>
              <TableHead >
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={filteredSubEvents.length > 0 && selected.length === filteredSubEvents.length}
                      onChange={handleSelectAllClick} />
                  </TableCell>
                  {TABLE_HEAD.map((headCell) => (
                    headCell.id !== 'select' && (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? 'right' : 'left'}
                      >
                        {headCell.label}
                      </TableCell>
                    )
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredSubEvents.map((row) => {
                  const { id, name, startDate, endDate, maxParticipants, price, ageGroup, gender, type } = row;
                  const isItemSelected = selected.indexOf(id) !== -1;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={() => handleClick(id)} />
                      </TableCell>
                      <TableCell>{name}</TableCell>
                      <TableCell>{fDate(startDate)}</TableCell>
                      <TableCell>{fDate(endDate)}</TableCell>
                      <TableCell>{maxParticipants}</TableCell>
                      <TableCell>₹{price}</TableCell>
                      <TableCell>{ageGroup}</TableCell>
                      <TableCell>{config.GENDER_OPTIONS.find(opt => opt.value === gender)?.label || ''}</TableCell>
                      <TableCell>{config.CATEGORY_OPTIONS.find(opt => opt.value === type)?.label || ''}</TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() => handleEditRow(id)}>
                          <Iconify icon="eva:edit-fill" height={20} width={20} />
                        </IconButton>
                        <IconButton 
                          onClick={() => handleDeleteRow(id)}
                          sx={{ color: 'error.main' }}
                        >
                          <Iconify icon="eva:trash-2-fill" height={20} width={20} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>
            {bulkAction === 'setDate' && 'Set Date for Selected Events'}
            {bulkAction === 'setMaxParticipants' && 'Set Max Participants for Selected Events'}
            {bulkAction === 'setPrice' && 'Set Price for Selected Events'}
          </DialogTitle>
          <DialogContent sx={{ mt: 2 }}>
            {bulkAction === 'setDate' ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <DatePicker
                    label="Start Date"
                    value={bulkEditValue.startDate}
                    onChange={(newValue) => setBulkEditValue(prev => ({ ...prev, startDate: newValue }))}
                    renderInput={(params) => (
                      <TextField {...params}/>
                    )}
                  />
                  <DatePicker
                    label="End Date"
                    value={bulkEditValue.endDate}
                    onChange={(newValue) => setBulkEditValue(prev => ({ ...prev, endDate: newValue }))}
                    minDate={bulkEditValue.startDate}
                    renderInput={(params) => (
                      <TextField {...params} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            ) : (
              <TextField
                fullWidth
                type="number"
                label={bulkAction === 'setPrice' ? 'Price' : 'Max Participants'}
                value={bulkEditValue.value}
                onChange={(e) => setBulkEditValue(prev => ({ ...prev, value: e.target.value }))}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleApplyBulkEdit} variant="contained">
              Apply
            </Button>
          </DialogActions>
        </Dialog>

        <AddEventDialog
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            setEditingEvent(null);
          }}
          onAdd={handleEditComplete}
          mode="edit"
          initialData={editingEvent}
        />
      </Stack>
    </Box>
  );
});

export default SubEventList;
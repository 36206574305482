import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useSearchParams, useParams } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Card,
  Table,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// redux
import { fetchPlayersData } from '../../redux/slices/event';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user/list';
import { fDateTimeUnix } from '../../utils/formatTime';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'select', label: '', alignRight: false },
  { id: 'name', label: 'Player Name', alignRight: false },
  { id: 'mobile', label: 'Mobile', alignRight: false },
  { id: 'subEventName', label: 'Event Category', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'createdAt', label: 'Applied', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

const EventPlayers = () => {
  const { id } = useParams();
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();

  const [userList, setUserList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { isLoading, playersData } = useSelector((state) => state.event);
  const [open, setOpen] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  const handleOpenDialog = (player) => {
    setSelectedPlayer(player);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedPlayer(null);
  };

  useEffect(() => {
    const searchQ = Object.fromEntries([...searchParams]);
    if (searchQ.pageNo) {
      setPage(parseInt(searchQ.page, 10));
    }
  }, [searchParams]);

  useEffect(() => {
    if (id) dispatch(fetchPlayersData(id));
  }, [dispatch]);

  const isLight = theme.palette.mode === 'light';

  useEffect(() => {
    const teamData = playersData?.map((item) => ({
      id: item.id,
      name: item.team?.name,
      mobile: item.metadata?.players?.[0]?.mobile,
      createdAt: item.team?.createdAt,
      status: item.status,
      players: item.metadata?.players || [],
      eventName: item.metadata?.eventName,
      subEventName: item.metadata?.subEventName,
      subEvent: item.metadata?.formData?.subEvent
    }));
    setUserList(teamData);
  }, [playersData]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = userList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch(fetchPlayersData({ limit: parseInt(event.target.value, 10), pageNo: 0 }));
    setPage(0);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteUser = (userId) => {
    const deleteUser = userList?.filter((user) => user.id !== userId);
    setSelected([]);
    setUserList(deleteUser);
  };

  const handleDeleteMultiUser = (selected) => {
    const deleteUsers = userList.filter((user) => !selected.includes(user.name));
    setSelected([]);
    setUserList(deleteUsers);
  };

  const filteredUsers = applySortFilter(userList, getComparator(order, orderBy), filterName);

  console.log(filteredUsers, 'filteredUsers')
  const isNotFound = !filteredUsers?.length && Boolean(filterName);

  return (
    <Page title="Event: Registered Users">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Registered Users"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Events', href: PATH_DASHBOARD.general.events },
            { name: 'List' },
          ]}
        />
        <Card>
          <UserListToolbar
            numSelected={selected?.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onDeleteUsers={() => handleDeleteMultiUser(selected)}
            isHidden={false}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={userList?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <Dialog open={open} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                  <DialogTitle
                    sx={{
                      borderBottom: '1px solid',
                      borderColor: 'divider',
                      pb: 2,
                    }}
                  >
                    Player Details
                  </DialogTitle>
                  <DialogContent sx={{ mt: 2 }}>
                    {selectedPlayer && (
                      <>
                        <Box sx={{ mb: 3 }}>
                          <Typography variant="h6" gutterBottom>
                            Team: {selectedPlayer.name}
                          </Typography>
                          <Typography color="text.secondary" gutterBottom>
                            {selectedPlayer.eventName} - {selectedPlayer.subEventName}
                          </Typography>
                          {selectedPlayer.subEvent && (
                            <Typography color="text.secondary">
                              Sub Event: {selectedPlayer.subEvent}
                            </Typography>
                          )}
                        </Box>

                        {selectedPlayer.players.map((player, index) => (
                          <Box
                            key={player.id}
                            sx={{
                              mb: 3,
                              p: 2,
                              border: '1px solid',
                              borderColor: 'divider',
                              borderRadius: 1,
                            }}
                          >
                            <Typography variant="subtitle1" gutterBottom>
                              Player {index + 1}
                            </Typography>
                            
                            <Box
                              sx={{
                                display: 'grid',
                                gap: 2,
                                gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
                                mb: 2,
                              }}
                            >
                              <Box>
                                <Typography color="text.secondary" variant="caption">
                                  Name
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                  {player.name}
                                </Typography>
                              </Box>

                              <Box>
                                <Typography color="text.secondary" variant="caption">
                                  Mobile
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                  {player.mobile && player.dialCode ? `+${player.dialCode} ${player.mobile}` : 'Not provided'}
                                </Typography>
                              </Box>

                              <Box>
                                <Typography color="text.secondary" variant="caption">
                                  Date of Birth
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                  {player.dob ? moment(player.dob).format('DD/MM/YYYY') : 'Not provided'}
                                </Typography>
                              </Box>

                              <Box>
                                <Typography color="text.secondary" variant="caption">
                                  Gender
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: 500, textTransform: 'capitalize' }}>
                                  {player.gender || 'Not provided'}
                                </Typography>
                              </Box>
                            </Box>

                            {player.formData && Object.keys(player.formData).length > 0 && (
                              <Box sx={{ mt: 2 }}>
                                <Typography variant="subtitle2" gutterBottom>
                                  Additional Information
                                </Typography>
                                <Box sx={{ display: 'grid', gap: 1 }}>
                                  {Object.entries(player.formData).map(([key, value]) => (
                                    <Box key={key}>
                                      <Typography color="text.secondary" variant="caption">
                                        {sentenceCase(key)}
                                      </Typography>
                                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                        {typeof value === 'object' ? JSON.stringify(value) : value}
                                      </Typography>
                                    </Box>
                                  ))}
                                </Box>
                              </Box>
                            )}
                          </Box>
                        ))}
                      </>
                    )}
                  </DialogContent>
                  <DialogActions sx={{ px: 3, py: 2, borderTop: '1px solid', borderColor: 'divider' }}>
                    <Button onClick={handleCloseDialog} variant="contained" color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>

                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Skeleton variant="rounded" width={'100%'} height={43} />
                        <br />
                        <Skeleton variant="rounded" width={'100%'} height={43} />
                        <br />
                        <Skeleton variant="rounded" width={'100%'} height={43} />
                        <br />
                        <Skeleton variant="rounded" width={'100%'} height={43} />
                        <br />
                        <Skeleton variant="rounded" width={'100%'} height={43} />
                        <br />
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredUsers?.map((row) => {
                      const { name, createdAt, status, mobile } = row;

                      return (
                        <TableRow hover key={row.id} tabIndex={-1} role="checkbox">
                          <TableCell padding="checkbox">
                            <Checkbox onClick={() => handleClick(row.id)} />
                          </TableCell>
                          <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar alt={name} src={name} sx={{ mr: 2 }} />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">{mobile}</TableCell>
                          <TableCell align="left">{row.subEventName}</TableCell>
                          <TableCell align="left">
                            <Label
                              variant={isLight ? 'ghost' : 'filled'}
                              color={status === 'pending' ? 'warning' : 'success'}
                            >
                              {sentenceCase(status)}
                            </Label>
                          </TableCell>
                          <TableCell align="left">{fDateTimeUnix(createdAt)}</TableCell>
                          <TableCell align="center">
                            <Button variant="contained" onClick={() => handleOpenDialog(row)}>
                              View Details
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={playersData?.count || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => {
              setPage(page);
              dispatch(fetchPlayersData({ limit: parseInt(rowsPerPage, 10), pageNo: page }));
            }}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
};

export default EventPlayers;

// ----------------------------------------------------------------------

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return array?.filter((_user) => _user?.team?.metadata?.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis?.map((el) => el[0]);
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const getAssessmentById = createAsyncThunk('assessmentAction/getAssessmentById', async (data, thunkApi) => {
    try {
        const assessmentId = data
        const { data: responseData } = await axios.get(`/admin/assessment/${assessmentId}`,
            { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        console.log(e)
        return thunkApi.rejectWithValue(e)
    }
});

export const getAssessmentTemplateByAssessmentId = createAsyncThunk('assessmentAction/getAssessmentTemplateByAssessmentId', async (data, thunkApi) => {
    try {
        const assessmentId = data
        const { data: responseData } = await axios.get(`/admin/assessment/${assessmentId}/template`,
            { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        console.log(e)
        return thunkApi.rejectWithValue(e)
    }
});


export const getPlayerRatingsByAssessmentId = createAsyncThunk('assessmentAction/getPlayerRatingsByAssessmentId', async (data, thunkApi) => {
    try {
        const assessmentId = data
        const { data: responseData } = await axios.get(`/admin/assessment/${assessmentId}/rating`,
            { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        console.log(e)
        return thunkApi.rejectWithValue(e)
    }
});

export const getPlayersByAssessmentId = createAsyncThunk('assessmentAction/getPlayersByAssessmentId', async (data, thunkApi) => {
    try {
        const assessmentId = data
        const { data: responseData } = await axios.get(`/admin/assessment/${assessmentId}/player`,
            { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        console.log(e)
        return thunkApi.rejectWithValue(e)
    }
});
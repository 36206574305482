import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    useTheme,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
    Tooltip,
    Stack,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    flexRender,
} from "@tanstack/react-table";
import getRatingWords from '../../../utils/getRatingWords'
import useResponsive from '../../../hooks/useResponsive';

const TooltipContent = ({ value, comments }) => {
    const ratingWord = getRatingWords(value);
    return (
        <Stack spacing={0.5}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
                {ratingWord}
            </Typography>
            {comments && (
                <Typography variant="body2">
                    {comments}
                </Typography>
            )}
        </Stack>
    );
};

const PlayerNameCell = ({ value, smDown, theme }) => (
    <Typography
        variant={smDown ? "caption" : "body2"}
        sx={{
            fontWeight: 600,
            color: theme.palette.text.primary,
            whiteSpace: 'normal',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }}
    >
        {value}
    </Typography>
);

const RatingCell = ({ value, comments, smDown, theme }) => {
    const ratingWord = getRatingWords(value);
    return (
        <Stack spacing={0.5}>
            <Typography
                variant={smDown ? "caption" : "body2"}
                sx={{
                    fontWeight: 600,
                    color: ratingWord === 'Excellent' 
                        ? theme.palette.success.darker
                        : ratingWord === 'Good'
                        ? theme.palette.info.darker
                        : ratingWord === 'Average'
                        ? theme.palette.warning.darker
                        : theme.palette.text.primary
                }}
            >
                {ratingWord}
            </Typography>
            {comments && (
                <Typography
                    variant="caption"
                    sx={{
                        color: theme.palette.text.secondary,
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        lineHeight: 1.2,
                    }}
                >
                    {comments}
                </Typography>
            )}
        </Stack>
    );
};

const CellRenderer = React.memo(({ cell, smDown, theme, isTooltip = false }) => {
    const cellValue = cell.getValue();
    if (cell.column.id === 'name') {
        return isTooltip ? cellValue : <PlayerNameCell value={cellValue} smDown={smDown} theme={theme} />;
    }
    return isTooltip ? 
        <TooltipContent value={cellValue?.rating} comments={cellValue?.comments} /> :
        <RatingCell 
            value={cellValue?.rating} 
            comments={cellValue?.comments} 
            smDown={smDown} 
            theme={theme} 
        />;
});

const RatingTable = ({ data, columns, ASSESSMENT }) => {

    const theme = useTheme();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const smDown = useResponsive('down', 'sm');
    
    useEffect(() => {
        const filtered = data.filter(player => 
            player.player?.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            player.player?.lastName?.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredData(filtered);
    }, [searchQuery, data]);

    const table = useReactTable({
        data: filteredData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        debugTable: true,
    });
    
    return (
        <Box 
            sx={{ 
                width: "100%", 
                height: 'auto',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                pb: 2,
            }}
        >
            <Stack 
                direction="column" 
                spacing={2}
                sx={{ 
                    width: '100%', 
                    px: { xs: 1, sm: 2 },
                    py: 1,
                }}
            >
                <Typography 
                    variant="h4" 
                    sx={{ 
                        color: theme.palette.text.primary,
                        fontWeight: 600,
                    }}
                >
                    {ASSESSMENT?.name || 'Assessment'}
                </Typography>
                <Stack 
                    direction="row" 
                    alignItems="center" 
                    spacing={2}
                    sx={{ width: '100%' }}
                >
                    <Box sx={{ flexGrow: 1, position: 'relative' }}>
                        <TextField
                            size="small"
                            fullWidth
                            placeholder="Search player name..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: theme.palette.background.neutral,
                                    transition: theme.transitions.create(['box-shadow', 'border-color', 'background-color']),
                                    borderRadius: 1.5,
                                    '&:hover': {
                                        backgroundColor: theme.palette.background.paper,
                                        '& > fieldset': {
                                            borderColor: theme.palette.primary.main,
                                            borderWidth: '1px',
                                        },
                                    },
                                    '&.Mui-focused': {
                                        backgroundColor: theme.palette.background.paper,
                                        boxShadow: `0 0 0 2px ${theme.palette.primary.lighter}`,
                                    },
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon sx={{ color: theme.palette.text.secondary }} />
                                    </InputAdornment>
                                ),
                                endAdornment: searchQuery && (
                                    <InputAdornment position="end">
                                        <Tooltip title="Clear search">
                                            <IconButton
                                                edge="end"
                                                onClick={() => setSearchQuery('')}
                                                size="small"
                                                sx={{
                                                    color: theme.palette.text.secondary,
                                                    '&:hover': {
                                                        color: theme.palette.error.main,
                                                    }
                                                }}
                                            >
                                                <ClearIcon sx={{ fontSize: 18 }} />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    {filteredData.length !== data.length && (
                        <Typography 
                            variant="body2" 
                            sx={{ 
                                color: theme.palette.text.secondary,
                                backgroundColor: theme.palette.background.neutral,
                                px: 2,
                                py: 1,
                                borderRadius: 1,
                                minWidth: 140,
                                textAlign: 'center'
                            }}
                        >
                            Found {filteredData.length} of {data.length} players
                        </Typography>
                    )}
                </Stack>
            </Stack>

            <TableContainer 
                component={Paper}
                sx={{ 
                    height: 'calc(100vh - 180px)',
                    overflow: 'auto',
                    borderRadius: 2,
                    border: `1px solid ${theme.palette.divider}`,
                    bgcolor: theme.palette.background.paper,
                    mx: { xs: 1, sm: 2 },
                    boxShadow: theme.customShadows?.z8,
                    '& .MuiTableCell-root': {
                        overflow: 'hidden',
                        padding: '6px 8px',
                        lineHeight: 1.2,
                    },
                }}
            >
                <Table stickyHeader size="small" sx={{ minWidth: { xs: 320, sm: 640 } }}>
                    <TableHead>
                        {table.getHeaderGroups().map((headerGroup, index) => (
                            <TableRow 
                                key={headerGroup.id}
                                sx={{
                                    '& th': {
                                        position: index < 2 ? 'sticky' : 'static',
                                        top: index === 0 ? 0 : index === 1 ? 42 : 'auto',
                                        bgcolor: index < 2 
                                            ? `${theme.palette.background.paper} !important` 
                                            : 'transparent',
                                        zIndex: index < 2 ? (theme.zIndex.appBar + (2 - index)) : 1,
                                        height: '42px',
                                        padding: '6px 8px',
                                        verticalAlign: 'middle',
                                        borderBottom: `1px solid ${theme.palette.divider}`,
                                        transition: theme.transitions.create(['background-color']),
                                        '&:hover': {
                                            bgcolor: `${theme.palette.background.neutral} !important`,
                                        },
                                    }
                                }}
                            >
                                {headerGroup.headers.map((header, columnIndex) => (
                                    <TableCell
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        sx={{
                                            padding: '6px 8px',
                                            position: columnIndex === 0 ? "sticky" : 'static',
                                            left: columnIndex === 0 ? 0 : "auto",
                                            minWidth: { xs: '100px', sm: '140px' },
                                            maxWidth: { xs: '120px', sm: '160px' },
                                            zIndex: columnIndex === 0 ? theme.zIndex.appBar + 3 : 'inherit',
                                            '& > div': {
                                                fontWeight: 600,
                                                fontSize: { xs: '12px', sm: '13px' },
                                                color: theme.palette.text.primary,
                                                letterSpacing: '0.015em',
                                                whiteSpace: 'normal',
                                                display: '-webkit-box',
                                                WebkitLineClamp: 2,
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }
                                        }}
                                    >
                                        {header.isPlaceholder ? null : (
                                            <Tooltip 
                                                title={flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                arrow
                                                placement="top"
                                            >
                                                <div>
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                </div>
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody>
                        {table.getRowModel().rows.map((row, rowIndex) => (
                            <TableRow 
                                key={row.id}
                                hover
                                sx={{
                                    height: '42px',
                                    transition: theme.transitions.create(['background-color'], {
                                        duration: theme.transitions.duration.shorter,
                                    }),
                                    '&:nth-of-type(odd)': {
                                        bgcolor: theme.palette.background.neutral,
                                    },
                                    '&:hover': {
                                        bgcolor: `${theme.palette.action.hover} !important`,
                                        '& .MuiTableCell-root': {
                                            color: theme.palette.text.primary,
                                        }
                                    },
                                }}
                            >
                                {row.getVisibleCells().map((cell, columnIndex) => (
                                    <TableCell
                                        key={cell.id}
                                        colSpan={cell.colSpan}
                                        sx={{
                                            bgcolor: columnIndex === 0 ? theme.palette.background.paper : 'transparent',
                                            padding: '6px 8px',
                                            borderBottom: `1px solid ${theme.palette.divider}`,
                                            position: columnIndex === 0 ? "sticky" : 'static',
                                            left: columnIndex === 0 ? 0 : "auto",
                                            minWidth: { xs: '100px', sm: '140px' },
                                            maxWidth: { xs: '120px', sm: '160px' },
                                            transition: theme.transitions.create(['background-color', 'color']),
                                        }}
                                    >
                                        <Tooltip 
                                            title={<CellRenderer cell={cell} smDown={smDown} theme={theme} isTooltip />}
                                            arrow
                                            placement="top"
                                            enterDelay={500}
                                            enterNextDelay={500}
                                        >
                                            <Typography 
                                                variant={smDown ? "caption" : "body2"}
                                                sx={{
                                                    fontWeight: columnIndex === 0 ? 600 : 400,
                                                    fontSize: { xs: '12px', sm: '13px' },
                                                    letterSpacing: '0.015em',
                                                    whiteSpace: columnIndex === 0 ? 'normal' : 'nowrap',
                                                    display: columnIndex === 0 ? '-webkit-box' : 'block',
                                                    WebkitLineClamp: 2,
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    lineHeight: 1.2,
                                                    cursor: 'default'
                                                }}
                                            >
                                                <CellRenderer cell={cell} smDown={smDown} theme={theme} />
                                            </Typography>
                                        </Tooltip>
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default RatingTable;

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import playersReducer from './slices/players'
import financeReducer from './slices/finance';
import productFamilyReducer from './slices/productFamily';
import assessmentSlice from './slices/assessmentSlice';
import organizationSlice from './slices/organizationSlice';
import payoutsReducer from './slices/payouts';
import eventSlice from './slices/event';
import eventCreateSlice from './slices/eventCreate';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  players: playersReducer,
  finance: financeReducer,
  productFam: productFamilyReducer,
  assessment: assessmentSlice,
  organization: organizationSlice,
  payouts: payoutsReducer,
  event: eventSlice,
  eventCreate: eventCreateSlice
});

export { rootPersistConfig, rootReducer };

import { useRef, forwardRef, useImperativeHandle } from 'react';
import { Typography, Stack, Card,Box, CardHeader, Divider, Button, TextField, IconButton, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Iconify from '../../../components/Iconify';
import { useCreateEvent } from './useCreateEvent';

const ANSWER_TYPES = [
  { value: 'text', label: 'Text' },
  { value: 'number', label: 'Number' },
  { value: 'date', label: 'Date' },
  { value: 'singleSelect', label: 'Single Select' },
  { value: 'multiSelect', label: 'Multi Select' }
];

export const EventRegQues = forwardRef((_, ref) => {
  const regQuestions = useSelector((state) => state.eventCreate.regQuestions);
  const { nextStep, handleRegQuestions } = useCreateEvent();
  const formRef = useRef(null);

  const methods = useForm({
    defaultValues: {
      questions: regQuestions.length > 0 ? regQuestions : [{ 
        question: '', 
        answerType: 'text', 
        options: [],
        isMandatory: false 
      }]
    }
  });

  useImperativeHandle(ref, () => ({
    onNextStep: () => {
      methods.handleSubmit(onSubmit)();
    },
  }));

  const handleAddQuestion = () => {
    const currentQuestions = methods.getValues('questions');
    methods.setValue('questions', [...currentQuestions, { 
      question: '', 
      answerType: 'text', 
      options: [],
      isMandatory: false 
    }]);
  };

  const handleRemoveQuestion = (index) => {
    const currentQuestions = methods.getValues('questions');
    if (currentQuestions.length > 1) {
      const newQuestions = currentQuestions.filter((_, i) => i !== index);
      methods.setValue('questions', newQuestions);
    }
  };

  const handleAddOption = (index) => {
    const currentQuestions = methods.getValues('questions');
    const currentOptions = currentQuestions[index].options || [];
    const updatedQuestions = [...currentQuestions];
    updatedQuestions[index] = {
      ...updatedQuestions[index],
      options: [...currentOptions, '']
    };
    methods.setValue('questions', updatedQuestions);
  };

  const handleRemoveOption = (questionIndex, optionIndex) => {
    const currentQuestions = methods.getValues('questions');
    const updatedQuestions = [...currentQuestions];
    updatedQuestions[questionIndex].options = updatedQuestions[questionIndex].options.filter((_, i) => i !== optionIndex);
    methods.setValue('questions', updatedQuestions);
  };

  const onSubmit = (data) => {
    handleRegQuestions(data.questions);
    nextStep();
  };

  return (
    <FormProvider {...methods} ref={formRef}>
      <Box>
        <CardHeader 
          title="Registration Questions" 
          subheader="Add questions that participants need to answer during registration" 
          sx={{ mb: 3 }} 
        />
        <Divider />
        <Stack spacing={3} sx={{ p: 3 }}>
          {methods.watch('questions')?.map((question, qIndex) => (
            <Card key={qIndex} sx={{ p: 3, boxShadow: '0 0 2px rgba(0,0,0,0.1)' }}>
              <Stack direction="row" justifyContent="space-between" alignItems="start" spacing={2}>
                <Stack spacing={3} sx={{ width: '100%' }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <TextField
                      fullWidth
                      placeholder="Question"
                      variant="outlined"
                      {...methods.register(`questions.${qIndex}.question`)}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: 'background.paper',
                        }
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={methods.watch(`questions.${qIndex}.isMandatory`)}
                          {...methods.register(`questions.${qIndex}.isMandatory`)}
                        />
                      }
                      label="Mandatory"
                      sx={{ 
                        minWidth: 120,
                        ml: 2,
                        color: 'text.secondary'
                      }}
                    />
                  </Stack>
                  
                  <TextField
                    select
                    fullWidth
                    label="Answer Type"
                    {...methods.register(`questions.${qIndex}.answerType`)}
                    defaultValue="text"
                    sx={{
                      backgroundColor: 'background.paper',
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'primary.main',
                        }
                      }
                    }}
                  >
                    {ANSWER_TYPES.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  {(question.answerType === 'singleSelect' || question.answerType === 'multiSelect') && (
                    <Stack spacing={2}>
                      <Typography variant="subtitle2" color="text.secondary">Options</Typography>
                      {question.options?.map((_, optionIndex) => (
                        <Stack key={optionIndex} direction="row" spacing={2} alignItems="center">
                          <TextField
                            fullWidth
                            placeholder={`Option ${optionIndex + 1}`}
                            {...methods.register(`questions.${qIndex}.options.${optionIndex}`)}
                            sx={{
                              backgroundColor: 'background.paper',
                            }}
                          />
                          <IconButton 
                            onClick={() => handleRemoveOption(qIndex, optionIndex)}
                            sx={{ color: 'text.secondary' }}
                          >
                            <Iconify icon="eva:close-fill" width={20} height={20} />
                          </IconButton>
                        </Stack>
                      ))}
                      <Button
                        startIcon={<Iconify icon="eva:plus-fill" width={20} height={20} />}
                        onClick={() => handleAddOption(qIndex)}
                        sx={{ 
                          width: 'fit-content',
                          textTransform: 'none',
                          color: 'text.secondary'
                        }}
                      >
                        Add Option
                      </Button>
                    </Stack>
                  )}
                </Stack>

                {methods.watch('questions').length > 1 && (
                  <IconButton 
                    onClick={() => handleRemoveQuestion(qIndex)}
                    sx={{ 
                      color: 'text.secondary',
                      '&:hover': { color: 'error.main' }
                    }}
                  >
                    <Iconify icon="eva:close-fill" width={20} height={20} />
                  </IconButton>
                )}
              </Stack>
            </Card>
          ))}

          <Button
            startIcon={<Iconify icon="eva:plus-fill" width={20} height={20} />}
            onClick={handleAddQuestion}
            sx={{ 
              width: '200px',
              alignSelf: 'center',
              textTransform: 'none'
            }}
          >
            Add Question
          </Button>
        </Stack>
      </Box>
    </FormProvider>
  );
});

export default EventRegQues;
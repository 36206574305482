import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = ({ disabledLink = false, sx }) => {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="100%" height="100%" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#000000" stroke="none">
          <path d="M0 2560 l0 -2560 2560 0 2560 0 0 2560 0 2560 -2560 0 -2560 0 0
-2560z m2721 1689 c282 -28 538 -119 768 -270 113 -74 181 -137 181 -168 0
-12 -11 -33 -26 -47 -32 -33 -58 -27 -133 30 -280 213 -613 327 -954 326 -96
0 -276 -21 -345 -39 l-39 -11 43 -85 c23 -47 48 -109 55 -136 12 -48 15 -50
41 -45 109 22 254 29 363 17 245 -26 501 -125 646 -249 44 -37 52 -81 22 -114
-27 -30 -69 -22 -138 27 -209 149 -507 226 -767 197 -62 -7 -117 -17 -124 -23
-6 -5 -14 -50 -17 -102 -5 -82 -21 -164 -43 -221 -6 -16 -4 -18 12 -12 139 55
360 67 499 28 93 -27 183 -68 243 -111 34 -25 42 -37 42 -63 0 -40 -25 -68
-61 -68 -15 0 -54 15 -86 34 -141 84 -333 116 -487 82 -228 -51 -413 -214
-499 -438 -30 -79 -31 -90 -31 -228 0 -134 2 -150 28 -220 16 -41 44 -101 63
-132 40 -67 40 -102 1 -131 -39 -29 -75 -11 -117 57 -116 193 -149 421 -92
640 12 44 20 80 18 82 -1 1 -39 -8 -84 -21 -44 -13 -120 -27 -167 -31 l-85 -7
-8 -46 c-4 -25 -8 -120 -7 -211 1 -144 4 -178 27 -263 30 -115 98 -262 162
-353 55 -79 63 -106 41 -139 -12 -19 -25 -25 -51 -25 -42 0 -60 17 -122 111
-166 258 -232 572 -187 900 l6 46 -70 22 c-39 12 -100 37 -136 55 -74 39 -66
45 -88 -69 -20 -108 -17 -447 5 -558 49 -243 165 -492 316 -681 40 -50 40
-102 0 -125 -45 -25 -72 -8 -141 86 -80 108 -127 187 -178 295 -166 356 -203
770 -101 1157 34 129 66 139 187 58 190 -127 447 -152 651 -62 137 61 269 187
334 320 53 109 69 179 69 305 0 162 -38 280 -132 409 -26 36 -48 73 -48 81 0
31 32 60 81 74 214 64 449 87 660 65z m-1186 -44 c192 -49 345 -205 390 -396
43 -184 -9 -363 -145 -499 -77 -77 -141 -113 -246 -140 -92 -24 -145 -25 -239
-5 -290 61 -478 355 -410 643 44 188 206 351 391 396 79 19 187 19 259 1z
m2335 -567 c18 -18 63 -79 100 -136 272 -409 352 -893 229 -1374 -25 -101 -36
-123 -65 -137 -30 -13 -46 -7 -110 41 -111 83 -257 130 -404 130 -191 0 -340
-61 -476 -196 -131 -132 -192 -268 -202 -452 -8 -163 41 -321 137 -443 36 -45
40 -89 12 -115 -21 -19 -128 -49 -256 -72 -134 -24 -333 -28 -475 -10 -290 38
-511 120 -741 275 -171 114 -198 153 -143 207 32 33 56 28 132 -31 290 -224
659 -340 1022 -321 117 6 275 28 287 40 3 3 -10 38 -30 78 -20 40 -44 101 -53
136 -11 41 -22 61 -30 58 -7 -3 -59 -10 -116 -17 -260 -30 -563 38 -785 177
-48 31 -100 67 -115 82 -51 48 -28 122 39 122 12 0 51 -20 87 -45 93 -66 259
-142 373 -172 83 -21 122 -26 248 -27 83 0 175 3 206 7 l56 8 6 83 c6 72 32
198 52 247 6 15 1 15 -49 -2 -182 -59 -400 -47 -575 32 -149 68 -201 122 -167
174 24 37 63 43 106 16 67 -42 175 -90 235 -106 83 -21 222 -19 324 5 124 30
211 80 307 175 67 66 89 97 127 175 60 121 77 191 77 310 0 138 -34 251 -111
372 -36 56 -35 93 2 120 41 31 72 16 119 -56 122 -186 159 -440 95 -659 -13
-45 -23 -83 -22 -83 1 -1 43 9 92 22 54 14 120 23 167 23 89 1 81 -8 99 126
33 253 -33 533 -176 751 -71 107 -80 143 -43 168 34 24 71 20 100 -11 96 -105
207 -349 242 -532 30 -157 30 -338 0 -487 -5 -29 -4 -30 47 -43 29 -8 91 -33
137 -56 51 -26 85 -38 87 -31 45 132 54 499 17 674 -57 263 -134 430 -317 686
-38 53 -39 77 -3 109 30 26 62 21 98 -15z m-65 -1703 c184 -48 344 -210 385
-390 7 -28 12 -84 12 -125 0 -139 -43 -253 -135 -356 -126 -141 -302 -202
-487 -170 -256 45 -440 264 -440 525 0 102 16 166 61 252 114 213 369 325 604
264z"/>
          <path d="M1295 4067 c-117 -39 -198 -110 -248 -215 -29 -61 -32 -76 -32 -162
1 -75 6 -107 24 -153 32 -81 115 -168 200 -210 61 -30 73 -32 166 -32 91 0
106 3 165 31 86 41 153 108 193 192 29 61 32 75 32 167 0 91 -3 106 -31 165
-44 91 -91 141 -176 186 -67 35 -81 38 -163 41 -56 2 -105 -2 -130 -10z"/>
          <path d="M3598 1810 c-68 -12 -146 -54 -204 -112 -232 -227 -109 -610 214
-670 255 -47 499 197 453 454 -29 167 -158 296 -326 327 -65 12 -69 12 -137 1z"/>
        </g>
      </svg>

    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
};

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;

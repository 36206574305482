import PropTypes from 'prop-types';
import { 
  Card, 
  CardContent, 
  Typography, 
  Grid, 
  Box, 
  Stack, 
  Button, 
  Tooltip,
  IconButton,
  Collapse,
  Divider,
  Switch,
  FormGroup,
  FormControlLabel,
  TextField,
  InputAdornment,
  Skeleton,
  useMediaQuery
} from '@mui/material';
import { styled, alpha, useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Iconify from '../../../../components/Iconify';
import EmptyState from './components/EmptyState';

// Styled Components
const ComparisonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3)
}));

const PeriodPanel = styled(Card)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  minHeight: 400
}));

const TransitionArrow = styled(Box)(({ theme, type }) => ({
  position: 'absolute',
  height: 2,
  width: 40,
  backgroundColor: type === 'joined' ? theme.palette.success.main : theme.palette.error.main,
  '&::after': {
    content: '""',
    position: 'absolute',
    right: -6,
    top: -4,
    borderStyle: 'solid',
    borderWidth: '5px 0 5px 8px',
    borderColor: `transparent transparent transparent ${
      type === 'joined' ? theme.palette.success.main : theme.palette.error.main
    }`
  }
}));

const EmptyPlayerCard = styled(Box)(({ theme }) => ({
  height: 76, // Match the height of a regular PlayerCard
  marginBottom: theme.spacing(1)
}));

// Summary Component
const PeriodSummary = ({ data, period }) => {
  const joinedCount = data.playerChanges?.filter(p => 
    p.fromStatus === 'inactive' && p.toStatus === 'active'
  ).length || 0;

  const leftCount = data.playerChanges?.filter(p => 
    p.fromStatus === 'active' && p.toStatus === 'inactive'
  ).length || 0;

  const activeCount = data.activePlayers?.length || 0;

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="h6">{period}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box sx={{ 
                p: 2, 
                bgcolor: (theme) => alpha(theme.palette.success.main, theme.palette.mode === 'dark' ? 0.2 : 0.1),
                borderRadius: 1 
              }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon="mdi:account-plus" sx={{ color: 'success.main' }} />
                  <Typography variant="subtitle2" sx={{ color: 'success.main' }}>
                    Joined: {joinedCount}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ 
                p: 2, 
                bgcolor: (theme) => alpha(theme.palette.error.main, theme.palette.mode === 'dark' ? 0.2 : 0.1),
                borderRadius: 1 
              }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon="mdi:account-minus" sx={{ color: 'error.main' }} />
                  <Typography variant="subtitle2" sx={{ color: 'error.main' }}>
                    Left: {leftCount}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ 
                p: 2, 
                bgcolor: (theme) => alpha(theme.palette.text.primary, theme.palette.mode === 'dark' ? 0.1 : 0.05),
                borderRadius: 1 
              }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon="mdi:account-check" sx={{ color: 'text.secondary' }} />
                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                    Active: {activeCount}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
};

// Player Card Component
const PlayerCard = ({ player, type, showTransition, changes }) => {
  const [expanded, setExpanded] = useState(false);

  const getHighlightStyle = (field) => {
    if (!changes || !changes[field]) return {};
    return {
      backgroundColor: (theme) => alpha(theme.palette.warning.main, 0.1),
      borderRadius: 0.5,
      px: 0.5
    };
  };

  return (
    <Box
      sx={{
        p: 2,
        mb: 1,
        height: expanded ? 'auto' : 76,
        borderRadius: 1,
        position: 'relative',
        bgcolor: (theme) => {
          const color = type === 'left' ? theme.palette.error.main : 
                       type === 'joined' ? theme.palette.success.main : 
                       theme.palette.text.primary;
          return alpha(color, theme.palette.mode === 'dark' ? 0.2 : 0.1);
        },
        cursor: 'pointer',
        '&:hover': {
          bgcolor: (theme) => alpha(
            type === 'left' ? theme.palette.error.main : 
            type === 'joined' ? theme.palette.success.main : 
            theme.palette.text.primary,
            theme.palette.mode === 'dark' ? 0.3 : 0.15
          )
        }
      }}
      onClick={() => setExpanded(!expanded)}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <Iconify 
          icon={
            type === 'left' ? 'mdi:account-minus' :
            type === 'joined' ? 'mdi:account-plus' :
            'mdi:account'
          }
          sx={{ 
            color: type === 'left' ? 'error.main' :
                   type === 'joined' ? 'success.main' :
                   'text.secondary'
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle2">{player.name}</Typography>
          <Typography variant="caption" color="text.secondary">
            {player.email || 'No email'}
          </Typography>
        </Box>
        {showTransition && (
          <TransitionArrow 
            type={type}
            sx={{
              right: type === 'left' ? -40 : 'auto',
              left: type === 'joined' ? -40 : 'auto'
            }}
          />
        )}
      </Stack>

      <Collapse in={expanded}>
        <Divider sx={{ my: 2 }} />
        <Stack spacing={1}>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Status: {player.status}
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Subscriptions: {player.subscriptionCount}
          </Typography>
          {player.snapshotDate && (
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              Last Active: {new Date(player.snapshotDate).toLocaleDateString()}
            </Typography>
          )}
        </Stack>
      </Collapse>
    </Box>
  );
};

// Helper function to sort players by name
const sortByName = (players) => {
  return [...players].sort((a, b) => a.name.localeCompare(b.name));
};

// Create a combined layout structure that includes empty spaces
const createPanelLayout = (period1Data, period2Data) => {
  // First, get all unique names across both periods
  const allNames = new Set([
    ...period1Data.activePlayers.map(p => p.name),
    ...period2Data.activePlayers.map(p => p.name)
  ]);

  // Sort names alphabetically
  const sortedNames = Array.from(allNames).sort((a, b) => a.localeCompare(b));

  // Create layout with proper spacing
  return sortedNames.map(name => {
    const player1 = period1Data.activePlayers.find(p => p.name === name);
    const player2 = period2Data.activePlayers.find(p => p.name === name);

    return {
      name,
      left: player1 || null,
      right: player2 || null,
      type: player1 && player2 ? 'common' : 'unique'
    };
  });
};

// Helper to detect changes between periods
const getPlayerChanges = (player1, player2) => {
  const changes = {};
  if (player1.email !== player2.email) {
    changes.email = { old: player1.email, new: player2.email };
  }
  if (player1.status !== player2.status) {
    changes.status = { old: player1.status, new: player2.status };
  }
  if (player1.subscriptionCount !== player2.subscriptionCount) {
    changes.subscriptionCount = { 
      old: player1.subscriptionCount, 
      new: player2.subscriptionCount 
    };
  }
  return Object.keys(changes).length > 0 ? changes : null;
};

// View Controls Component
const ViewControls = ({ 
  showOnlyDifferences, 
  setShowOnlyDifferences,
  highlightChanges,
  setHighlightChanges,
  searchQuery,
  setSearchQuery,
  onReset,
  resultCount
}) => (
  <Box
    sx={{
      position: { xs: 'sticky', md: 'static' },
      top: 0,
      zIndex: 1,
      bgcolor: 'background.paper',
      py: 2
    }}
  >
    <Card sx={{ p: 2 }}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="center" justifyContent="space-between">
        <FormGroup row sx={{ flexGrow: 1 }}>
          <Tooltip title="Show only players that changed between periods">
            <FormControlLabel
              control={
                <Switch
                  checked={showOnlyDifferences}
                  onChange={(e) => setShowOnlyDifferences(e.target.checked)}
                />
              }
              label="Show Only Differences"
            />
          </Tooltip>
          <Tooltip title="Highlight changes in player details">
            <FormControlLabel
              control={
                <Switch
                  checked={highlightChanges}
                  onChange={(e) => setHighlightChanges(e.target.checked)}
                />
              }
              label="Highlight Changes"
            />
          </Tooltip>
        </FormGroup>

        <Stack direction="row" spacing={2} alignItems="center">
          <Box sx={{ position: 'relative' }}>
            <TextField
              size="small"
              placeholder="Search players..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" />
                  </InputAdornment>
                ),
                endAdornment: searchQuery && (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="Clear search"
                      onClick={() => setSearchQuery('')}
                    >
                      <Iconify icon="eva:close-fill" />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {resultCount !== null && (
              <Typography
                variant="caption"
                sx={{
                  position: 'absolute',
                  bottom: -20,
                  left: 0,
                  color: 'text.secondary'
                }}
              >
                {resultCount} results found
              </Typography>
            )}
          </Box>
          <Tooltip title="Reset view to default settings">
            <IconButton onClick={onReset} aria-label="Reset view">
              <Iconify icon="mdi:refresh" />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </Card>
  </Box>
);

// Main Component
const AnalyticsComparisonMetrics = ({ data, period1, period2 }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isLoading, setIsLoading] = useState(true);
  const [showOnlyDifferences, setShowOnlyDifferences] = useState(false);
  const [highlightChanges, setHighlightChanges] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    // Simulate loading state
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 1000);
  }, [data]);

  if (!data?.period1?.[0] || !data?.period2?.[0]) {
    return (
      <EmptyState
        icon="mdi:chart-timeline-variant"
        title="No Data Available"
        description="Select a club and periods to compare player analytics"
      />
    );
  }

  const period1Data = data.period1[0];
  const period2Data = data.period2[0];

  // Get filtered and processed players
  const getProcessedPlayers = () => {
    // Get left players (in period1 but not in period2)
    const leftPlayers = period1Data.activePlayers
      .filter(p1 => !period2Data.activePlayers.some(p2 => p2.id === p1.id))
      .map(player => ({
        name: player.name,
        left: player,
        right: null,
        type: 'left'
      }));

    // Get joined players (in period2 but not in period1)
    const joinedPlayers = period2Data.activePlayers
      .filter(p2 => !period1Data.activePlayers.some(p1 => p1.id === p2.id))
      .map(player => ({
        name: player.name,
        left: null,
        right: player,
        type: 'joined'
      }));

    // Get common players
    const commonPlayers = !showOnlyDifferences
      ? period1Data.activePlayers
          .filter(p1 => period2Data.activePlayers.some(p2 => p2.id === p1.id))
          .map(player1 => {
            const player2 = period2Data.activePlayers.find(p2 => p2.id === player1.id);
            return {
              name: player1.name,
              left: player1,
              right: player2,
              type: 'common',
              changes: highlightChanges ? getPlayerChanges(player1, player2) : null
            };
          })
      : [];

    let allPlayers = [...leftPlayers, ...commonPlayers, ...joinedPlayers];

    // Apply search filter
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      allPlayers = allPlayers.filter(player => 
        player.name.toLowerCase().includes(query) ||
        (player.left?.email && player.left.email.toLowerCase().includes(query)) ||
        (player.right?.email && player.right.email.toLowerCase().includes(query))
      );
    }

    // Sort by name
    return allPlayers.sort((a, b) => a.name.localeCompare(b.name));
  };

  const processedPlayers = getProcessedPlayers();

  if (isLoading) {
    return (
      <Stack spacing={3}>
        <ViewControls
          showOnlyDifferences={showOnlyDifferences}
          setShowOnlyDifferences={setShowOnlyDifferences}
          highlightChanges={highlightChanges}
          setHighlightChanges={setHighlightChanges}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onReset={() => {
            setShowOnlyDifferences(false);
            setHighlightChanges(true);
            setSearchQuery('');
          }}
          resultCount={null}
        />
        <Grid container spacing={3}>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Skeleton 
                variant="rectangular" 
                height={76} 
                sx={{ borderRadius: 1 }}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    );
  }

  if (processedPlayers.length === 0 && searchQuery) {
    return (
      <Stack spacing={3}>
        <ViewControls
          showOnlyDifferences={showOnlyDifferences}
          setShowOnlyDifferences={setShowOnlyDifferences}
          highlightChanges={highlightChanges}
          setHighlightChanges={setHighlightChanges}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onReset={() => {
            setShowOnlyDifferences(false);
            setHighlightChanges(true);
            setSearchQuery('');
          }}
          resultCount={0}
        />
        <EmptyState
          icon="mdi:account-search"
          title="No Players Found"
          description="Try adjusting your search or filters"
        />
      </Stack>
    );
  }

  return (
    <Stack spacing={3}>
      <ViewControls
        showOnlyDifferences={showOnlyDifferences}
        setShowOnlyDifferences={setShowOnlyDifferences}
        highlightChanges={highlightChanges}
        setHighlightChanges={setHighlightChanges}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        onReset={() => {
          setShowOnlyDifferences(false);
          setHighlightChanges(true);
          setSearchQuery('');
        }}
        resultCount={processedPlayers.length}
      />

      {/* Summary Section */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <PeriodSummary data={period1Data} period={period1} />
        </Grid>
        <Grid item xs={12} md={6}>
          <PeriodSummary data={period2Data} period={period2} />
        </Grid>
      </Grid>

      {/* Comparison Panels */}
      <Grid container spacing={3}>
        {/* Period 1 Panel */}
        <Grid item xs={12} md={6}>
          <PeriodPanel>
            <Typography variant="h6" gutterBottom>
              {period1}
            </Typography>
            {processedPlayers.map((item) => (
              <Box key={`left-${item.name}`}>
                {item.left ? (
                  <PlayerCard 
                    player={item.left}
                    type={item.type}
                    showTransition={item.type === 'left'}
                    changes={item.changes}
                  />
                ) : (
                  <EmptyPlayerCard />
                )}
              </Box>
            ))}
          </PeriodPanel>
        </Grid>

        {/* Period 2 Panel */}
        <Grid item xs={12} md={6}>
          <PeriodPanel>
            <Typography variant="h6" gutterBottom>
              {period2}
            </Typography>
            {processedPlayers.map((item) => (
              <Box key={`right-${item.name}`}>
                {item.right ? (
                  <PlayerCard 
                    player={item.right}
                    type={item.type}
                    showTransition={item.type === 'joined'}
                    changes={item.changes}
                  />
                ) : (
                  <EmptyPlayerCard />
                )}
              </Box>
            ))}
          </PeriodPanel>
        </Grid>
      </Grid>
    </Stack>
  );
};

AnalyticsComparisonMetrics.propTypes = {
  data: PropTypes.object.isRequired,
  period1: PropTypes.string.isRequired,
  period2: PropTypes.string.isRequired
};

export default AnalyticsComparisonMetrics; 
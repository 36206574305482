import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { setSelectedClubs } from "../redux/slices/organizationSlice";

export default function useOrg() {
  const { organization, config, clubs, selectedClubs } = useSelector((state) => state.organization);
  const clubStorage = localStorage.getItem('selectedClubs') || '';
  const selectedClubIdArrayFromStorage = useMemo(() => 
    clubStorage.split(',').filter(Boolean)
  , [clubStorage]);

  let filteredClubs = useMemo(() => 
    clubs?.filter(_club => 
      _club && 
      _club.id && 
      selectedClubIdArrayFromStorage.includes(_club.id.toString())
    ) || []
  , [clubs, selectedClubIdArrayFromStorage]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (clubs.length > 0) {
      filteredClubs = clubs.filter((_club) => selectedClubIdArrayFromStorage.includes(_club.id))
      if (filteredClubs.length === 0) filteredClubs = [clubs.find(_club => _club.isDefault) || clubs[0]];
    } else {
        filteredClubs = [];
      }
      dispatch(setSelectedClubs(filteredClubs))

  }, [clubs])

  const handleClubChange = (clubId) => {
    if (!clubId) return;

    // Handle array of IDs for city selection
    if (Array.isArray(clubId)) {
      const newClubs = clubId
        .map(id => clubs?.find(_club => _club?.id === id))
        .filter(Boolean);
      dispatch(setSelectedClubs(newClubs));
      localStorage.setItem('selectedClubs', clubId.join(','));
      return;
    }

    // Rest of the code for single club selection remains same
    const isRemoval = selectedClubs?.some(_club => _club?.id === clubId);
    
    if (isRemoval) {
      filteredClubs = selectedClubs.filter(_club => _club?.id !== clubId);
    } else {
      const clubToAdd = clubs?.find(_club => _club?.id === clubId);
      if (!clubToAdd) return;
      filteredClubs = [...(selectedClubs || []), clubToAdd];
    }

    dispatch(setSelectedClubs(filteredClubs));
    const validClubIds = filteredClubs.map(_club => _club.id).filter(Boolean);
    localStorage.setItem('selectedClubs', validClubIds.join(','));
  };

  return {
    onChangeClub: handleClubChange,
    selectedClubs: selectedClubs || [],
    allClubs: clubs || [],
    organization,
    orgConfig: config
  };
}

// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOT = ''

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    home: path(ROOTS_DASHBOARD, '/'),
    playerAnalytics: path(ROOTS_DASHBOARD, '/playerAnalytics'),
    finance: path(ROOTS_DASHBOARD, '/finance'),
    events: path(ROOTS_DASHBOARD, '/events')
  },
  playerAnalytics: {
    root: path(ROOTS_DASHBOARD, '/playerAnalytics'),
    overview: path(ROOTS_DASHBOARD, '/playerAnalytics/overview'),
    diff: path(ROOTS_DASHBOARD, '/playerAnalytics/diff'),
  },
  profile: path(ROOTS_DASHBOARD, '/profile'),
  transactions: path(ROOTS_DASHBOARD, '/finance/allTransactions'),
  players: path(ROOTS_DASHBOARD, '/playerAnalytics/allPlayers'),
  registeredUsers: path(ROOTS_DASHBOARD, '/events/registeredUsers'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  payouts: {
    root: path(ROOTS_DASHBOARD, '/payouts'),
    list: path(ROOTS_DASHBOARD, '/payouts/list'),
    details: (id) => path(ROOTS_DASHBOARD, `/payouts/${id}/details`),
  },
  events: {
    root: path(ROOTS_DASHBOARD, '/events'),
    overview: path(ROOTS_DASHBOARD, '/events/overview'),
   eventPlayers: (id) => path(ROOTS_DASHBOARD, `/events/eventPlayers/${id}`),
   create: path(ROOTS_DASHBOARD, '/events/create'),
  },
  gallery: {
    root: path(ROOTS_DASHBOARD, '/gallery'),
    create: path(ROOTS_DASHBOARD, '/gallery/create'),
    list: path(ROOTS_DASHBOARD, '/gallery/list')
  }
};

export const PATH_PF = {
  planFamily: {
    root: path(ROOT, '/plan-families'),
    plan: path(ROOT, '/plans'),
    charge: path(ROOT, '/charges'),
    coupon: path(ROOT, '/coupons'),
  },
}

import { useRef, forwardRef, useImperativeHandle } from 'react';
import { Typography, Stack, Box, CardHeader, Divider } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RHFEditor } from '../../../components/hook-form';
import { useCreateEvent } from './useCreateEvent';

export const EventRules = forwardRef((_, ref) => {
  const rules = useSelector((state) => state.eventCreate.rules);
  const { nextStep, handleRules } = useCreateEvent();
  const formRef = useRef(null);

  const methods = useForm({
    defaultValues: {
      rules,
    }
  });

  const editorConfig = {
    simple: true,
    toolbarOptions: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['clean']
    ]
  };

  useImperativeHandle(ref, () => ({
    onNextStep: () => {
      methods.handleSubmit(onSubmit)();
    },
  }));

  const onSubmit = (data) => {
    handleRules(data.rules);
    nextStep();
  };

  return (
    <FormProvider {...methods} ref={formRef} onSubmit={onSubmit}>
      <Box>
        <CardHeader title="Rules" subheader="Add event rules and guidelines" sx={{ mb: 3 }} />
        <Divider />
        <Stack spacing={3} sx={{ p: 3 }}>
          <Stack spacing={1.5}>
            <Typography variant="subtitle2">Event Rules</Typography>
            <RHFEditor 
              name="rules" 
              {...editorConfig}
              sx={{ maxHeight: 400 }} 
            />
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  );
});

export default EventRules;

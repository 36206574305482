// ----------------------------------------------------------------------

export default function getRatingWords(rating) {
    let ratingName;

    switch (rating) {
        case undefined:
            ratingName = 'N/A'
            break;
        case 1:
            ratingName = 'Poor';
            break;
        case 2:
            ratingName = 'Fair';
            break;
        case 3:
            ratingName = 'Average';
            break;
        case 4:
            ratingName = 'Good';
            break;
        case 5:
            ratingName = 'Excellent';
            break;
        default:
            ratingName = rating;
    }

    return ratingName;
}

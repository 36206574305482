import { useRef, forwardRef, useImperativeHandle, useState } from 'react';
import { Typography, Stack, Card, MenuItem, Checkbox, TextField, ListItem, Box, Tooltip, IconButton, Autocomplete, ImageList, ImageListItem } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { RHFEditor, RHFUploadAvatar } from '../../../components/hook-form';
import { useCreateEvent } from './useCreateEvent';
import Iconify from '../../../components/Iconify';

const tooltips = {
  name: "Enter a unique and descriptive name for your event",
  eventType: "Select the type of event you're organizing",
  eventMode: "Choose how participants will attend your event",
  eventVenue: "Select the location where the event will take place",
  about: "Brief summary of your event (max 250 characters)",
  description: "Detailed description of your event including key highlights and objectives",
  sport: "Select the primary sport for this event",
  registrationDates: "Set the period during which participants can register",
  eventDates: "Set the start and end dates of your event",
  maxRegistrations: "This limits how many different sub-events a single participant can register for. Example: If set to 3, a participant can register for up to 3 different categories like 'Men's Singles', 'Men's Doubles', and 'Mixed Doubles'."
};


export const EventDetails = forwardRef((_, ref) => {
  const basicDetails = useSelector((state) => state.eventCreate.basicDetails);
  const {clubs, organization} = useSelector((state) => state.organization);
  const formRef = useRef(null);
  const { nextStep, handleBasicDetails } = useCreateEvent();

  const methods = useForm({
    defaultValues: {
      name: basicDetails.name,
      eventType: basicDetails.eventType,
      eventMode: basicDetails.eventMode,
      clubId: basicDetails.clubId,
      about: basicDetails.about,
      description: basicDetails.description,
      sport: basicDetails.sport,
      startDate: basicDetails.startDate,
      endDate: basicDetails.endDate,
      registrationStartDate: basicDetails.registrationStartDate,
      registrationEndDate: basicDetails.registrationEndDate,
      maxRegistrations: basicDetails.maxRegistrations,
      selectedImages: basicDetails.selectedImages || [],
    }
  });

  const { register, handleSubmit, setValue, watch } = methods;

  const editorConfig = {
    placeholder: 'Description',
    simple: true,
    toolbarOptions: [
      ['bold', 'italic', 'underline'],
      ['link'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['clean']
    ]
  };
  
  const eventTypes = [
    { value: 'tournament', label: 'Tournament' },
    { value: 'summerCamp', label: 'Summer Camp' },
    { value: 'apprentice', label: 'Apprentice' },
  ];

  
  const eventModes = [
    { value: 'online', label: 'Online' },
    { value: 'offline', label: 'Offline' },
    { value: 'hybrid', label: 'Hybrid' },
  ];

  const eventVenue = clubs?.map((club) => ({ value: club.id, label: `${club.name}, ${club.city}` }));

  const sports = organization?.sports?.map((sport) => ({ value: sport.id, label: sport.name }));

  const onSubmit = (data) => {
    const formattedData = {
      ...data,
      startDate: dayjs(data.startDate).format(),
      endDate: dayjs(data.endDate).format(),
      registrationStartDate: dayjs(data.registrationStartDate).format(),
      registrationEndDate: dayjs(data.registrationEndDate).format(),
      selectedImages: data.selectedImages,
    };
    
    handleBasicDetails(formattedData);
    nextStep();
  };

  useImperativeHandle(ref, () => ({
    onNextStep: () => {
      methods.handleSubmit(onSubmit)();
    },
  }));

  const handleDateChange = (field) => (date) => {
    setValue(field, date, { shouldValidate: true });
  };

  const { galleryImages } = useSelector((state) => state.organization);

  const [showImageSelector, setShowImageSelector] = useState(false);

  const handleImageSelection = (image) => {
    const currentSelected = watch('selectedImages') || [];
    let newSelected;
    
    if (currentSelected.some(selected => selected.id === image.id)) {
      // Remove if already selected
      newSelected = currentSelected.filter(item => item.id !== image.id);
    } else {
      // Add if not selected
      newSelected = [...currentSelected, image];
    }
    
    // Update form
    setValue('selectedImages', newSelected);
    
    // Update event create state using the hook
    handleBasicDetails({
      ...basicDetails,
      selectedImages: newSelected
    });
  };

  return (
    <FormProvider {...methods} ref={formRef}>
      <Box>
        <Stack spacing={3} sx={{ py: 2 }}>
            <TextField 
              fullWidth
              name="name" 
              placeholder="Event Name"
              InputProps={{
                endAdornment: (
                  <Tooltip title={tooltips.name} placement="top">
                    <IconButton size="small" sx={{ padding: 0.2 }}>
                      <Iconify icon="eva:info-fill" width={20} height={20} />
                    </IconButton>
                  </Tooltip>
                ),
              }}
              {...register('name')}
            />

<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
<TextField
            select
            sx={{ minWidth: { xs: '100%', sm: '20%' } }}
            label="Event Type"
            {...register('eventType')}
          >
            {eventTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            sx={{ minWidth: { xs: '100%', sm: '20%' } }}
            label="Event Mode"
            {...register('eventMode')}
          >
            {eventModes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            </TextField>

             <Autocomplete
              fullWidth
              options={eventVenue || []}
              getOptionLabel={(option) => option?.label || ''}
              value={eventVenue?.find(venue => venue.value === watch('clubId')) || null}
              onChange={(_, newValue) => {
                setValue('clubId', newValue?.value || null);
              }}
              renderOption={(props, option) => (
                <ListItem {...props}>
                  {option.label}
                </ListItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Event Venue"
                />
              )}
            />
          </Stack>
          <TextField 
              fullWidth
              name="about" 
              placeholder="About Event"
              InputProps={{
                endAdornment: (
                  <Tooltip title={tooltips.about} placement="top">
                    <IconButton size="small" sx={{ padding: 0.2 }}>
                      <Iconify icon="eva:info-fill" width={20} height={20} />
                    </IconButton>
                  </Tooltip>
                ),
                maxLength: 250
              }}
              helperText={`${watch('about')?.length || 0}/250 characters`}
              {...register('about')}
            />
          <Stack spacing={1.5}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography variant="subtitle2">Description</Typography>
              <Tooltip title={tooltips.description} placement="top">
                <IconButton size="small" sx={{ padding: 0.2 }}>
                  <Iconify icon="eva:info-fill" width={20} height={20} />
                </IconButton>
              </Tooltip>
            </Stack>
            <RHFEditor 
              name="description"
              {...editorConfig}
            />
          </Stack>

          <TextField
          select
          fullWidth
          label="Select Sport"
          {...register('sport')}
        >
          {sports?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Box> 
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 2 }}>
            <Typography variant="subtitle2">Registration Dates</Typography>
            <Tooltip title={tooltips.registrationDates} placement="top">
              <IconButton size="small">
                <Iconify icon="eva:info-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          </Stack>
            <Stack direction="row" spacing={2} >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  value={watch('registrationStartDate')}
                  onChange={handleDateChange('registrationStartDate')}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                <DatePicker
                  label="End Date"
                  value={watch('registrationEndDate')}
                  onChange={handleDateChange('registrationEndDate')}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Stack>
            </Box>
          <Box>
           <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 2 }}>
            <Typography variant="subtitle2">Event Dates</Typography>
            <Tooltip title={tooltips.eventDates} placement="top">
              <IconButton size="small">
                <Iconify icon="eva:info-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          </Stack>
           <Stack direction="row" spacing={2} >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  value={watch('startDate')}
                  onChange={handleDateChange('startDate')}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                <DatePicker
                  label="End Date"
                  value={watch('endDate')}
                  onChange={handleDateChange('endDate')}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Stack>
            </Box>
            <TextField
            type="number"
            sx={{ maxWidth: 260 }}
            placeholder="Max registration per person"
            InputProps={{
              endAdornment: (
                <Tooltip title={tooltips.maxRegistrations} placement="top">
                  <IconButton size="small" sx={{ padding: 0.2 }}>
                    <Iconify icon="eva:info-fill" width={20} height={20} />
                  </IconButton>
                </Tooltip>
              ),
            }}
            {...register('maxRegistrations')}
          />

          <Stack spacing={1.5}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography variant="subtitle2">Select Images</Typography>
              <Tooltip title="Select images from your gallery" placement="top">
                <IconButton 
                  size="small" 
                  sx={{ padding: 0.2 }}
                  onClick={() => setShowImageSelector(!showImageSelector)}
                >
                  <Iconify icon={showImageSelector ? "eva:eye-off-fill" : "eva:eye-fill"} width={20} height={20} />
                </IconButton>
              </Tooltip>
            </Stack>
            
            {showImageSelector && (
              <Card sx={{ p: 2 }}>
                <ImageList sx={{ maxHeight: 300 }} cols={3} rowHeight={164}>
                  {galleryImages?.map((image) => (
                    <ImageListItem key={image.id} sx={{ position: 'relative' }}>
                      <img
                        src={image.preview}
                        alt={image.name}
                        loading="lazy"
                        style={{ borderRadius: 1 }}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 8,
                          right: 8,
                          bgcolor: 'background.paper',
                          borderRadius: '50%'
                        }}
                      >
                        <Checkbox
                          size="small"
                          checked={watch('selectedImages')?.some(selected => selected.id === image.id)}
                          onChange={() => handleImageSelection(image)}
                          sx={{ p: 0.5 }}
                        />
                      </Box>
                    </ImageListItem>
                  ))}
                </ImageList>
              </Card>
            )}
          </Stack>

        </Stack>
      </Box>
    </FormProvider>
  );
});

export default EventDetails;

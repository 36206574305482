import React, { useEffect, useMemo, useRef, useState } from 'react';
// @mui
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box, Card, Stack, Link, Alert, Tooltip, Container, Typography,
    TextField, SpeedDial, SpeedDialIcon, SpeedDialAction, Backdrop, Dialog,
    DialogTitle, MenuItem, Slider, Slide, DialogContent, FormControl, InputLabel,
    Select, Icon, DialogActions, Button, CardContent, CardActionArea, Grid, IconButton, Radio, FormControlLabel, FormLabel, ButtonGroup, Rating, RadioGroup
} from '@mui/material';
import { m } from 'framer-motion';

import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { ArrowForwardIos, ArrowBackIos } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import CloseIcon from '@mui/icons-material/Close';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import useResponsive from '../../../hooks/useResponsive';
import useAuth from '../../../hooks/useAuth';



import { setAssessmentGroups, setAssessmentModalState, updateAssessmentRatingCell } from '../../../redux/slices/assessmentSlice';
import { getAssessmentById, getAssessmentTemplateByAssessmentId, getPlayerRatingsByAssessmentId, getPlayersByAssessmentId } from '../../../redux/actions/assessmentAction';
import { socket } from '../../../socket';
import RatingTable from './RatingTable';
import LoadingScreen from '../../../components/LoadingScreen';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        display: 'flex',
        width: '100%',
        height: '100%',
        maxHeight: "100vh",
        maxWidth: "100vw",
        position: 'relative',
        paddingBottom: 50
    },
}));

const getItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k + offset}-${new Date().getTime()}`,
        content: `item ${k + offset}`
    }));

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid,
    margin: `0 ${grid}px ${grid}px ${grid}px`,

    // Change background color and text color if dragging
    background: isDragging ? "#66BB6A" : "#E0E0E0",
    color: isDragging ? "white" : "black",

    // Styles to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#90CAF9" : "#F5F5F5", // Light blue when dragging over, light grey otherwise
    width: 250,
    position: 'relative'
});

// ----------------------------------------------------------------------

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ratings = [{ label: 'Poor', value: 1 }, { label: 'Fair', value: 2 }, { label: 'Average', value: 3 }, { label: 'Good', value: 4 }, { label: 'Excellent', value: 5 }]

const theme = createTheme({});

const AEF = () => {
    const { method } = useAuth();
    const smUp = useResponsive('up', 'sm');
    const mdUp = useResponsive('up', 'md');
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(2);
    const socketRef = useRef(null);
    const [open, setOpen] = React.useState(false);
    const isInitialRender = useRef(true);

    const [state, setState] = useState([[], []]);
    const [groupSkillsOrderedArray, setGroupSkillsOrderedArray] = useState({})
    const [ratingComment, setRatingComment] = useState([]);
    const { assessmentId } = useParams();
    const { assessmentPlayers, assessmentDetails: ASSESSMENT, groups, groupStates, modalState, assessmentState, assessmentPlayerRatingsDetails } = useSelector(state => state.assessment);
    const [skillComments, setSkillComments] = useState([]);

    useEffect(() => {
        dispatch(getAssessmentById(assessmentId));
        dispatch(getAssessmentTemplateByAssessmentId(assessmentId));
        dispatch(getPlayerRatingsByAssessmentId(assessmentId));
        dispatch(getPlayersByAssessmentId(assessmentId));
        dispatch(setAssessmentGroups(JSON.parse(localStorage.getItem(`${assessmentId}-g`))) || []);

    }, [assessmentId])
    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
        } else {
            localStorage.setItem(`${ASSESSMENT.id}-g`, JSON.stringify(groups))
        }
    }, [groups]);
    useEffect(() => {
        setState([assessmentPlayers, []]);
    }, [assessmentPlayers]);

    useEffect(() => {
        socketRef.current = socket.connect();
        socketRef.current.on("connect", () => {
            // console.log(socketRef.current.id);
        });
        socketRef.current.on("disconnect", () => {
            // console.log(socketRef.current.id);
        });
        return () => {
            // console.log('disconnecting from socket');
            socketRef.current.disconnect();
        };
    }, []);

    const sendChangeEventToServer = (data) => {
        dispatch(updateAssessmentRatingCell({ data, type: 'updating' }));
        socket.timeout(5000).emit('upsertAssessmentRating', data, (response) => {
        });
    }

    socket.on('upsertAssessmentRating', (response) => {
        dispatch(updateAssessmentRatingCell({ data: response[0], type: 'updated' }))
    });
    const columns = React.useMemo(
        () => {
            if (ASSESSMENT && ASSESSMENT.id) return [
                {
                    header: 'Player Name',
                    footer: props => props.column.id,
                    columns: [
                        {
                            accessorFn: row => {
                                return renderPlayerName(row)
                            },
                            id: 'name',
                            cell: info => info.getValue(),
                            header: <span>Player Name</span>,
                            footer: props => props.column.id,
                            columnPinning: 'left'
                        },
                    ],
                },
                ...ASSESSMENT.sections.map(section => section.levelSkillGroups.map(levelSkillGroup => {
                    return {
                        header: `${section.name} ${levelSkillGroup.name}`,
                        footer: props => props.column.name,
                        columns: levelSkillGroup.skills.map(skill => {
                            return {
                                accessorFn: row => defaultColumn({ row, section, levelSkillGroup, skill }),
                                id: `${levelSkillGroup.id}-${skill.id}`,
                                header: <span>{skill.name}</span>,
                                cell: info => info.getValue(),
                                footer: props => props.column.id,
                            };
                        }),
                    };
                }))
            ].flat();
            return []
        },
        [ASSESSMENT]
    );

    const AssessmentScreenActionButton = useMemo(() => {
        const { firstPlayer, firstSection, firstLevelSkillGroup, firstSkill, lastPlayer, lastSection, lastLevelSkillGroup, lastSkill } = assessmentState;
        let result = <>
            {/* <Button fullWidth color="secondary" variant="outlined" onClick={() => handleAssessmentAction(['previous_player'])} >
                <Typography variant="caption" > Previous Player </Typography>
            </Button> */}
            <Button fullWidth color="secondary" variant="contained" onClick={() => handleAssessmentAction(['next_flow'])}>
                <Typography variant="caption" > Next </Typography>
            </Button>
        </>;

        if (lastPlayer) {
            if (lastSection && lastLevelSkillGroup) {
                result = (
                    <>
                        {/* <Button fullWidth color="secondary" variant="outlined" onClick={() => handleAssessmentAction(['previous_section'])}>
                            <Typography variant="caption" > Previous Section </Typography>

                        </Button> */}
                        <Button fullWidth color="secondary" variant="contained" onClick={() => dispatch(setAssessmentModalState({
                            type: 'closeModal'
                        }))}>
                            <Typography variant="caption" > Complete </Typography>
                        </Button>
                    </>
                );
            }
            // } else if (lastLevelSkillGroup && lastSkill) {
            //     result = (
            //         <>
            //             {/* <Button fullWidth color="secondary" variant="outlined" onClick={() => handleAssessmentAction(['previous_skill_group'])}>
            //                 <Typography variant="caption" > Previous Skill Group </Typography>


            //             </Button> */}
            //             {/* <Button fullWidth color="secondary" variant="contained" onClick={() => handleAssessmentAction(['next_skill'])}>
            //                 <Typography variant="caption" > Next Skill Group </Typography>


            //             </Button> */}
            //         </>
            //     );
            // } else if (lastSkill) {
            //     result = (
            //         <>
            //             {/* <Button fullWidth color="secondary" variant="outlined" onClick={() => handleAssessmentAction(['previous_skill'])}>
            //                 <Typography variant="caption" > Previous Skill </Typography>


            //             </Button> */}
            //             <Button fullWidth color="secondary" variant="contained" onClick={() => handleAssessmentAction(['next_skill_group', 'next_player'])}>
            //                 <Typography variant="caption" > Next Skill Group </Typography>


            //             </Button>
            //         </>
            //     );
            // } else {
            //     result = (
            //         <>
            //             <Button fullWidth color="secondary" variant="outlined" onClick={() => handleAssessmentAction(['previous_player'])}>
            //                 <Typography variant="caption" > Previous Player </Typography>

            //             </Button>
            //             <Button fullWidth color="secondary" variant="contained" onClick={() => handleAssessmentAction(['next_skill', 'next_player'])}>
            //                 <Typography variant="caption" > Next Skill </Typography>


            //             </Button>

            //         </>
            //     );
            // }
        }

        return result;
    }, [assessmentState]);

    useEffect(() => {
        if (assessmentState?.levelSkillGroup) {
            setRatingComment(assessmentState.levelSkillGroup.skills.map(skill => {
                return assessmentPlayerRatingsDetails[`${assessmentState.assessmentPlayer.id}-${assessmentState.levelSkillGroup.skillGroupId}-${skill.id}`]?.comments || ''
            }));
        } else {
            setRatingComment([]);
        }
    }, [assessmentState])

    const onDragEnd = (result) => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === dInd) {
            const items = reorder(state[sInd], source.index, destination.index);
            const newState = [...state];
            newState[sInd] = items;
            setState(newState);
        } else {
            const result = move(state[sInd], state[dInd], source, destination);
            const newState = [...state];
            newState[sInd] = result[sInd];
            newState[dInd] = result[dInd];

            setState(newState.filter(group => group.length));
        }
    }


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const handleRatingChange = ({ skill, rating, comments }) => {
        sendChangeEventToServer({
            "assessmentPlayerId": assessmentState.assessmentPlayer.id,
            "skillId": skill.id,
            "skillsGroupId": assessmentState.levelSkillGroup.skillGroupId,
            "rating": rating,
            "comments": comments,
        })
    };
    const saveAssessmentComment = (skill) => {
        sendChangeEventToServer({
            "assessmentPlayerId": assessmentState.assessmentPlayer.id,
            "skillId": skill.id,
            "skillsGroupId": assessmentState.levelSkillGroup.skillGroupId,
            "comments": skill.comments,
            "rating": skill?.rating
        })
    }
    const DND = DNDHelper({ state, setState });

    const handleAssessmentAction = (action) => {
        dispatch(setAssessmentModalState({
            data: action,
            type: 'assessment_card_change'
        }))
    }


    const defaultColumn = ({ row, section, levelSkillGroup, skill }) => {
        const key = `${row.id}-${levelSkillGroup.skillGroupId}-${skill.id}`;
        const rating = assessmentPlayerRatingsDetails[key]
        return {
            rating: rating?.rating,
            comments: rating?.comments
        };
    };


    // if(loading) return <LoadingScreen />

    return (

        <RootStyle>
            <RatingTable {...{ data: [...assessmentPlayers], columns, ASSESSMENT }} />
            <Dialog
                open={modalState?.isVisible}
                fullScreen={!smUp}
                fullWidth={!smUp}
                TransitionComponent={Transition}
                onClose={handleClose}
            >
                <DialogTitle>
                    <Box style={{ position: "relative", zIndex: 2 }}>
                        {modalState?.currentStep === 'selectBatch' && <>Select / Add a group to start</>}
                        {modalState?.currentStep === 'addBatch' && <>Select players</>}
                        {modalState?.currentStep === 'assessment' &&
                            <Box>
                                <div>
                                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Tooltip title={`${assessmentState?.prevAssessmentPlayer?.player?.firstName} ${assessmentState?.prevAssessmentPlayer?.player?.lastName}`}>
                                            <IconButton onClick={() => handleAssessmentAction(['previous_player'])} color="primary" aria-label="delete">
                                                < KeyboardDoubleArrowLeftIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Typography align='center' variant='h6'> {assessmentState?.assessmentPlayer?.player?.firstName} {assessmentState?.assessmentPlayer?.player?.lastName}  {` ( ${1 + assessmentState.PI}/${assessmentState.A_PLAYER_COUNT} )`}   </Typography>
                                        <Tooltip title={`${assessmentState?.nextAssessmentPlayer?.player?.firstName} ${assessmentState?.nextAssessmentPlayer?.player?.lastName}`}>
                                            <IconButton onClick={() => handleAssessmentAction(['next_player'])} color="primary" aria-label="delete">
                                                <KeyboardDoubleArrowRightIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Tooltip title={`${assessmentState?.prevAssessmentSection?.name}`}>
                                            <IconButton onClick={() => handleAssessmentAction(['previous_section'])} color="primary" aria-label="delete">
                                                <KeyboardDoubleArrowLeftIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Typography fontWeight={600} >{assessmentState?.section?.name}  {` ( ${1 + assessmentState.SECI}/${assessmentState.A_SECTION_COUNT} )`} </Typography>
                                        <Tooltip title={`${assessmentState?.nextAssessmentSection?.name}`}>
                                            <IconButton onClick={() => handleAssessmentAction(['next_section'])} color="primary" aria-label="delete">
                                                <KeyboardDoubleArrowRightIcon />
                                            </IconButton>
                                        </Tooltip>

                                    </Stack>
                                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Tooltip title={`${assessmentState?.prevAssessmentSG?.name}`}>
                                            <IconButton onClick={() => handleAssessmentAction(['previous_skill_group'])} color="primary" aria-label="delete">
                                                <KeyboardDoubleArrowLeftIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Typography fontWeight={600} >{assessmentState?.levelSkillGroup?.name}  {` ( ${1 + assessmentState.LSGI}/${assessmentState.A_SKILL_GROUP_COUNT} )`} </Typography>
                                        <Tooltip title={`${assessmentState?.nextAssessmentSG?.name}`}>
                                            <IconButton onClick={() => handleAssessmentAction(['next_skill_group'])} color="primary" aria-label="delete">
                                                <KeyboardDoubleArrowRightIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </div>
                            </Box>
                        }
                        <CloseIcon onClick={() => {
                            dispatch(setAssessmentModalState({
                                type: 'closeModal'
                            }))
                        }} color='error' style={{ position: 'absolute', top: -16, right: -16, cursor: 'pointer' }} />
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Stack justifyContent={'space-between'} pt={2} maxHeight={'100%'}>
                        <Stack>

                            {modalState?.currentStep === 'selectBatch' && <GroupList selectedGroupId={modalState?.selectedGroupId} groups={groups} onGroupClick={(id) => {
                                dispatch(setAssessmentModalState({
                                    type: 'groupSelect',
                                    id
                                }))
                            }}
                                handleGroupDelete={(id) => {
                                    dispatch(setAssessmentModalState({
                                        type: 'groupDelete',
                                        id
                                    }))
                                }} onCreateNewGroup={() => {
                                    dispatch(setAssessmentModalState({
                                        type: 'addNewGroup'
                                    }))
                                }} />}

                            {modalState?.currentStep === 'addBatch' && <div style={{ display: "flex" }}>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    {
                                        DND
                                    }
                                </DragDropContext>
                            </div>}

                            {modalState?.currentStep === 'reorderSkillGroupandSkills' && <div style={{ display: "flex" }}>
                                <AssessmentDragDrop ASSESSMENT={ASSESSMENT} onGroupUpdate={setGroupSkillsOrderedArray} />
                            </div>}

                        </Stack>
                        {modalState?.currentStep === 'assessment' && <Stack>
                            <Stack justifyContent={'space-between'} alignItems={'center'}>
                                {assessmentState.levelSkillGroup?.skills?.map((skill, index) => {
                                    return <Stack>
                                        <Typography pb={.5}>{skill.name}</Typography>
                                        {
                                            <Box pb={1}>
                                                <FormControl>
                                                    <ButtonGroup color="primary" aria-label="rating">
                                                        <Button
                                                            key={`${skill.id}-${index}-1`}
                                                            color='error'
                                                            onClick={() => handleRatingChange({ skill, rating: 1, comments: assessmentPlayerRatingsDetails[`${assessmentState.assessmentPlayer.id}-${assessmentState.levelSkillGroup.skillGroupId}-${skill.id}`]?.comments, })}
                                                            variant={assessmentPlayerRatingsDetails[`${assessmentState.assessmentPlayer.id}-${assessmentState.levelSkillGroup.skillGroupId}-${skill.id}`]?.rating === 1 ? 'contained' : 'outlined'}
                                                        >
                                                            <Typography variant='caption' fontWeight={'bold'}>Poor</Typography>
                                                        </Button>

                                                        <Button
                                                            key={`${skill.id}-${index}-2`}
                                                            color='error'
                                                            sx={{ opacity: .8 }}
                                                            onClick={() => handleRatingChange({ skill, rating: 2, comments: assessmentPlayerRatingsDetails[`${assessmentState.assessmentPlayer.id}-${assessmentState.levelSkillGroup.skillGroupId}-${skill.id}`]?.comments })}
                                                            variant={assessmentPlayerRatingsDetails[`${assessmentState.assessmentPlayer.id}-${assessmentState.levelSkillGroup.skillGroupId}-${skill.id}`]?.rating === 2 ? 'contained' : 'outlined'}
                                                        >
                                                            <Typography variant='caption' fontWeight={'bold'}>Fair</Typography>
                                                        </Button>
                                                        <Button
                                                            key={`${skill.id}-${index}-3`}
                                                            color='warning'
                                                            onClick={() => handleRatingChange({ skill, rating: 3, comments: assessmentPlayerRatingsDetails[`${assessmentState.assessmentPlayer.id}-${assessmentState.levelSkillGroup.skillGroupId}-${skill.id}`]?.comments })}
                                                            variant={assessmentPlayerRatingsDetails[`${assessmentState.assessmentPlayer.id}-${assessmentState.levelSkillGroup.skillGroupId}-${skill.id}`]?.rating === 3 ? 'contained' : 'outlined'}
                                                        >
                                                            <Typography variant='caption' fontWeight={'bold'}>Average</Typography>
                                                        </Button>
                                                        <Button
                                                            key={`${skill.id}-${index}-4`}
                                                            color='success'

                                                            onClick={() => handleRatingChange({ skill, rating: 4, comments: assessmentPlayerRatingsDetails[`${assessmentState.assessmentPlayer.id}-${assessmentState.levelSkillGroup.skillGroupId}-${skill.id}`]?.comments })}
                                                            variant={assessmentPlayerRatingsDetails[`${assessmentState.assessmentPlayer.id}-${assessmentState.levelSkillGroup.skillGroupId}-${skill.id}`]?.rating === 4 ? 'contained' : 'outlined'}
                                                        >
                                                            <Typography variant='caption' fontWeight={'bold'}>Good</Typography>
                                                        </Button>
                                                        <Button
                                                            key={`${skill.id}-${index}-5`}
                                                            onClick={() => handleRatingChange({ skill, rating: 5, comments: assessmentPlayerRatingsDetails[`${assessmentState.assessmentPlayer.id}-${assessmentState.levelSkillGroup.skillGroupId}-${skill.id}`]?.comments })}
                                                            variant={assessmentPlayerRatingsDetails[`${assessmentState.assessmentPlayer.id}-${assessmentState.levelSkillGroup.skillGroupId}-${skill.id}`]?.rating === 5 ? 'contained' : 'outlined'}
                                                        >
                                                            <Typography variant='caption' fontWeight={'bold'}>Excellent</Typography>
                                                        </Button>
                                                    </ButtonGroup>
                                                </FormControl>
                                            </Box>
                                        }
                                        {
                                            (assessmentPlayerRatingsDetails[`${assessmentState.assessmentPlayer.id}-${assessmentState.levelSkillGroup.skillGroupId}-${skill.id}`]?.rating === 1 || assessmentPlayerRatingsDetails[`${assessmentState.assessmentPlayer.id}-${assessmentState.levelSkillGroup.skillGroupId}-${skill.id}`]?.rating === 2 || assessmentPlayerRatingsDetails[`${assessmentState.assessmentPlayer.id}-${assessmentState.levelSkillGroup.skillGroupId}-${skill.id}`]?.rating === 3) &&
                                            <Box component={m.div} pt={1} pb={1}>
                                                <TextField size='small' fullWidth
                                                    autocomplete
                                                    id={`assessment-comment4${skill.id}`} value={ratingComment[index] || ''} onChange={(e) =>
                                                        setRatingComment(ratingComment.map((rating, commentIndex) => commentIndex === index ? e.target.value : rating))} onBlur={() => {
                                                            saveAssessmentComment({
                                                                ...skill, rating: assessmentPlayerRatingsDetails[`${assessmentState.assessmentPlayer.id}-${assessmentState.levelSkillGroup.skillGroupId}-${skill.id}`]?.rating,
                                                                comments: ratingComment.find((rating, commentIndex) => commentIndex === index)
                                                            })
                                                        }} label="Comment" variant="outlined" />
                                            </Box>
                                        }

                                    </Stack>
                                })}
                            </Stack>
                        </Stack>}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    {modalState?.currentStep === 'selectBatch' && <Button disabled={!modalState.selectedGroupId} onClick={() => {
                        dispatch(setAssessmentModalState({
                            type: 'startAssessmentForCurrentGroup'
                        }))
                    }}>Next </Button>}
                    {modalState?.currentStep === 'addBatch' && <Button onClick={() => {
                        dispatch(setAssessmentModalState({
                            type: 'reorderSkillGroupandSkills'
                        }))
                    }}>Next </Button>}
                    {modalState?.currentStep === 'reorderSkillGroupandSkills' && <Button onClick={() => {
                        dispatch(setAssessmentModalState({
                            type: 'AddGroupAndStartAssessment',
                            players: state[1],
                            assessment: groupSkillsOrderedArray
                        }))
                    }}>Start Assessment </Button>}

                    {modalState?.currentStep === 'assessment' && AssessmentScreenActionButton}

                    {assessmentState.lastPlayer && assessmentState.lastSkill && assessmentState.lastLevelSkillGroup && assessmentState.lastSection
                    }

                </DialogActions>
            </Dialog>
            <div style={{ position: 'absolute', bottom: 0, width: '100%', padding: 10 }}>
                <Button fullWidth variant="contained" onClick={() => dispatch(setAssessmentModalState({
                    type: 'start'
                }))}>Start Assessment</Button>
            </div>
        </RootStyle >

    );
};

export default AEF;

const Filter = ({
    column,
    table,
}) => {
    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id)

    const columnFilterValue = column.getFilterValue()

    return typeof firstValue === 'number' ? (
        <div className="flex space-x-2">
            <InputBase
                type="number"
                value={(columnFilterValue)?.[0] ?? ''}
                onChange={e =>
                    column.setFilterValue((old) => [
                        e.target.value,
                        old?.[1],
                    ])
                }
                placeholder={`Min`}
                className="w-24 border shadow rounded"
            />
            <InputBase
                type="number"
                value={(columnFilterValue)?.[1] ?? ''}
                onChange={e =>
                    column.setFilterValue((old) => [
                        old?.[0],
                        e.target.value,
                    ])
                }
                placeholder={`Max`}
                className="w-24 border shadow rounded"
                inputProps={{ 'aria-label': 'search' }}
            />
        </div>
    ) : (
        <InputBase
            value={(columnFilterValue ?? '')}
            onChange={e => column.setFilterValue(e.target.value)}
            placeholder={`Search...`}
            className="w-36 border shadow rounded"
            inputProps={{ 'aria-label': 'search' }}
        />
    )
}

const renderPlayerName = (row) => `${row.player?.firstName || ''} ${row.player?.lastName || ''}`.trim();

const DNDHelper = ({ state, setState }) => state.map((el, ind) => (
    <Droppable key={ind} droppableId={`${ind}`}>
        {(provided, snapshot) => (
            <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
            >
                <Typography
                    py={2}
                    mb={2}
                    align={'center'}
                    style={{ position: 'sticky', top: 2, backgroundColor: '#fff', zIndex: 1 }}>{ind === 0 ? 'All players' : 'Selected'}
                </Typography>
                {el.map((item, index) => (
                    <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                    >
                        {(provided, snapshot) => (
                            <Card
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                )}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-around"
                                    }}
                                >
                                    {ind === 1 && <span> <ArrowBackIos fontSize="small" onClick={() => {
                                        const result = move(state[1], state[0], { index, droppableId: 1 }, { index: state[0].length, droppableId: 0 })
                                        setState([result[0], result[1]])

                                    }} /></span>} <Typography variant="caption"  >{item?.player?.firstName} {item?.player?.lastName}</Typography> {ind === 0 && <span> <ArrowForwardIos fontSize="small" onClick={() => {
                                        const result = move(state[0], state[1], { index, droppableId: 0 }, { index: state[1].length, droppableId: 1 })
                                        setState([result[0], result[1]])

                                    }} /></span>}
                                </div>
                            </Card>
                        )}
                    </Draggable>
                ))}
                {provided.placeholder}
            </div>
        )}
    </Droppable>

))


const SectionCard = ({ item }) => (
    <div>
        {item.name}
    </div>
)

const SkillGroupCard = ({ item }) => {
    <div>
        {item.name}
    </div>
}



const AssessmentDragDrop = ({ ASSESSMENT, onGroupUpdate }) => {
    const [assessment, setAssessment] = useState(ASSESSMENT);

    useEffect(() => {
        onGroupUpdate(assessment)
    }, [assessment])
    const onDragEnd = (result) => {
        if (!result.destination) return;

        const source = result.source;
        const destination = result.destination;

        if (source.droppableId === destination.droppableId) {
            if (source.index === destination.index) return;

            if (result.type === 'SECTION') {
                const newSections = Array.from(assessment.sections);
                const [movedSection] = newSections.splice(source.index, 1);
                newSections.splice(destination.index, 0, movedSection);
                setAssessment({ ...assessment, sections: newSections });
            }

            if (result.type === 'SKILL_GROUP') {
                const sectionIndex = assessment.sections.findIndex(
                    (section) => section.id === source.droppableId
                );
                if (sectionIndex === -1) return;

                const newSections = Array.from(assessment.sections);
                const section = newSections[sectionIndex];
                const [movedSkillGroup] = section.levelSkillGroups.splice(source.index, 1);
                section.levelSkillGroups.splice(destination.index, 0, movedSkillGroup);
                setAssessment({ ...assessment, sections: newSections });
            }


            if (result.type === 'SKILL') {
                const sectionIndex = assessment.sections.findIndex(
                    (section) => section.id === source.droppableId
                );
                if (sectionIndex === -1) return;

                const section = assessment.sections[sectionIndex];
                const levelSkillGroupIndex = section.levelSkillGroups.findIndex(
                    (skillGroup) =>
                        skillGroup.id === result.destination.droppableId
                );
                if (levelSkillGroupIndex === -1) return;

                const newSections = [...assessment.sections];
                const newSkillGroups = [...section.levelSkillGroups];
                const skillGroup = newSkillGroups[levelSkillGroupIndex];

                const movedSkill = skillGroup.skills.splice(source.index, 1)[0];
                skillGroup.skills.splice(destination.index, 0, movedSkill);

                newSkillGroups[levelSkillGroupIndex] = skillGroup;
                newSections[sectionIndex] = { ...section, levelSkillGroups: newSkillGroups };

                setAssessment({ ...assessment, sections: newSections });
            }

        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="assessment" type="SECTION" direction="vertical">
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {assessment.sections.map((section, sectionIndex) => (
                            <Draggable key={section.id} draggableId={section.id} index={sectionIndex}>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <Typography variant='h3' pb={1} fontWeight={200}> {section.name}</Typography>
                                        <Droppable droppableId={section.id} type="SKILL_GROUP" direction="vertical">
                                            {(provided) => (
                                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                                    {section.levelSkillGroups.map((levelSkillGroup, levelSkillGroupIndex) => (
                                                        <Draggable
                                                            key={levelSkillGroup.id}
                                                            draggableId={levelSkillGroup.id}
                                                            index={levelSkillGroupIndex}
                                                        >
                                                            {(provided) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <Typography variant='h6' fontWeight={200} pl={3}> {levelSkillGroup.name}</Typography>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};


const GroupList = ({ groups, onGroupClick, handleGroupDelete, selectedGroupId, onCreateNewGroup }) => {

    return <Stack direction={'row'} justifyContent={'space-around'} width={'100%'} flexWrap={'wrap'}>
        {
            (groups || []).map((group) => {
                return (
                    <Card sx={{ width: '120px', marginBottom: '20px' }} style={{ cursor: 'pointer', position: 'relative' }}
                        onClick={() => { onGroupClick(group.id) }}>
                        <CardActionArea>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography variant="caption" component="div" sx={{ mt: 1, textAlign: 'center' }}>
                                    {group.name} Group
                                </Typography>
                                <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5, textAlign: 'center' }}>
                                    {group.players.length} Players
                                </Typography>
                            </CardContent>
                            <Grid container sx={{ position: 'absolute', top: 10 }}>
                                <Grid item sx={{ paddingLeft: 1 }} xs={6}>
                                    {selectedGroupId && group.id === selectedGroupId && <CheckCircleIcon fontSize="small" color="success" />}
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack sx={{ paddingRight: 1 }} justifyContent={'flex-end'} direction={'row'}><DeleteForeverIcon onClick={(e) => {
                                        e.stopPropagation();
                                        handleGroupDelete(group.id);
                                    }} fontSize="small" sx={{ color: 'red' }} /></Stack>
                                </Grid>
                            </Grid>
                        </CardActionArea>
                    </Card>
                )
            })
        }
        <Card sx={{ width: '120px', marginBottom: '20px' }} style={{ cursor: 'pointer', position: 'relative' }}
            onClick={onCreateNewGroup}
        >
            <CardActionArea>
                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <AddCircleOutlineIcon fontSize="large" />
                    <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5, textAlign: 'center' }}>
                        Add new
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    </Stack>

}
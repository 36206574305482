import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parse, isValid, addDays } from 'date-fns'; // Import parse instead of parseISO
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    TablePagination,
    TextField,
    Button,
    IconButton
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Page from '../../components/Page';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../routes/paths';
import { getPayouts } from '../../redux/slices/payouts';
import { getUserOrganization } from '../../redux/actions/organizationAction';
import useOrg from '../../hooks/useOrg';
import Iconify from '../../components/Iconify';


const TABLE_HEAD = [
    { id: 'settlementDate', label: 'Settlement Date', alignRight: false },
    { id: 'payAmount', label: 'Pay Amount', alignRight: false },
    { id: 'subId', label: 'Account', alignRight: false },
    { id: 'settlementBank', label: 'Settlement Bank', alignRight: false },
    { id: 'accountNo', label: 'Account Number', alignRight: false },
    { id: 'utrNo', label: 'UTR Number', alignRight: false },
];

const PayoutList = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { selectedClubs } = useOrg();
    const { payouts, isLoading } = useSelector((state) => state.payouts);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchParams, setSearchParams] = useSearchParams();

    // Extract selectedDate from search params or set to current date
    const initialDateParam = searchParams.get('selectedDate');
    const initialDate = initialDateParam ? parse(initialDateParam, 'dd-MM-yyyy', new Date()) : new Date();
    const [selectedDate, setSelectedDate] = useState(initialDate);

    useEffect(() => {
        // If selectedDate is not in search params, set it to current date
        if (!initialDateParam) {
            const formattedDate = format(selectedDate, 'dd-MM-yyyy');
            setSearchParams({ selectedDate: formattedDate });
        }
    }, [initialDateParam, selectedDate, setSearchParams]);

    useEffect(() => {
        dispatch(getUserOrganization());
        const formattedDate = format(selectedDate, 'dd-MM-yyyy');
        dispatch(getPayouts(formattedDate));
        console.log("selectedDate", selectedDate)
    }, [dispatch, selectedDate]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDateChange = (date) => {
        if (date) {
            setSelectedDate(date);
            const formattedDate = format(date, 'dd-MM-yyyy');
            setSearchParams({ selectedDate: formattedDate });
            setPage(0);
        }
    };

    const payoutsArray = Array.isArray(payouts) ? payouts : [payouts].filter(Boolean);
    const totalPayouts = payoutsArray.length;

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - payoutsArray.length) : 0;

    const isPayoutNotFound = payoutsArray.length === 0;


    return (
        <Page title="Payouts: List">
            <HeaderBreadcrumbs
                heading="Payouts List"
                links={[
                    { name: 'Dashboard', href: PATH_DASHBOARD.root },
                    { name: 'Payouts', href: PATH_DASHBOARD.payouts.root },
                    { name: 'List' },
                ]}
            />

            <Card>
                <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={() => handleDateChange(addDays(selectedDate, -1))}>
                        <Iconify icon="eva:arrow-ios-back-fill" />
                    </IconButton>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Select Date"
                            value={selectedDate}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField {...params} />}
                            inputFormat="dd-MM-yyyy"
                        />
                    </LocalizationProvider>
                    <IconButton onClick={() => handleDateChange(addDays(selectedDate, 1))}>
                        <Iconify icon="eva:arrow-ios-forward-fill" />
                    </IconButton>
                </Box>

                <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {TABLE_HEAD.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.alignRight ? 'right' : 'left'}
                                    >
                                        {headCell.label}
                                    </TableCell>
                                ))}
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {payoutsArray
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    console.log('Row data:', row);
                                    
                                    // eslint-disable-next-line camelcase
                                    const { pay_Id, settlement_date: settlementDate, pay_amount: payAmount, settlement_bank: settlementBank, account_no: accountNo, utr_no: utrNo, sub_acc_Id: subAccId } = row;

                                    // eslint-disable-next-line camelcase
                                    console.log('pay_Id:', pay_Id);

                                    // Parse the date and check if it's valid
                                    const date = parse(settlementDate, 'dd-MM-yyyy', new Date());
                                    const formattedDate = isValid(date) ? format(date, 'dd-MM-yyyy') : 'Invalid Date';

                                    return (
                                        // eslint-disable-next-line camelcase
                                        <TableRow hover key={pay_Id || index} tabIndex={-1}>
                                            <TableCell align="left">{formattedDate}</TableCell>
                                            <TableCell align="left">{payAmount}</TableCell>
                                            <TableCell align="left">{subAccId}</TableCell>
                                            <TableCell align="left">{settlementBank}</TableCell>
                                            <TableCell align="left">{accountNo}</TableCell>
                                            <TableCell align="left">{utrNo}</TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    variant="contained"
                                                    component={RouterLink}
                                                    // eslint-disable-next-line camelcase
                                                    to={`${PATH_DASHBOARD.payouts.root}/${pay_Id}/details`}
                                                    // eslint-disable-next-line camelcase
                                                    onClick={() => console.log(`Navigating to: ${PATH_DASHBOARD.payouts.root}/${pay_Id}/details`)}
                                                >
                                                    View Details
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={7} />
                                </TableRow>
                            )}
                        </TableBody>
                        {isPayoutNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                                        {isLoading ? 'Loading...' :'No data found'}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalPayouts}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>
        </Page>
    );
};

export default PayoutList;
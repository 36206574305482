import { useState, useMemo } from 'react';
// @mui
import { MenuItem, Stack, Button, Checkbox } from '@mui/material';
import { alpha } from '@mui/material/styles';
// components
import { useLocation } from 'react-router-dom';
import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';
import Iconify from '../../../components/Iconify';
import useOrg from '../../../hooks/useOrg';

const SHOW_CLUB_SELECTOR = ['/dashboard/finance/overview', '/dashboard/playerAnalytics/overview'];

const normalizeCityName = (city) => {
  if (!city) return 'Other';
  return city.charAt(0).toUpperCase() + city.slice(1).toLowerCase().trim();
};

const groupClubsByCity = (clubs) => clubs.reduce((acc, club) => {
    const city = normalizeCityName(club.city);
    if (!acc[city]) acc[city] = [];
    acc[city].push(club);
    return acc;
  }, {});

const isCityFullySelected = (cityClubs, selectedClubs) => 
  cityClubs.every(club => selectedClubs.some(selected => selected?.id === club.id));

const ClubPopover = () => {
  const { onChangeClub, allClubs, selectedClubs } = useOrg();
  const [open, setOpen] = useState(null);
  const { pathname } = useLocation();
  
  const groupedClubs = useMemo(() => groupClubsByCity(allClubs), [allClubs]);
  const buttonText = useMemo(() => 
    `${selectedClubs?.[0]?.shortName} ${(selectedClubs.length > 1) ? `+ ${selectedClubs.length - 1}` : ''}`
  , [selectedClubs]);

  if (!SHOW_CLUB_SELECTOR.includes(pathname)) return null;

  const handleCityClick = (clubs) => {
    const isFullySelected = isCityFullySelected(clubs, selectedClubs);
    if (isFullySelected) {
      // If all clubs in city are selected, remove them
      const cityClubIds = clubs.map(club => club.id);
      const remainingClubs = selectedClubs
        .filter(club => !cityClubIds.includes(club.id))
        .map(club => club.id);
      onChangeClub(remainingClubs);
    } else {
      // If not all selected, add all clubs from this city
      const newClubIds = [
        ...selectedClubs.map(club => club.id),
        ...clubs.map(club => club.id)
      ];
      onChangeClub([...new Set(newClubIds)]);
    }
  };

  return (
    <>
      {/* <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && { bgcolor: 'action.selected' }),
          borderRadius: '1px solid red'
        }}
      >
        <>
          <Image disabledEffect src={organization?.metadata?.logo} alt={organization.name} />
        </>
      </IconButtonAnimate> */}

      <Button
        fullWidth
        size="large"
        color="primary"
        startIcon={<Iconify icon={'mingcute:badminton-line'} />}
        onClick={(e) => setOpen(e.currentTarget)}
        sx={{
          display: {xs: 'flex'},
          fontSize: 12,
          bgcolor: theme => alpha(theme.palette.primary.dark, theme.palette.action.selectedOpacity),
        }}
      >
        {buttonText}
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(null)}
        sx={{
          mt: 1.5,
          ml: 0.75,
          maxHeight: '80vh',
          overflow: 'auto',
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {Object.entries(groupedClubs).map(([city, clubs]) => (
            <div key={city}>
              <MenuItem
                onClick={() => handleCityClick(clubs)}
                sx={{ backgroundColor: 'action.hover', typography: 'subtitle2' }}
              >
                <Checkbox 
                  checked={isCityFullySelected(clubs, selectedClubs)}
                  indeterminate={
                    clubs.some(club => selectedClubs.some(s => s?.id === club.id)) &&
                    !isCityFullySelected(clubs, selectedClubs)
                  }
                />
                {city}
              </MenuItem>
              {clubs.map((club) => (
                <MenuItem
                  key={club.id}
                  selected={selectedClubs.some(s => s?.id === club.id)}
                  onClick={() => onChangeClub(club.id)}
                  sx={{ ml: 2 }}
                >
                  <Checkbox checked={selectedClubs.some(s => s?.id === club.id)} />
                  <Image disabledEffect alt={club.label} src={club.icon} />
                  {club.shortName}
                </MenuItem>
              ))}
            </div>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
};

export default ClubPopover;

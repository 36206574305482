import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Grid, 
  Container, 
  Typography, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Button,
  Box,
  Card,
  Stack,
  InputAdornment,
  CardContent,
  CircularProgress,
  Fab,
  Zoom
} from '@mui/material';
import Page from '../../components/Page';
import { fetchPeriodData, fetchComparisonData } from '../../redux/slices/players';
import { 
  AnalyticsComparisonMetrics,
  PeriodSummary,
  PlayerCard 
} from '../../sections/@dashboard/general/analytics';
import useOrg from '../../hooks/useOrg';
import Iconify from '../../components/Iconify';

// Helper function for generating monthly periods
const generateMonthlyPeriods = () => {
  const periods = [];
  const startDate = new Date(2022, 6); // July 2022
  const currentDate = new Date();

  while (startDate <= currentDate) {
    const year = startDate.getFullYear();
    const month = startDate.getMonth() + 1;
    const monthStr = month.toString().padStart(2, '0');
    const value = `${year}-${monthStr}`;
    const label = new Date(year, month - 1).toLocaleString('default', { 
      month: 'long',
      year: 'numeric'
    });

    periods.push({ value, label });
    startDate.setMonth(startDate.getMonth() + 1);
  }

  return periods.reverse(); // Most recent first
};

// Remove XLSX import and add CSV helper functions
const convertToCSV = (data, headers) => {
  const csvRows = [];
  
  // Add headers
  csvRows.push(headers.join(','));
  
  // Add data rows
  data.forEach(item => {
    const values = headers.map(header => {
      const value = item[header] || '';
      // Escape commas and quotes in values
      return `"${String(value).replace(/"/g, '""')}"`;
    });
    csvRows.push(values.join(','));
  });
  
  return csvRows.join('\n');
};

const downloadCSV = (csvContent, filename) => {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

// Add helper function to format period string
const formatPeriodLabel = (periodStr) => {
  const [year, month] = periodStr.split('-');
  return new Date(year, month - 1).toLocaleString('default', { 
    month: 'short',
    year: 'numeric'
  });
};

const PlayerAnalyticsComparison = () => {
  const dispatch = useDispatch();
  const { periodData, comparisonData, isLoading } = useSelector((state) => state.players);
  const { allClubs } = useOrg();
  
  // State management
  const [selectedClub, setSelectedClub] = useState('');
  const [primaryPeriod, setPrimaryPeriod] = useState('');
  const [comparisonPeriod, setComparisonPeriod] = useState('');
  const [showComparison, setShowComparison] = useState(false);
  const [periodOptions] = useState(generateMonthlyPeriods());

  // Handle Fetch Results for single period
  const handleFetchResults = () => {
    if (selectedClub && primaryPeriod) {
      dispatch(fetchPeriodData({ 
        clubId: selectedClub, 
        period: primaryPeriod
      }));
    }
  };

  // Handle Compare button click for comparison
  const handleCompareClick = () => {
    if (selectedClub && primaryPeriod && comparisonPeriod) {
      dispatch(fetchComparisonData({ 
        clubId: selectedClub, 
        period1: primaryPeriod,
        period2: comparisonPeriod 
      }));
    }
  };

  // Replace Excel generation with API call
  const handleDownloadSinglePeriod = () => {
    if (!periodData?.[0]) return;
    
    const data = periodData[0];
    const csvRows = [];
    
    // Common headers
    const headers = ['Name', 'Email', 'Status', 'Subscription Count', 'Last Active'];
    
    // Left Players Section
    csvRows.push('LEFT PLAYERS');
    csvRows.push(headers.join(','));
    const leftPlayers = data.playerChanges
      ?.filter(p => p.fromStatus === 'active' && p.toStatus === 'inactive')
      .map(player => [
        `"${player.name}"`,
        `"${player.email || 'No email'}"`,
        `"${player.status}"`,
        `"${player.subscriptionCount}"`,
        `"${player.snapshotDate ? new Date(player.snapshotDate).toLocaleDateString() : 'N/A'}"`
      ].join(','));
    csvRows.push(...(leftPlayers || []));
    csvRows.push(''); // Empty row for separation

    // Joined Players Section
    csvRows.push('JOINED PLAYERS');
    csvRows.push(headers.join(','));
    const joinedPlayers = data.playerChanges
      ?.filter(p => p.fromStatus === 'inactive' && p.toStatus === 'active')
      .map(player => [
        `"${player.name}"`,
        `"${player.email || 'No email'}"`,
        `"${player.status}"`,
        `"${player.subscriptionCount}"`,
        `"${player.snapshotDate ? new Date(player.snapshotDate).toLocaleDateString() : 'N/A'}"`
      ].join(','));
    csvRows.push(...(joinedPlayers || []));
    csvRows.push(''); // Empty row for separation

    // Active Players Section
    csvRows.push('ACTIVE PLAYERS');
    csvRows.push(headers.join(','));
    const activePlayers = data.activePlayers.map(player => [
      `"${player.name}"`,
      `"${player.email || 'No email'}"`,
      `"${player.status}"`,
      `"${player.subscriptionCount}"`,
      `"${player.snapshotDate ? new Date(player.snapshotDate).toLocaleDateString() : 'N/A'}"`
    ].join(','));
    csvRows.push(...activePlayers);

    downloadCSV(csvRows.join('\n'), `Players_${primaryPeriod}.csv`);
  };

  // Handle download for comparison
  const handleDownloadComparison = () => {
    if (!comparisonData?.period1?.[0] || !comparisonData?.period2?.[0]) return;

    const period1Data = comparisonData.period1[0];
    const period2Data = comparisonData.period2[0];
    const csvRows = [];

    const period1Label = formatPeriodLabel(primaryPeriod);
    const period2Label = formatPeriodLabel(comparisonPeriod);

    // Headers
    const headers = ['Name', 'Email', 'Status', 'Subscription Count', 'Last Active'];

    // Left in Period 2
    csvRows.push(`LEFT IN ${period2Label}`);
    csvRows.push(headers.join(','));
    const leftPlayers = period1Data.activePlayers
      .filter(p1 => !period2Data.activePlayers.some(p2 => p2.id === p1.id))
      .map(player => [
        `"${player.name}"`,
        `"${player.email || 'No email'}"`,
        `"${player.status}"`,
        `"${player.subscriptionCount}"`,
        `"${player.snapshotDate ? new Date(player.snapshotDate).toLocaleDateString() : 'N/A'}"`
      ].join(','));
    csvRows.push(...leftPlayers);
    csvRows.push(''); // Empty row for separation

    // Joined in Period 2
    csvRows.push(`JOINED IN ${period2Label}`);
    csvRows.push(headers.join(','));
    const joinedPlayers = period2Data.activePlayers
      .filter(p2 => !period1Data.activePlayers.some(p1 => p1.id === p2.id))
      .map(player => [
        `"${player.name}"`,
        `"${player.email || 'No email'}"`,
        `"${player.status}"`,
        `"${player.subscriptionCount}"`,
        `"${player.snapshotDate ? new Date(player.snapshotDate).toLocaleDateString() : 'N/A'}"`
      ].join(','));
    csvRows.push(...joinedPlayers);
    csvRows.push(''); // Empty row for separation

    // Common Players
    csvRows.push('COMMON PLAYERS');
    csvRows.push([...headers, `Status in ${period1Label}`, `Status in ${period2Label}`].join(','));
    const commonPlayers = period1Data.activePlayers
      .filter(p1 => period2Data.activePlayers.some(p2 => p2.id === p1.id))
      .map(player => {
        const player2 = period2Data.activePlayers.find(p2 => p2.id === player.id);
        return [
          `"${player.name}"`,
          `"${player.email || 'No email'}"`,
          `"${player.status}"`,
          `"${player.subscriptionCount}"`,
          `"${player.snapshotDate ? new Date(player.snapshotDate).toLocaleDateString() : 'N/A'}"`,
          `"${player.status}"`,
          `"${player2.status}"`
        ].join(',');
      });
    csvRows.push(...commonPlayers);

    downloadCSV(csvRows.join('\n'), `Player_Comparison_${period1Label}_vs_${period2Label}.csv`);
  };

  // Render single period data
  const renderSinglePeriodView = () => {
    if (!periodData?.[0]) return null;

    return (
      <Card>
        <CardContent>
          <Stack spacing={3}>
            {/* Period Summary */}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <PeriodSummary data={periodData[0]} period={primaryPeriod} />
              </Grid>
            </Grid>

            {/* Player Lists */}
            <Box sx={{ overflowX: 'auto' }}>
              <Grid 
                container 
                spacing={3} 
                sx={{ 
                  minWidth: { xs: '800px', md: '100%' },
                  width: '100%'
                }}
              >
                {/* Left Column - Left Players */}
                <Grid item xs={4}>
                  <Typography variant="h6" sx={{ mb: 2, color: 'error.main' }}>
                    Left ({periodData[0].playerChanges?.filter(p => 
                      p.fromStatus === 'active' && p.toStatus === 'inactive'
                    ).length || 0})
                  </Typography>
                  <Stack spacing={1}>
                    {periodData[0].playerChanges?.filter(p => 
                      p.fromStatus === 'active' && p.toStatus === 'inactive'
                    ).map(player => (
                      <PlayerCard 
                        key={player.id}
                        player={player}
                        type="left"
                      />
                    ))}
                  </Stack>
                </Grid>

                {/* Middle Column - Active Players */}
                <Grid item xs={4}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Active ({periodData[0].activePlayers?.length || 0})
                  </Typography>
                  <Stack spacing={1}>
                    {periodData[0].activePlayers?.map(player => (
                      <PlayerCard 
                        key={player.id}
                        player={player}
                        type="common"
                      />
                    ))}
                  </Stack>
                </Grid>

                {/* Right Column - Joined Players */}
                <Grid item xs={4}>
                  <Typography variant="h6" sx={{ mb: 2, color: 'success.main' }}>
                    Joined ({periodData[0].playerChanges?.filter(p => 
                      p.fromStatus === 'inactive' && p.toStatus === 'active'
                    ).length || 0})
                  </Typography>
                  <Stack spacing={1}>
                    {periodData[0].playerChanges?.filter(p => 
                      p.fromStatus === 'inactive' && p.toStatus === 'active'
                    ).map(player => (
                      <PlayerCard 
                        key={player.id}
                        player={player}
                        type="joined"
                      />
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    );
  };

  // Modify the download button to be a FAB
  const renderDownloadFAB = () => (
    <Zoom in={!!(showComparison ? comparisonData : periodData)}>
      <Fab
        color="primary"
        sx={{
          position: 'fixed',
          bottom: 24,
          right: 24,
          zIndex: 1000
        }}
        onClick={showComparison ? handleDownloadComparison : handleDownloadSinglePeriod}
      >
        <Iconify icon="mdi:download" width={24} height={24} />
      </Fab>
    </Zoom>
  );

  return (
    <Page title="Player Analytics Comparison">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Player Analytics Comparison
        </Typography>

        <Card sx={{ p: 3, mb: 4 }}>
          <Stack spacing={3}>
            <Typography variant="h6">Club & Period Selection</Typography>

            <Box sx={{ overflowX: 'auto' }}>
              <Grid 
                container 
                spacing={3} 
                alignItems="center"
                sx={{ 
                  minWidth: { xs: '600px', md: '100%' },
                  width: '100%'
                }}
              >
                {/* Club Selection - Full width */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Select Club</InputLabel>
                    <Select
                      value={selectedClub}
                      onChange={(e) => setSelectedClub(e.target.value)}
                      label="Select Club"
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify icon="mdi:home" />
                        </InputAdornment>
                      }
                    >
                      {allClubs.map((club) => (
                        <MenuItem key={club.id} value={club.id}>
                          {club.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Period Selection Row */}
                <Grid item xs={showComparison ? 6 : 8}>
                  <FormControl fullWidth>
                    <InputLabel>Primary Period</InputLabel>
                    <Select
                      value={primaryPeriod}
                      onChange={(e) => setPrimaryPeriod(e.target.value)}
                      label="Primary Period"
                    >
                      {periodOptions.map((period) => (
                        <MenuItem key={period.value} value={period.value}>
                          {period.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {showComparison ? (
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>Comparison Period</InputLabel>
                      <Select
                        value={comparisonPeriod}
                        onChange={(e) => setComparisonPeriod(e.target.value)}
                        label="Comparison Period"
                      >
                        {periodOptions
                          .filter(period => period.value !== primaryPeriod)
                          .map((period) => (
                            <MenuItem key={period.value} value={period.value}>
                              {period.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      variant="outlined"
                      startIcon={<Iconify icon="mdi:compare" />}
                      onClick={() => setShowComparison(true)}
                    >
                      Compare With Another Period
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>

            {/* Action Button */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                startIcon={<Iconify icon={showComparison ? "mdi:compare" : "mdi:chart-bar"} />}
                onClick={showComparison ? handleCompareClick : handleFetchResults}
                disabled={!selectedClub || !primaryPeriod || (showComparison && !comparisonPeriod)}
              >
                {showComparison ? 'Compare Periods' : 'Fetch Results'}
              </Button>
            </Box>
          </Stack>
        </Card>

        {/* Results Section */}
        {isLoading ? (
          <Box sx={{ textAlign: 'center', py: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          showComparison ? (
            comparisonData && (
              <Box sx={{ overflowX: 'auto' }}>
                <Box sx={{ minWidth: { xs: '800px', md: '100%' }, width: '100%' }}>
                  <AnalyticsComparisonMetrics
                    data={comparisonData}
                    period1={primaryPeriod}
                    period2={comparisonPeriod}
                  />
                </Box>
              </Box>
            )
          ) : (
            periodData && renderSinglePeriodView()
          )
        )}

        {/* Floating Download Button */}
        {renderDownloadFAB()}
      </Container>
    </Page>
  );
};

export default PlayerAnalyticsComparison; 
import { EventDetails } from './event-details';
import { EventRules } from './event-rules';
import { SubEventList } from './event-sub-events';
import { EventFaqs } from './event-faqs';
import { EventContactDetails } from './event-contact-details';
import { EventReview } from './event-review';
import { EventRegQues } from './event-reg-ques';
import { EventNotice } from './event-notice'

const COMPONENTS_MAP = {
    'sub-events': SubEventList,
    'rules': EventRules,
    'faqs': EventFaqs,
    'contact-details': EventContactDetails,
    'registration-questions': EventRegQues,
    'notice': EventNotice
  };

export const STEP_CONFIGURATIONS = {
    'basic-details': {
        label: 'Basic Details',
        id: 'basic-details',
        buttons: [
          {
            label: 'Next',
            variant: 'contained',
            action: 'next',
            show: true,
            flex: 1,
          },
        ],
        getComponent: (ref) => <EventDetails ref={ref} />,
      },
      ...['sub-events', 'rules', 'faqs', 'contact-details', 'registration-questions','notice'].reduce((acc, key) => ({
        ...acc,
        [key]: {
         label: key.charAt(0).toUpperCase() + key.slice(1),
          id: key,
          buttons: [
            {
              label: 'Back',
              variant: 'outlined',
              action: 'back',
              show: true,
              flex: 1,
            },
            {
              label: 'Next',
              variant: 'contained',
              action: 'next',
              show: true,
              flex: 1,
            },
          ],
          getComponent: (ref) => {
            const Component = COMPONENTS_MAP[key];
            return <Component ref={ref} />;
          },
        }
      }), {}),
      'review': {
        label: 'Review & Submit',
        id: 'review',
        buttons: [
          {
            label: 'Back',
            variant: 'outlined',
            action: 'back',
            show: true,
            flex: 1,
          },
          {
            label: 'Save Draft',
            variant: 'contained',
            action: 'next',
            show: true,
            flex: 1,
          },
        ],
        getComponent: (ref) => <EventReview ref={ref} />,
      }
}

const STEP_ORDER = [
    'basic-details',
    'sub-events',
    'rules',
    'faqs',
    'contact-details',
    'registration-questions',
    'notice',
    'review'
];

export const getStepConfiguration = () => 
    STEP_ORDER.map(key => STEP_CONFIGURATIONS[key]);
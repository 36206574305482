import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
// @mui
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useTheme, styled } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, InputAdornment, Box, Grid, TextField } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';

// components
import { fetchFinanceTransactionAction, setFilterQueryParam } from '../../../../redux/slices/finance'
import Iconify from '../../../../components/Iconify';
import InputStyle from '../../../../components/InputStyle';
import { FormProvider, RHFSelect } from '../../../../components/hook-form';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 64,
  display: 'flex',
  justifyContent: 'space-between',
}));

const paymentMode = [
  { id: 'offline', label: 'Offline' },
  { id: 'online', label: 'Online' },
  { id: 'link', label: 'Link' },
]

const paymentInstrument = [
  { id: 'cash', label: 'Cash' },
  { id: 'upi', label: 'UPI' },
  { id: 'netbanking', label: 'Net Banking' },
  { id: 'paylater', label: 'Pay Later' },
  { id: 'wallet', label: 'Wallet' },
]

const paymentStatus = [
  { id: 'paid', label: 'Paid' },
  { id: 'pending', label: 'Pending' },
]

const defaultValues = {
  startDate: null,
  endDate: null,
  mode: '',
  instrument: '',
  status: ''
};

// ----------------------------------------------------------------------

const UserListToolbar = ({ numSelected, filterName, onFilterName, onDeleteUsers, isHidden }) => {
  const theme = useTheme();
  const dispatch = useDispatch()
  const isLight = theme.palette.mode === 'light';
  const [queryParam, setQueryParam] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [mode, setPaymentMode] = useState('')
  const [instrument, setPaymentInstrument] = useState('')
  const [status, setPaymentStatus] = useState('')

  useEffect(() => {
    if (queryParam.length)
      dispatch(setFilterQueryParam(queryParam))
    dispatch(fetchFinanceTransactionAction({ limit: 5, pageNo: 0 }))
  }, [queryParam])

  const methods = useForm({
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async () => {
    try {
      // reset();
      enqueueSnackbar('Filters Applied!');
      if (startDate)
        setQueryParam(`${queryParam}&startDate=${moment(startDate).format('DD/MM/YYYY')}`)
      if (endDate)
        setQueryParam(`${queryParam}&endDate=${moment(endDate).format('DD/MM/YYYY')}`)
      if (mode)
        setQueryParam(`${queryParam}&paymentMode=${mode}`)
      if (instrument)
        setQueryParam(`${queryParam}&paymentInstrument=${instrument}`)
      if (status)
        setQueryParam(`${queryParam}&status=${status}`)
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Box sx={{ display: 'grid', rowGap: 1, gridTemplateRows: 'repeat(1, 1fr)', p: 3 }}>
          <RootStyle sx={{ paddingLeft: 0 }} disableGutters>
            <InputStyle
              stretchStart={240}
              value={filterName}
              onChange={(event) => onFilterName(event.target.value)}
              placeholder="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                ),
              }}
            />

            <Tooltip title="Filter list">
              <IconButton>
                <Iconify icon={'ic:round-filter-list'} />
              </IconButton>
            </Tooltip>
          </RootStyle>
          {isHidden && <LocalizationProvider dateAdapter={AdapterMoment}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Box sx={{ display: 'grid', columnGap: 1, rowGap: 1, gridTemplateColumns: 'repeat(5, 2fr)' }}>
                    <Controller
                      name="start"
                      control={control}
                      value={startDate}
                      render={({ field }) => (
                        <DesktopDatePicker
                          {...field}
                          label="Start date"
                          inputFormat="DD/MM/YYYY"
                          value={startDate}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                          onChange={(value) => {
                            setStartDate(value)
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="end"
                      control={control}
                      value={endDate}
                      render={({ field }) => (
                        <DesktopDatePicker
                          {...field}
                          label="End date"
                          value={endDate}
                          inputFormat="DD/MM/YYYY"
                          onChange={(value) => {
                            setEndDate(value)
                          }}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                      )}
                    />
                    <RHFSelect name="paymentMode" label="Payment Mode" placeholder="Payment Mode"
                      value={mode}
                      onChange={(event) => {
                        setPaymentMode(event.target.value)
                      }}>
                      <option />
                      {paymentMode?.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.label}
                        </option>
                      ))}
                    </RHFSelect>
                    <RHFSelect name="paymentInstrument" label="Payment Instrument" placeholder="Payment Instrument"
                      value={instrument}
                      onChange={(event) => {
                        setPaymentInstrument(event.target.value)
                      }}>
                      <option value="" />
                      {paymentInstrument?.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.label}
                        </option>
                      ))}
                    </RHFSelect>
                    <RHFSelect name="status" label="Payment Status" placeholder="Payment Status"
                      value={status}
                      onChange={(event) => {
                        setPaymentStatus(event.target.value)
                      }}>
                      <option value="" />
                      {paymentStatus?.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.label}
                        </option>
                      ))}
                    </RHFSelect>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                      Save Changes
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>

            </FormProvider>
          </LocalizationProvider>
          }
        </Box>
      </Grid>
    </Grid>
  );
};

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onDeleteUsers: PropTypes.func,
};

export default UserListToolbar;

import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
  Tabs,
  Tab,
  Button,
} from '@mui/material';
import moment from 'moment';
import { format, parse, isValid, addDays } from 'date-fns'; // Import parse instead of parseISO
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../routes/paths';
import { getPayoutDetails, clearPayoutDetails } from '../../redux/slices/payouts';

const PayoutDetails = () => {
  const { payId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { payoutDetails, isLoading, error , payoutDate} = useSelector((state) => state.payouts);

  const [orderBy, setOrderBy] = useState('clubName');
  const [order, setOrder] = useState('asc');
  const [activeTab, setActiveTab] = useState(0);

  const [aggregatedOrderBy, setAggregatedOrderBy] = useState('clubName');
  const [aggregatedOrder, setAggregatedOrder] = useState('asc');

  useEffect(() => {
    if (payId) {
      dispatch(clearPayoutDetails());
      dispatch(getPayoutDetails(payId));
    } else {
      navigate(PATH_DASHBOARD.payouts.list);
    }

    return () => {
      dispatch(clearPayoutDetails());
    };
  }, [dispatch, payId, navigate]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleAggregatedRequestSort = (property) => {
    const isAsc = aggregatedOrderBy === property && aggregatedOrder === 'asc';
    setAggregatedOrder(isAsc ? 'desc' : 'asc');
    setAggregatedOrderBy(property);
  };

  const sortedTransactions = useMemo(() => {
    if (!payoutDetails?.transactions) return [];
    
    return [...payoutDetails.transactions].sort((a, b) => {
      let aValue = a[orderBy];
      let bValue = b[orderBy];

      if (orderBy === 'club.name') {
        aValue = a.club.name;
        bValue = b.club.name;
      }

      if (order === 'desc') {
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
      }
      return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
    });
  }, [payoutDetails, orderBy, order]);

  const aggregatedTransactions = useMemo(() => {
    if (!payoutDetails?.transactions) return [];

    const grouped = payoutDetails.transactions.reduce((acc, transaction) => {
      const clubId = transaction.club.id;
      if (!acc[clubId]) {
        acc[clubId] = {
          clubName: transaction.club.name,
          totalFee: 0,
          totalPayoutAmount: 0,
          totalConvenienceFee: 0,
          totalTaxes: 0,
          count: 0,
        };
      }
      acc[clubId].totalFee += transaction.txnAmount / 100;
      acc[clubId].totalPayoutAmount += transaction.amount;
      acc[clubId].totalConvenienceFee += transaction.pgConvenienceCharges / 100;
      acc[clubId].totalTaxes += transaction.tax;
      acc[clubId].count += 1;
      return acc;
    }, {});

    return Object.values(grouped);
  }, [payoutDetails]);

  const sortedAggregatedTransactions = useMemo(() => {
    if (!aggregatedTransactions.length) return [];
    
    return [...aggregatedTransactions].sort((a, b) => {
      const aValue = a[aggregatedOrderBy];
      const bValue = b[aggregatedOrderBy];

      if (aggregatedOrder === 'desc') {
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
      }
      return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
    });
  }, [aggregatedTransactions, aggregatedOrderBy, aggregatedOrder]);

  const detailedCsvData = useMemo(() => {
    if (!sortedTransactions.length) return [];
    
    const headers = ['Club Name', 'Fee', 'Payout Amount', 'Convenience Fee', 'Taxes'];
    const data = sortedTransactions.map(transaction => [
      transaction.club.name,
      transaction.txnAmount / 100,
      transaction.amount,
      transaction.pgConvenienceCharges / 100,
      transaction.tax
    ]);
    
    return [headers, ...data];
  }, [sortedTransactions]);

  const aggregatedCsvData = useMemo(() => {
    if (!sortedAggregatedTransactions.length) return [];
    
    const headers = ['Club Name', 'Total Fee', 'Total Payout Amount', 'Total Convenience Fee', 'Total Taxes', 'Transaction Count'];
    const data = sortedAggregatedTransactions.map(club => [
      club.clubName,
      club.totalFee.toFixed(2),
      club.totalPayoutAmount.toFixed(2),
      club.totalConvenienceFee.toFixed(2),
      club.totalTaxes.toFixed(2),
      club.count
    ]);
    
    return [headers, ...data];
  }, [sortedAggregatedTransactions]);

  const downloadCSV = () => {
    const csvData = activeTab === 0 ? detailedCsvData : aggregatedCsvData;
    const csvContent = csvData.map(e => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `payout_report_${activeTab === 0 ? 'detailed' : 'aggregated'}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error: {error}</Typography>;
  }

  if (!payoutDetails) {
    return <Typography>No details found</Typography>;
  }

  console.log("date trans",sortedTransactions?.[0]?.dateTime, "parsed",parse(sortedTransactions?.[0]?.dateTime,'dd-MM-yyyy',new Date()))

  return (
    <Page title="Payout Details">
      <HeaderBreadcrumbs
        heading="Payout Details"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Payouts', href: `${PATH_DASHBOARD.payouts.list}?selectedDate=${payoutDate}` },
          { name: 'Details' },
        ]}
      />

      <Card>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
          <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
            <Tab label="Detailed View" />
            <Tab label="Aggregated View" />
          </Tabs>
          <Button variant="contained" onClick={downloadCSV}>
            Download CSV
          </Button>
        </Box>

        {activeTab === 0 && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'club.name'}
                      direction={orderBy === 'club.name' ? order : 'asc'}
                      onClick={() => handleRequestSort('club.name')}
                    >
                      Club Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'txnAmount'}
                      direction={orderBy === 'txnAmount' ? order : 'asc'}
                      onClick={() => handleRequestSort('txnAmount')}
                    >
                      Fee
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'amount'}
                      direction={orderBy === 'amount' ? order : 'asc'}
                      onClick={() => handleRequestSort('amount')}
                    >
                      Payout Amount
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'pgConvenienceCharges'}
                      direction={orderBy === 'pgConvenienceCharges' ? order : 'asc'}
                      onClick={() => handleRequestSort('pgConvenienceCharges')}
                    >
                      Convenience Fee
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'tax'}
                      direction={orderBy === 'tax' ? order : 'asc'}
                      onClick={() => handleRequestSort('tax')}
                    >
                      Taxes
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedTransactions.map((transaction, index) => (
                  <TableRow key={index}>
                    <TableCell>{transaction.club.name}</TableCell>
                    <TableCell>{transaction.txnAmount / 100}</TableCell>
                    <TableCell>{transaction.amount}</TableCell>
                    <TableCell>{transaction.pgConvenienceCharges / 100}</TableCell>
                    <TableCell>{transaction.tax}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {activeTab === 1 && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={aggregatedOrderBy === 'clubName'}
                      direction={aggregatedOrderBy === 'clubName' ? aggregatedOrder : 'asc'}
                      onClick={() => handleAggregatedRequestSort('clubName')}
                    >
                      Club Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={aggregatedOrderBy === 'totalFee'}
                      direction={aggregatedOrderBy === 'totalFee' ? aggregatedOrder : 'asc'}
                      onClick={() => handleAggregatedRequestSort('totalFee')}
                    >
                      Total Fee
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={aggregatedOrderBy === 'totalPayoutAmount'}
                      direction={aggregatedOrderBy === 'totalPayoutAmount' ? aggregatedOrder : 'asc'}
                      onClick={() => handleAggregatedRequestSort('totalPayoutAmount')}
                    >
                      Total Payout Amount
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={aggregatedOrderBy === 'totalConvenienceFee'}
                      direction={aggregatedOrderBy === 'totalConvenienceFee' ? aggregatedOrder : 'asc'}
                      onClick={() => handleAggregatedRequestSort('totalConvenienceFee')}
                    >
                      Total Convenience Fee
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={aggregatedOrderBy === 'totalTaxes'}
                      direction={aggregatedOrderBy === 'totalTaxes' ? aggregatedOrder : 'asc'}
                      onClick={() => handleAggregatedRequestSort('totalTaxes')}
                    >
                      Total Taxes
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={aggregatedOrderBy === 'count'}
                      direction={aggregatedOrderBy === 'count' ? aggregatedOrder : 'asc'}
                      onClick={() => handleAggregatedRequestSort('count')}
                    >
                      Transaction Count
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedAggregatedTransactions.map((club, index) => (
                  <TableRow key={index}>
                    <TableCell>{club.clubName}</TableCell>
                    <TableCell>{club.totalFee.toFixed(2)}</TableCell>
                    <TableCell>{club.totalPayoutAmount.toFixed(2)}</TableCell>
                    <TableCell>{club.totalConvenienceFee.toFixed(2)}</TableCell>
                    <TableCell>{club.totalTaxes.toFixed(2)}</TableCell>
                    <TableCell>{club.count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Card>
    </Page>
  );
};

export default PayoutDetails;
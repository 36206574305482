import { matchPath } from 'react-router-dom';

// ----------------------------------------------------------------------

export { default as NavSectionVertical } from './vertical';
export { default as NavSectionHorizontal } from './horizontal';

export function isExternalLink(path) {
  return typeof path === 'string' && path.includes('http');
}

export function getActive(path, pathname) {
  // Return false for any non-string paths
  if (typeof path !== 'string') return false;
  if (typeof pathname !== 'string') return false;
  
  try {
    return !!matchPath({ path, end: false }, pathname);
  } catch (error) {
    console.error('Error in getActive:', error);
    return false;
  }
}

import { useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Stack, Box, Button, Card, Grid, TableContainer, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import { useCreateEvent } from './useCreateEvent';

export const EventReview = forwardRef((_, ref) => {
    const basicDetails = useSelector((state) => state.eventCreate.basicDetails);
    const subEvents = useSelector((state) => state.eventCreate.subEvents);
    const rules = useSelector((state) => state.eventCreate.rules);
    const faqs = useSelector((state) => state.eventCreate.faqs);
    const contactDetails = useSelector((state) => state.eventCreate.contactDetails);
    const regQuestions = useSelector((state) => state.eventCreate.regQuestions);
    const notices = useSelector((state) => state.eventCreate.notices || []);
    const { submitEvent } = useCreateEvent();

    const onSubmit = () => {
        const eventData = {
            ...basicDetails,
            subEvents,
            templateId: "",
            templateSections: [
                {
                    type: "faqs",
                    data: faqs
                },
                {
                    type: "rules",
                    data: rules
                },
                {
                    type: "contactDetails",
                    data: contactDetails
                },
                {
                    type: "regQuestions",
                    data: regQuestions
                },
                {
                    type: "notices",
                    data: notices
                }
            ],
        };
        submitEvent(eventData);
    };

    useImperativeHandle(ref, () => ({
        onNextStep: () => {
            onSubmit();
        },
    }));

    return (
        <Box >
            <Stack spacing={3}>
                {/* Basic Details Card */}
                <Card sx={{ p: 3 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Basic Details</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={2}>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Typography variant="subtitle2" sx={{ width: 140 }}>Name:</Typography>
                                    <Typography>{basicDetails.name}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Typography variant="subtitle2" sx={{ width: 140 }}>Event Type:</Typography>
                                    <Typography>{basicDetails.eventType}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Typography variant="subtitle2" sx={{ width: 140 }}>Event Mode:</Typography>
                                    <Typography>{basicDetails.eventMode}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Typography variant="subtitle2" sx={{ width: 140 }}>Sport:</Typography>
                                    <Typography>{basicDetails.sport}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Typography variant="subtitle2" sx={{ width: 140 }}>Images:</Typography>
                                    <Typography>{basicDetails.selectedImages.map(image => image.name).join(', ')}</Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={2}>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Typography variant="subtitle2" sx={{ width: 140 }}>Registration Period:</Typography>
                                    <Typography>
                                        {dayjs(basicDetails.registrationStartDate).format('DD/MM/YYYY')} - {' '}
                                        {dayjs(basicDetails.registrationEndDate).format('DD/MM/YYYY')}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Typography variant="subtitle2" sx={{ width: 140 }}>Event Period:</Typography>
                                    <Typography>
                                        {dayjs(basicDetails.startDate).format('DD/MM/YYYY')} - {' '}
                                        {dayjs(basicDetails.endDate).format('DD/MM/YYYY')}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Typography variant="subtitle2" sx={{ width: 140 }}>Max Registrations:</Typography>
                                    <Typography>{basicDetails.maxRegistrations}</Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <Typography variant="subtitle2" sx={{ width: 140 }}>Description:</Typography>
                                <Typography>{parse(basicDetails.description || '')}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Card>

                {/* Sub Events Card */}
                <Card sx={{ p: 3 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Sub Events</Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Gender</TableCell>
                                    <TableCell>Age</TableCell>
                                    <TableCell>Category & Price</TableCell>
                                    <TableCell>Slots</TableCell>
                                    <TableCell>Start Date</TableCell>
                                    <TableCell>Born After</TableCell>
                                    <TableCell>Born Before</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subEvents.map((event) => (
                                    <TableRow key={event.id}>
                                        <TableCell>{event.name}</TableCell>
                                        <TableCell>{event.gender}</TableCell>
                                        <TableCell>{event.age}</TableCell>
                                        <TableCell>{event.category} | ₹{event.price}</TableCell>
                                        <TableCell>{event.maxParticipants} slots</TableCell>
                                        <TableCell>{dayjs(event.startDate).format('DD/MM/YYYY')}</TableCell>
                                        <TableCell>{ event.playersBornAfter ?  dayjs(event.playersBornAfter).format('DD/MM/YYYY') : '-'}</TableCell>
                                        <TableCell>{ event.playersBornBefore ? dayjs(event.playersBornBefore).format('DD/MM/YYYY') : '-'}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>

                <Card sx={{ p: 3 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Rules</Typography>
                    <Typography>{rules.length > 0 ? parse(rules) : 'No rules added'}</Typography>
                </Card>

                <Card sx={{ p: 3 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>FAQs</Typography>
                    {faqs.map((faq, index) => (
                        <Stack key={index} spacing={1} sx={{ ml: 2, mb: 2 }}>
                            <Typography variant="subtitle2">Q: {faq.question}</Typography>
                            <Typography variant="body2">A: {faq.answer}</Typography>
                        </Stack>
                    ))}
                </Card>

                <Card sx={{ p: 3 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Registration Questions</Typography>
                    {regQuestions.map((question, index) => (
                        <Stack key={index} spacing={1} sx={{ ml: 2, mb: 2 }}>
                            <Typography variant="subtitle2">Q: {question.question}</Typography>
                            <Typography variant="body2">Answer Type: {question.answerType}</Typography>
                        </Stack>
                    ))}
                </Card>

                <Card sx={{ p: 3 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Contact Details</Typography>
                    {contactDetails.map((contact, index) => (
                        <Stack key={index} spacing={1} sx={{ mb: 2 }}>
                            <Typography variant="body1">
                                <strong>Contact Person {index + 1}:</strong> {contact.contact}
                            </Typography>
                            <Typography variant="body1">
                                <strong>Email:</strong> {contact.email}
                            </Typography>
                            <Typography variant="body1">
                                <strong>Phone:</strong> {contact.phone}
                            </Typography>
                        </Stack>
                    ))}           
                    </Card>

                <Card sx={{ p: 3 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Notices</Typography>
                    {notices.map((notice, index) => (
                        <Stack key={index} spacing={1} sx={{ mb: 2 }}>
                            <Typography variant="body1">
                                <strong>Description:</strong> {notice?.description || ''}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Valid Period:</strong> {' '}
                                    {notice?.fromDate && dayjs(notice.fromDate).format('DD/MM/YYYY')} - {' '}
                                    {notice?.toDate && dayjs(notice.toDate).format('DD/MM/YYYY')}
                                </Typography>
                            </Stack>
                        ))
                    }
                </Card>
            </Stack>
        </Box>
    );
});

export default EventReview;

import { useRef, forwardRef, useImperativeHandle } from 'react';
import { Stack, Card,Box, CardHeader, Divider, Button, TextField, IconButton } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Iconify from '../../../components/Iconify';
import { useCreateEvent } from './useCreateEvent';

export const EventContactDetails = forwardRef((_, ref) => {
  const contactDetails = useSelector((state) => state.eventCreate.contactDetails);
  const { nextStep, handleContactDetails } = useCreateEvent();
  const formRef = useRef(null);

  const methods = useForm({
    defaultValues: {
      contacts: contactDetails.length > 0 ? contactDetails : [{ name: '', phone: '', email: '' }]
    }
  });

  useImperativeHandle(ref, () => ({
    onNextStep: () => {
      methods.handleSubmit(onSubmit)();
    },
  }));

  const handleAddContact = () => {
    const currentContacts = methods.getValues('contacts');
    methods.setValue('contacts', [...currentContacts, { name: '', phone: '', email: '' }]);
  };

  const handleRemoveContact = (index) => {
    const currentContacts = methods.getValues('contacts');
    if (currentContacts.length > 1) {
      const newContacts = currentContacts.filter((_, i) => i !== index);
      methods.setValue('contacts', newContacts);
    }
  };

  const onSubmit = (data) => {
    handleContactDetails(data.contacts);
    nextStep();
  };

  return (
    <FormProvider {...methods} ref={formRef}>
      <Box>
        <CardHeader title="Contact Details" subheader="Add contact information for the event" sx={{ mb: 3 }} />
        <Divider />
        <Stack spacing={3} sx={{ p: 3 }}>
          {methods.watch('contacts')?.map((_, index) => (
            <Card key={index} sx={{ p: 3 }}>
              <Stack direction="row" justifyContent="flex-end" sx={{ mb: 2 }}>
                {methods.watch('contacts').length > 1 && (
                  <IconButton onClick={() => handleRemoveContact(index)}>
                    <Iconify icon="eva:close-fill" width={20} height={20} />
                  </IconButton>
                )}
              </Stack>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Contact Person Name"
                  {...methods.register(`contacts.${index}.name`)}
                />
                <TextField
                  fullWidth
                  label="Phone Number"
                  type="tel"
                  {...methods.register(`contacts.${index}.phone`)}
                />
                <TextField
                  fullWidth
                  label="Email Address"
                  type="email"
                  {...methods.register(`contacts.${index}.email`)}
                />
              </Stack>
            </Card>
          ))}

          <Stack direction="row" justifyContent="center">
            <Button
              startIcon={<Iconify icon="eva:plus-fill" width={20} height={20} />}
              onClick={handleAddContact}
              sx={{ width: '200px' }}
            >
              Add more contacts
            </Button>
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  );
});

export default EventContactDetails;

import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment, Paper, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';


// ----------------------------------------------------------------------

const LoginForm = () => {
  const { generateOtp, otpData, login } = useAuth();

  const isMountedRef = useIsMountedRef();

  const [step, setStep] = useState("email");

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const defaultValues = {
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const [otp, setOtp] = useState('');
  const onSubmit = async (data) => {
    try {
      if (step === "email") {
        await generateOtp(data.email);
      } else {
        await login({ emailOrMobile: data.email, referenceCode: otpData.referenceCode, otp });
      }
    } catch (error) {
      console.error(error);
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', error?.error);
      }
    }
  };
  useEffect(() => {
    if (otpData?.referenceCode) {
      setStep("otp");
    } else {
      setStep("email");
    }
  }, [otpData]);
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {step === "email" ? <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
        <RHFTextField name="email" label="Email address" />
      </Stack> : null}
      {step === "otp" && (
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
          <Typography variant="body1">Entered Email: {methods.watch("email")}</Typography>
          <IconButton onClick={() => setStep("email")} color="primary" aria-label="go back">
            <ArrowBackIcon />
          </IconButton>
        </Stack>
      )}



      {step === "otp" ? <Stack spacing={2}>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span />}
          inputType="number"
          renderInput={(props, index) => (
            <input
              {...props}
              style={{
                width: 'clamp(2.5rem, 12vw, 3.5rem)',
                height: 'clamp(2.5rem, 12vw, 3.5rem)',
                fontSize: 'clamp(1.2rem, 5vw, 1.8rem)',
                borderRadius: 4,
                border: '1px solid rgba(0,0,0,0.3)',
                margin: '0 clamp(0.2rem, 1vw, 0.5rem)',
                textAlign: 'center',
              }}
            />
          )}
        />
      </Stack> : null}
      {
        <LoadingButton disabled={step === "otp" && otp.length !== 6} fullWidth size="large" sx={{ marginTop: 2 }} type="submit" variant="contained" loading={isSubmitting}>
          {step === "email" ? 'Get Otp' : 'Login'}
        </LoadingButton>
      }
    </FormProvider>
  );
};

export default LoginForm;

import { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Stack,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip
  } from '@mui/material';
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
  import { DatePicker } from '@mui/x-date-pickers/DatePicker';
  import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
  import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import config from './config.json';
  
  
 
  export const AddEventDialog = ({ open, onClose, onAdd, mode, initialData = null }) => {
    const basicDetails = useSelector((state) => state.eventCreate.basicDetails);
    const { GENDER_OPTIONS, AGE_OPTIONS, CATEGORY_OPTIONS } = config;
    
    const defaultFormState = {
      gender: '',
      ageGroup: '',
      category: '',
      maxParticipants: '',
      price: '',
      name: '',
      birthDate: null,
      dateCondition: 'AFTER',
      startDate: basicDetails?.startDate,
      endDate: basicDetails?.endDate,
      playersBornBefore: null,
      playersBornAfter: null,
      selectedDateOption: 'eventEnd',
    };
  
    const [formData, setFormData] = useState(defaultFormState);

    useEffect(() => {
      if (initialData) {
        setFormData({
          ...initialData,
          startDate: dayjs(initialData.startDate),
          endDate: dayjs(initialData.endDate)
        });
      }
    }, [initialData]);
  
    const handleClose = () => {
      setFormData(defaultFormState);
      onClose();
    };
  
    const generateName = (gender, ageGroup, category) => {
        if (!gender || !ageGroup || !category) return '';
        let categoryPrefix = '';
        let genderPrefix = '';
        
        // Find the category label from CATEGORY_OPTIONS
        const categoryLabel = CATEGORY_OPTIONS.find(opt => opt.value === category)?.label || '';
        const genderLabel = GENDER_OPTIONS.find(opt => opt.value === gender)?.label || '';
        
        genderPrefix = gender === 'mixed' ? 'X' : 
                       (ageGroup === 'U-13' || ageGroup === 'U-15' || ageGroup === 'U-17') ? 
                       (gender === 'male' ? 'B' : 'G') : 
                       genderLabel?.charAt(0).toUpperCase();
        
        // Use the category label instead of value
        categoryPrefix = categoryLabel.charAt(0).toUpperCase(); 
        
        return `${genderPrefix}${categoryPrefix} ${ageGroup}`; 
    };
  
    const handleChange = (field) => (event) => {
      const newValue = event.target.value;
      
      setFormData(prev => {
        if (field === 'gender' || field === 'ageGroup' || field === 'category') {
          const option = field === 'gender' ? GENDER_OPTIONS : field === 'ageGroup' ? AGE_OPTIONS : CATEGORY_OPTIONS
          const newData = {
            ...prev,
            [field]: {
              value: newValue,
              label: option.find(opt => opt.value === newValue)?.label || ''
            }
          };
          // Generate name only if all required fields are present
          if (newData.gender && newData.ageGroup && newData.category) {
            newData.name = generateName(
              newData.gender.value,
              newData.ageGroup.value,
              newData.category.value
            );
          }
          
          return newData;
        }
        
        // For other fields (price, maxParticipants), use direct value
        return {
          ...prev,
          [field]: newValue
        };
      });
    };
  
    const calculateDate = (baseDate, years) => {
      if (!baseDate) return null;
      const date = dayjs(baseDate);
      return date.subtract(years, 'year').toDate();
    };
  
    const handleDateOptionClick = (option) => {
      const ageGroup = parseInt(formData.ageGroup.value.replace('U-', ''), 10);
      let newDate = null;
  
      switch(option) {
        case 'eventEnd':
            newDate = calculateDate(formData.endDate, ageGroup);
          break;
        case 'eventStart':
          newDate = calculateDate(formData.startDate, ageGroup);
          break;
        case 'custom':
          break;
        default:
          break;
      }

      setFormData(prev => ({
        ...prev,
        playersBornAfter: prev.dateCondition === 'AFTER' ? newDate : null,
        playersBornBefore: prev.dateCondition === 'BEFORE' ? newDate : null,
        selectedDateOption: option
      }));
    };
  
    const handleSubmit = () => {
      const eventData = {
        ...(mode === 'edit' && { id: formData.id }),  // Include id only in edit 
        gender: formData.gender.value,
        ageGroup: formData.ageGroup.value,
        type: formData.category.value,
        maxParticipants: formData.maxParticipants,
        price: formData.price,
        name: formData.name,
        startDate: dayjs(formData.startDate).format(),
        endDate: dayjs(formData.endDate).format(),
        playersBornAfter: formData.playersBornAfter ? dayjs(formData.playersBornAfter).format() : null,
        playersBornBefore: formData.playersBornBefore ? dayjs(formData.playersBornBefore).format() : null
      };

      onAdd(eventData);
      onClose();
      setFormData(defaultFormState);
    };
  
    return (
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>{mode === 'add' ? 'Add New Event' : 'Edit Event'}</DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <Stack direction="row" spacing={2}>
              <FormControl sx={{ width: '33%' }}>
                <InputLabel>Gender</InputLabel>
                <Select
                  value={formData.gender?.value || ''}
                  label="Gender"
                  onChange={handleChange('gender')}
                >
                  {GENDER_OPTIONS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ width: '33%' }}>
                <InputLabel>Age Group</InputLabel>
                <Select
                  value={formData.ageGroup?.value || ''}
                  label="Age Group"
                  onChange={handleChange('ageGroup')}
                >
                  {AGE_OPTIONS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ width: '33%' }}>
                <InputLabel>Category</InputLabel>
                <Select
                  value={formData.category?.value || ''}
                  label="Category"
                  onChange={handleChange('category')}
                >
                  {CATEGORY_OPTIONS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            <Stack direction="row" spacing={2}>
              <TextField
                sx={{ width: '70%' }}
                label="Name"
                value={formData.name}
                onChange={handleChange('name')}
              />
              <TextField
                sx={{ width: '30%' }}
                label="Max Participants"
                type="number"
                value={formData.maxParticipants}
                onChange={handleChange('maxParticipants')}
              />
            </Stack>

            <Stack spacing={1}>
              <Stack direction="row" spacing={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    value={formData.startDate}
                    onChange={(newValue) => {
                      setFormData(prev => ({
                        ...prev,
                        startDate: newValue
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: '50%' }} />
                    )}
                  />
                  <DatePicker
                    label="End Date"
                    value={formData.endDate}
                    onChange={(newValue) => {
                      setFormData(prev => ({
                        ...prev,
                        endDate: newValue
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: '50%' }} />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
            </Stack>

            <Stack spacing={1}>
              <Stack direction="row" spacing={2}>
                <FormControl sx={{ width: '30%' }}>
                  <InputLabel>Born</InputLabel>
                  <Select
                    value={formData.dateCondition}
                    label="Born"
                    onChange={(e) => {
                      setFormData(prev => ({
                        ...prev,
                        dateCondition: e.target.value,
                        playersBornAfter: e.target.value === 'AFTER' ? prev.playersBornBefore : null,
                        playersBornBefore: e.target.value === 'BEFORE' ? prev.playersBornAfter : null
                      }));
                    }}
                  >
                    <MenuItem value="AFTER">AFTER</MenuItem>
                    <MenuItem value="BEFORE">BEFORE</MenuItem>
                  </Select>
                </FormControl>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Birth Date"
                    value={formData.dateCondition === 'AFTER' ? formData.playersBornAfter : formData.playersBornBefore}
                    onChange={(newValue) => {
                      setFormData(prev => ({
                        ...prev,
                        playersBornAfter: prev.dateCondition === 'AFTER' ? newValue : null,
                        playersBornBefore: prev.dateCondition === 'BEFORE' ? newValue : null,
                        selectedDateOption: 'custom'
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: '70%' }} />
                    )}
                  />
                </LocalizationProvider>
              </Stack>

              {formData.ageGroup && (
                <Stack direction="row" spacing={1}>
                  <Chip
                    label={`${(formData.ageGroup.value || formData.ageGroup).replace('U-', '')} years from Event End`}
                    onClick={() => handleDateOptionClick('eventEnd')}
                    color={formData.selectedDateOption === 'eventEnd' ? 'primary' : 'default'}
                    variant={formData.selectedDateOption === 'eventEnd' ? 'filled' : 'outlined'}
                    size="small"
                  />
                  <Chip
                    label={`${(formData.ageGroup.value || formData.ageGroup).replace('U-', '')} years from Event Start`}
                    onClick={() => handleDateOptionClick('eventStart')}
                    color={formData.selectedDateOption === 'eventStart' ? 'primary' : 'default'}
                    variant={formData.selectedDateOption === 'eventStart' ? 'filled' : 'outlined'}
                    size="small"
                  />
                  <Chip
                    label="Custom Date"
                    onClick={() => handleDateOptionClick('custom')}
                    color={formData.selectedDateOption === 'custom' ? 'primary' : 'default'}
                    variant={formData.selectedDateOption === 'custom' ? 'filled' : 'outlined'}
                    size="small"
                  />
                </Stack>
              )}
            </Stack>

            <TextField
              sx={{ width: '50%' }}
              label="Price"
              type="number"
              value={formData.price}
              onChange={handleChange('price')}
              InputProps={{
                startAdornment: <span>₹</span>,
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit}>
            {mode === 'add' ? 'Add' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default AddEventDialog;
import PropTypes from 'prop-types';
import { Box, Typography, Stack } from '@mui/material';
import Iconify from '../../../../../components/Iconify';

const EmptyState = ({ icon, title, description }) => (
  <Box
    sx={{
      py: 5,
      textAlign: 'center',
      color: 'text.secondary',
      bgcolor: (theme) => theme.palette.mode === 'dark' ? 'grey.800' : 'grey.100',
      borderRadius: 1
    }}
  >
    <Stack spacing={2} alignItems="center">
      <Iconify icon={icon} width={48} height={48} />
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body2">{description}</Typography>
    </Stack>
  </Box>
);

EmptyState.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default EmptyState; 
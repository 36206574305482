import { useRef, forwardRef, useImperativeHandle } from 'react';
import { Typography, Stack, Card, Box, CardHeader, Divider, Button, TextField, IconButton, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Iconify from '../../../components/Iconify';
import { useCreateEvent } from './useCreateEvent';

const tooltips = {
  description: "A short notice that participants need to know",
  fromDate: "The date from which this notice becomes effective",
  toDate: "The date until which this notice remains valid"
};

export const EventNotice = forwardRef((_, ref) => {
  const notices = useSelector((state) => state.eventCreate.notices);
  const { nextStep, handleNotices } = useCreateEvent();
  const formRef = useRef(null);

  const methods = useForm({
    defaultValues: {
      notices: notices.length > 0 ? notices : [{ 
        description: '', 
        fromDate: null,
        toDate: null 
      }]
    }
  });

  useImperativeHandle(ref, () => ({
    onNextStep: () => {
      methods.handleSubmit(onSubmit)();
    },
  }));

  const handleAddNotice = () => {
    const currentNotices = methods.getValues('notices');
    methods.setValue('notices', [...currentNotices, { 
      description: '', 
      fromDate: null,
      toDate: null 
    }]);
  };

  const handleRemoveNotice = (index) => {
    const currentNotices = methods.getValues('notices');
    if (currentNotices.length > 1) {
      const newNotices = currentNotices.filter((_, i) => i !== index);
      methods.setValue('notices', newNotices);
    }
  };

  const onSubmit = (data) => {
    console.log(data);
    handleNotices(data.notices);
    nextStep();
  };

  return (
    <FormProvider {...methods} ref={formRef}>
      <Box>
        <CardHeader title="Event Notices" subheader="Add important announcements for your event" sx={{ mb: 3 }} />
        <Divider />
        <Stack spacing={3} sx={{ p: 3 }}>
          {methods.watch('notices')?.map((_, index) => (
            <Card key={index}>
              <Stack direction="row" justifyContent="flex-end" sx={{ m: 2 }}>
                {methods.watch('notices').length > 1 && (
                  <IconButton onClick={() => handleRemoveNotice(index)}>
                    <Iconify icon="eva:close-fill" width={20} height={20} />
                  </IconButton>
                )}
              </Stack>
              <Stack spacing={3} sx={{ px: 3, pb: 3 }}>
   
                <TextField
                  fullWidth
                  label="Notice Description"
                  multiline
                  rows={3}
                  {...methods.register(`notices.${index}.description`)}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title={tooltips.description} placement="top">
                        <IconButton size="small" sx={{ padding: 0.2 }}>
                          <Iconify icon="eva:info-fill" width={20} height={20} />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack direction="row" spacing={2}>
                <DatePicker
                    label="From"
                    value={methods.watch(`notices.${index}.fromDate`)}
                    onChange={(newValue) => {
                      methods.setValue(`notices.${index}.fromDate`, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: '50%' }} />
                    )}
                  />
                  <DatePicker
                    label="To"
                    value={methods.watch(`notices.${index}.toDate`)}
                    onChange={(newValue) => {
                      methods.setValue(`notices.${index}.toDate`, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: '50%' }} />
                    )}
                  />
                </Stack>
                </LocalizationProvider>
              </Stack>
            </Card>
          ))}

          <Stack direction="row" justifyContent="center">
            <Button
              startIcon={<Iconify icon="eva:plus-fill" width={20} height={20} />}
              onClick={handleAddNotice}
              sx={{ width: '200px' }}
            >
              Add another notice
            </Button>
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  );
});

export default EventNotice;
// routes
import { PATH_DASHBOARD, PATH_PF } from '../../../routes/paths';

import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  finance: getIcon('ic_banking'),
  playerAnalytics: getIcon('ic_analytics'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Krida',
    items: [
      { title: 'Home', path: PATH_DASHBOARD.general.home, icon: ICONS.dashboard },
      // {
      //   title: 'Plan Catalog', 
      //   path: PATH_PF.planFamily.root, 
      //   icon: ICONS.finance,
      //   children: [
      //     { title: 'Plan Families', path: PATH_PF.planFamily.root },
      //     { title: 'Plans', path: PATH_PF.planFamily.plan },
      //     { title: 'One time Charges', path: PATH_PF.planFamily.charge },
      //     { title: 'Coupons', path: PATH_PF.planFamily.coupon },
      //   ],
      // },
      {
        title: 'finance', 
        path: PATH_DASHBOARD.general.finance, 
        icon: ICONS.finance,
        children: [
          { title: 'overview', path: PATH_DASHBOARD.general.finance },
          { title: 'Payouts', path: PATH_DASHBOARD.payouts.list },
          // { title: 'all transactions', path: PATH_DASHBOARD.transactions },
        ],
      },
      { 
        title: 'player analytics', 
        path: PATH_DASHBOARD.general.playerAnalytics, 
        icon: ICONS.playerAnalytics,
        children: [
          { title: 'overview', path: PATH_DASHBOARD.general.playerAnalytics },
          { title: 'Compare Analytics', path: `${PATH_DASHBOARD.general.playerAnalytics}/diff` },
          // { title: 'all players', path: PATH_DASHBOARD.players },
        ], 
      },
      { 
        title: 'events', 
        path: PATH_DASHBOARD.general.events, 
        icon: ICONS.playerAnalytics,
        children: [
          { title: 'overview', path: PATH_DASHBOARD.general.events },
          // { title: 'Registered Users', path: PATH_DASHBOARD.registeredUsers },
          { title: 'Create Event', path: PATH_DASHBOARD.events.create },
          { title: 'Event Players', path: PATH_DASHBOARD.events.eventPlayers },
        ], 
      },
      {
        title: 'Gallery',
        path: PATH_DASHBOARD.gallery.root,
        icon: ICONS.gallery,
        children: [
          { title: 'View Gallery', path: PATH_DASHBOARD.gallery.list },
          { title: 'Upload Images', path: PATH_DASHBOARD.gallery.create },
        ],
      },
    ],
  },
];

export default navConfig;

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const getUserOrganization = createAsyncThunk('org/userOrg', async (data, thunkApi) => {
    try {
        const { data: responseData } = await axios.get(`/admin/organization`,
            { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        console.log(e)
        return thunkApi.rejectWithValue(e)
    }
});

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserOrganization } from '../actions/organizationAction';

const organizationSlice = createSlice({
    name: 'organization',
    initialState: {
        userOrgAPIStatus: 'idle',
        organization: {},
        config: [],
        clubs: [],
        error: {},
        selectedClubs: [],
        images: [],
        galleryImages: [],
        status: 'idle',
    },
    reducers: {
        setSelectedClubs: (state, action) => {
            state.selectedClubs = action.payload;
        },
        setImages: (state, action) => {
            state.images = action.payload;
        },
        addImages: (state, action) => {
            const existingImages = state.galleryImages || [];
            state.galleryImages = [...existingImages, ...action.payload];
        },
        removeImage: (state, action) => {
            state.galleryImages = state.galleryImages.filter(img => img.id !== action.payload);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUserOrganization.pending, (state) => {
            state.userOrgAPIStatus = 'loading';
        });
        builder.addCase(getUserOrganization.fulfilled, (state, action) => {
            state.userOrgAPIStatus = 'succeeded';
            const data = action?.payload?.data;
            state.organization = data;
            state.clubs = data.clubs;
            state.config = data.config;
        });
        builder.addCase(getUserOrganization.rejected, (state) => {
            state.userOrgAPIStatus = 'failed';
        });
    }
})

export const { setSelectedClubs, setImages, addImages, removeImage } = organizationSlice.actions;

export default organizationSlice.reducer;
import PropTypes from 'prop-types';
import { Card, CardContent, Typography, Grid, Box, Stack } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Iconify from '../../../../components/Iconify';

const PeriodSummary = ({ data, period }) => {
  const joinedCount = data.playerChanges?.filter(p => 
    p.fromStatus === 'inactive' && p.toStatus === 'active'
  ).length || 0;

  const leftCount = data.playerChanges?.filter(p => 
    p.fromStatus === 'active' && p.toStatus === 'inactive'
  ).length || 0;

  const activeCount = data.activePlayers?.length || 0;

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="h6">{period}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box sx={{ 
                p: 2, 
                bgcolor: (theme) => alpha(theme.palette.success.main, theme.palette.mode === 'dark' ? 0.2 : 0.1),
                borderRadius: 1 
              }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon="mdi:account-plus" sx={{ color: 'success.main' }} />
                  <Typography variant="subtitle2" sx={{ color: 'success.main' }}>
                    Joined: {joinedCount}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ 
                p: 2, 
                bgcolor: (theme) => alpha(theme.palette.error.main, theme.palette.mode === 'dark' ? 0.2 : 0.1),
                borderRadius: 1 
              }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon="mdi:account-minus" sx={{ color: 'error.main' }} />
                  <Typography variant="subtitle2" sx={{ color: 'error.main' }}>
                    Left: {leftCount}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ 
                p: 2, 
                bgcolor: (theme) => alpha(theme.palette.text.primary, theme.palette.mode === 'dark' ? 0.1 : 0.05),
                borderRadius: 1 
              }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon="mdi:account-check" sx={{ color: 'text.secondary' }} />
                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                    Active: {activeCount}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
};

PeriodSummary.propTypes = {
  data: PropTypes.object.isRequired,
  period: PropTypes.string.isRequired
};

export default PeriodSummary; 
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios'
import { store } from '../store';

const playersSlice = createSlice({
    name: 'players',
    initialState: {
        isLoading: false,
        subscriptionPaymentStatusData: {},
        loader: false,
        playerSubscriptionData: {},
        playerGrowthData: [],
        playerChurnData: [],
        playerBatchData: {},
        playerData: {},
        enrollmentData: [],
        churnData: [],
        filterQueryParam: '',
        comparisonData: null,
        periodData: null,
    },
    reducers: {
        setFilterQueryParam: (state, action) => {
            state.filterQueryParam = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPlayerAnalyticsAction.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchPlayerAnalyticsAction.fulfilled, (state, action) => {
            state.isLoading = false;
            const data = action?.payload?.data
            const type = action?.payload?.type
            if (type === 'SUBSCRIPTION') {
                state.playerSubscriptionData = data
            }
            else if (type === 'GROWTH') {
                state.playerGrowthData = data
            }
            else if (type === 'CHURN') {
                state.playerChurnData = data
            }
            else {
                state.playerBatchData = data
            }
        });
        builder.addCase(fetchPlayerAnalyticsAction.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(fetchPlayersDataAction.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchPlayersDataAction.fulfilled, (state, action) => {
            state.isLoading = false;
            const data = action?.payload?.data
            state.playerData = data
        });
        builder.addCase(fetchPlayersDataAction.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(fetchSubscriptionPaymentStatus.pending, (state) => {
            state.loader = true;
        });
        builder.addCase(fetchSubscriptionPaymentStatus.fulfilled, (state, action) => {
            state.loader = false;
            const data = action?.payload?.data;
            state.subscriptionPaymentStatusData = data;
        });
        builder.addCase(fetchSubscriptionPaymentStatus.rejected, (state) => {
            state.loader = false;
        });
        builder.addCase(fetchEnrollmentDataAction.fulfilled, (state, action) => {
            if (action.meta?.arg?.reportType === 'total_students') {
                state.enrollmentData = action?.payload?.data;
            } else if (action.meta?.arg?.reportType === 'churn') {
                state.churnData = action?.payload?.data;
            }
        });
        builder.addCase(fetchEnrollmentDataAction.pending, (state) => {
            state.isLoading = true;
            state.enrollmentData = [];
            state.churnData = [];
        });
        builder.addCase(fetchEnrollmentDataAction.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(fetchPeriodData.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchPeriodData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.periodData = action.payload.data;
        });
        builder.addCase(fetchPeriodData.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(fetchComparisonData.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchComparisonData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.comparisonData = action.payload.data;
        });
        builder.addCase(fetchComparisonData.rejected, (state) => {
            state.isLoading = false;
        });
    }
})

export const fetchPlayerAnalyticsAction = createAsyncThunk('playerAnalytics', async (params, thunkApi) => {
    try {
        const queryParam = '1'
        if (queryParam?.length) {
            const { data: responseData } = await axios.get(`/admin/report/player`,
                { headers: { 'content-type': 'application/json' }, params })
            if (responseData)
                responseData.type = params.type
            return responseData
        }
        return []
    } catch (e) {
        console.log(e)
        return thunkApi.rejectWithValue(e)
    }
});

export const fetchSubscriptionPaymentStatus = createAsyncThunk('subscriptionPaymentStatus', async (thunkApi) => {
    try {
        const queryParam = '1'
        if (queryParam?.length) {
            const { data: responseData } = await axios.get(`/admin/report/revenue?clubId=PSM_HSR&type=SUBSCRIPTION&clubId=PSM_CUCT`,
                { headers: { 'content-type': 'application/json' } })

            return responseData
        }
        return []
    } catch (e) {
        console.log(e)
        return thunkApi.rejectWithValue(e)
    }
})
export const fetchPlayersDataAction = createAsyncThunk('playerData', async (data, thunkApi) => {
    try {
        const { filterQueryParam } = store.getState().players
        const { limit, pageNo } = data
        const { data: responseData } = await axios.get(`/admin/player?clubId=PSM_HSR&pageSize=${limit}&pageNo=${pageNo}${filterQueryParam}`,
            { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        console.log(e)
        return thunkApi.rejectWithValue(e)
    }
})

export const fetchEnrollmentDataAction = createAsyncThunk('enrollmentData', async (data, thunkApi) => {
    try {
        const { data: responseData } = await axios.post(`/admin/report/enrollment-reports`, data,
            { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        console.log(e)
        return thunkApi.rejectWithValue(e)
    }
})

export const fetchPeriodData = createAsyncThunk('periodData', async (params, thunkApi) => {
    try {
        const { clubId, period } = params;
        
        const { data: responseData } = await axios.get('/admin/report/snapshot', {
            headers: { 'content-type': 'application/json' },
            params: {
                clubId,
                period
            }
        });

        return {
            data: responseData?.data
        };
    } catch (e) {
        console.log(e);
        return thunkApi.rejectWithValue(e);
    }
});

export const fetchComparisonData = createAsyncThunk('comparisonData', async (params, thunkApi) => {
    try {
        const { clubId, period1, period2 } = params;
        
        // Both API calls in parallel for better performance
        const [period1Response, period2Response] = await Promise.all([
            axios.get('/admin/report/snapshot', {
                headers: { 'content-type': 'application/json' },
                params: {
                    clubId,
                    period: period1
                }
            }),
            axios.get('/admin/report/snapshot', {
                headers: { 'content-type': 'application/json' },
                params: {
                    clubId,
                    period: period2
                }
            })
        ]);

        return {
            data: {
                period1: period1Response.data?.data,
                period2: period2Response.data?.data
            }
        };
    } catch (e) {
        console.log(e);
        return thunkApi.rejectWithValue(e);
    }
});

export const { setFilterQueryParam } = playersSlice.actions

export default playersSlice.reducer;
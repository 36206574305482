import { current } from "@reduxjs/toolkit";
import { flatten } from "lodash";

const getNextIndex = (currentIndex, length) => (currentIndex + 1) % length;
const getPreviousIndex = (currentIndex, length) => (currentIndex - 1 + length) % length;

export const getPopupData = ({ playerId, sectionId, levelSkillGroupId, skillId, type = 'start', ASSESSMENT_PLAYERS = [], ASSESSMENT }) => {
    if (ASSESSMENT_PLAYERS.length === 0) {
        return {}
    };
    const findIndexOrZero = (array, id, idKey) => {
        const index = array?.findIndex(item => item[idKey] === id);
        return index === -1 ? 0 : index;
    };
    let playerIndex = findIndexOrZero(ASSESSMENT_PLAYERS, playerId, 'playerId');
    const sectionIndex = findIndexOrZero(ASSESSMENT.sections, sectionId, 'id');

    const section = ASSESSMENT.sections[sectionIndex];
    let levelSkillGroupIndex = findIndexOrZero(section.levelSkillGroups, levelSkillGroupId, 'id');

    const getValidNextStats = ({ currentPlayerIndex, sectionIndex, levelSkillGroupIndex }) => {
        const totalPlayers = ASSESSMENT_PLAYERS.length;
        const totalSections = ASSESSMENT.sections.length;
        const totalSkillGroupsInSection = ASSESSMENT.sections[sectionIndex].levelSkillGroups.length;
        const lastPlayer = currentPlayerIndex === totalPlayers - 1;
        const firstPlayer = currentPlayerIndex === 0;
        const firstLevelSkillGroup = levelSkillGroupIndex === 0;
        const lastLevelSkillGroup = levelSkillGroupIndex === totalSkillGroupsInSection - 1;
        const firstSection = sectionIndex === 0;
        const lastSection = sectionIndex === totalSections - 1;

        return {
            lastPlayer,
            firstPlayer,
            firstLevelSkillGroup,
            lastLevelSkillGroup,
            firstSection,
            lastSection,
        };
    };

    let nextPlayerIndex;
    let nextSkillGroupIndex;
    let nextSectionIndex;
    const lastSection = sectionIndex === ASSESSMENT.sections.length - 1;
    const lastLevelSkillGroup = levelSkillGroupIndex === ASSESSMENT.sections[sectionIndex].levelSkillGroups.length - 1;
    const lastPlayer = playerIndex === ASSESSMENT_PLAYERS.length - 1;
    let subSectionLastIndex;

    const currentSection = ASSESSMENT.sections[sectionIndex];
    const { breakpointIndexes, lsgSequence } = currentSection;
    const lastSGStart = breakpointIndexes.indexOf(levelSkillGroupIndex);

    switch (type) {
        case 'start':
            nextPlayerIndex = playerIndex;
            nextSkillGroupIndex = levelSkillGroupIndex;
            nextSectionIndex = sectionIndex;
            break;

        case 'next_player':
            nextPlayerIndex = getNextIndex(playerIndex, ASSESSMENT_PLAYERS.length);
            break;

        case 'previous_player':
            nextPlayerIndex = getPreviousIndex(playerIndex, ASSESSMENT_PLAYERS.length);
            break;

        case 'next_skill_group':
            nextSkillGroupIndex = getNextIndex(levelSkillGroupIndex, ASSESSMENT.sections[sectionIndex].levelSkillGroups.length);
            break;

        case 'previous_skill_group':
            nextSkillGroupIndex = getPreviousIndex(levelSkillGroupIndex, ASSESSMENT.sections[sectionIndex].levelSkillGroups.length);
            break;

        case 'next_section':
            nextSectionIndex = getNextIndex(sectionIndex, ASSESSMENT.sections.length);
            levelSkillGroupIndex = 0;
            break;

        case 'previous_section':
            nextSectionIndex = getPreviousIndex(sectionIndex, ASSESSMENT.sections.length);
            levelSkillGroupIndex = 0;
            break;

        case 'next_flow':
            if (lastPlayer && lastSection && lastLevelSkillGroup) {
                console.log('completed')
            }
            else if (lastLevelSkillGroup && lastPlayer) {
                nextSectionIndex = getNextIndex(sectionIndex, ASSESSMENT.sections?.length);
                levelSkillGroupIndex = 0;
                playerIndex = 0;
            }
            else if (lastPlayer && breakpointIndexes.includes(levelSkillGroupIndex)) {
                nextSkillGroupIndex = getNextIndex(levelSkillGroupIndex, ASSESSMENT.sections[sectionIndex].levelSkillGroups?.length);
                playerIndex = 0;
            }
            else if (breakpointIndexes.includes(levelSkillGroupIndex)) {
                nextPlayerIndex = getNextIndex(playerIndex, ASSESSMENT_PLAYERS.length);
                if(lastSGStart <= 0 ) levelSkillGroupIndex = 0;
                else {
                    levelSkillGroupIndex = breakpointIndexes[lastSGStart - 1] + 1;
                }
            }
            else {
                nextSkillGroupIndex = getNextIndex(levelSkillGroupIndex, ASSESSMENT.sections[sectionIndex].levelSkillGroups?.length);
            }
            break;
        default:
            return {};
    }

    const LSGI = nextSkillGroupIndex !== undefined ? nextSkillGroupIndex : levelSkillGroupIndex;
    const SECI = nextSectionIndex !== undefined ? nextSectionIndex : sectionIndex;
    const PI = nextPlayerIndex !== undefined ? nextPlayerIndex : playerIndex;
    const previuosAssessmentPlayerIndex = getPreviousIndex(PI, ASSESSMENT_PLAYERS.length);
    const nextAssessmentPlayerIndex = getNextIndex(PI, ASSESSMENT_PLAYERS.length);
    const previousAssessmentSectionIndex = getPreviousIndex(SECI, ASSESSMENT.sections.length);
    const nextAssessmentSectionIndex = getNextIndex(SECI, ASSESSMENT.sections.length);
    const nextAssessmentSGIndex = getNextIndex(LSGI, ASSESSMENT.sections[SECI].levelSkillGroups.length);
    const prevAssessmentSGIndex = getPreviousIndex(LSGI, ASSESSMENT.sections[SECI].levelSkillGroups.length);

    const returnData = {
        levelSkillGroup: ASSESSMENT.sections[SECI].levelSkillGroups[LSGI],
        assessmentPlayer: ASSESSMENT_PLAYERS[PI],
        nextAssessmentPlayer: ASSESSMENT_PLAYERS[nextAssessmentPlayerIndex],
        prevAssessmentPlayer: ASSESSMENT_PLAYERS[previuosAssessmentPlayerIndex],
        section: ASSESSMENT.sections[SECI],
        playerId: ASSESSMENT_PLAYERS[PI].playerId,
        levelSkillGroupId: ASSESSMENT.sections[SECI].levelSkillGroups[LSGI].id,
        sectionId: ASSESSMENT.sections[SECI].id,
        LSGI, PI, SECI,
        nextAssessmentSection: ASSESSMENT.sections[nextAssessmentSectionIndex],
        prevAssessmentSection: ASSESSMENT.sections[previousAssessmentSectionIndex],
        nextAssessmentSG: ASSESSMENT.sections[SECI].levelSkillGroups[nextAssessmentSGIndex],
        prevAssessmentSG: ASSESSMENT.sections[SECI].levelSkillGroups[prevAssessmentSGIndex],
    }

    return {
        ...returnData,
        A_PLAYER_COUNT: ASSESSMENT_PLAYERS.length, A_SKILL_GROUP_COUNT: returnData.section.levelSkillGroups.length,
        A_SECTION_COUNT: ASSESSMENT.sections.length,
        ...getValidNextStats({
            currentPlayerIndex: PI,
            sectionIndex: SECI,
            levelSkillGroupIndex: LSGI,
        }),
    };
};

export const assessmentAdapter = ({ assessmentTemplateDetails: AD }) => {
    const assessmentDetails = {
        id: AD.id,
        name: AD.assessmentName
    };

    const uniqueSectionsMap = {}


    AD.levelSkillGroups.forEach(levelSkillGroup => {
        const assessmentSection = levelSkillGroup.assessment_section;
        if (!uniqueSectionsMap[assessmentSection.id]) {
            uniqueSectionsMap[assessmentSection.id] = {
                ...assessmentSection,
                levelSkillGroups: []
            };
        }

        uniqueSectionsMap[assessmentSection.id].levelSkillGroups.push(levelSkillGroup);
    });

    const uniqueSections = Object.values(uniqueSectionsMap);
    const sequence = AD?.assessmentTemplate?.metadata?.sequence || [];
    const flatSequence = flatten(sequence);



    const sections = uniqueSections.map((section) => {
        const sectionSequence = sequence.filter(seq => {
            if (Array.isArray(seq) && seq.length > 0) {
                return section.levelSkillGroups.find(lsg => lsg.id === seq[0].id);
            }
            return false;
        })
        let breakpointInd = -1;
        const breakpointIndexes = sectionSequence.map((seq) => {
            breakpointInd += seq.length;
            return breakpointInd;
        })
        return {
            id: section.id,
            name: section.sectionName,
            description: section.description,
            levelSkillGroups: section.levelSkillGroups.map((lsg) => {
                return {
                    id: lsg.id,
                    name: lsg.skills_group.skillGroupName,
                    level: lsg.level,
                    subLevel: lsg.subLevel,
                    description: lsg.description,
                    skillGroupId: lsg.skillGroupId,
                    ratingCriteria: lsg?.ratingCriteria || {
                        "minValue": 1,
                        "maxValue": 4,
                        grades: [{
                            value: 1,
                            label: 'A'
                        }, {
                            value: 2,
                            label: 'B'
                        }, {
                            value: 3,
                            label: 'C'
                        }, {
                            value: 4,
                            label: 'D'
                        }]
                    },
                    skills: lsg?.skills?.map(skill => {
                        return {
                            id: skill.id,
                            name: skill.skillName
                        }
                    })

                }
            }).sort((lsg1, lsg2) => {
                const ind1 = flatSequence.findIndex(lsg => lsg.id === lsg1.id);
                const ind2 = flatSequence.findIndex(lsg => lsg.id === lsg2.id);
                return ind1 - ind2;
            }),
            breakpointIndexes: breakpointIndexes || section.levelSkillGroups.map((_, index) => index),
            lsgSequence: sectionSequence,
        }
    })

    return {
        ...assessmentDetails,
        sections
    }

}
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { store } from '../store';
import axios from '../../utils/axios'

const financeSlice = createSlice({
    name: 'finance',
    initialState: {
        isLoading: false,
        financeTransactionLoader: false,
        financeAggregateData: {},
        financeAggregateExpenseData: {},
        financeGrowthData: [],
        financeInstrumentData: {},
        financeSubscriptionData: {},
        financeTransactionData: {},
        financeExpectedPData: {},
        filterQueryParam: ''
    },
    reducers: {
        setFilterQueryParam: (state, action) => {
            state.filterQueryParam = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFinanceDataAction.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchFinanceDataAction.fulfilled, (state, action) => {
            state.isLoading = false;
            const data = action?.payload?.data
            const type = action?.payload?.type
            if (type === 'AGGREGATE') {
                state.financeAggregateData = data
            }
            else if (type === 'AGGREGATE_EXPENSE') {
                state.financeAggregateExpenseData = data
            }
            else if (type === 'GROWTH') {
                state.financeGrowthData = data
            }
            else if (type === 'INSTRUMENT') {
                state.financeInstrumentData = data
            } 
            else if(type === 'EXPECTED') {
                state.financeExpectedPData = data;
            }
            else {
                state.financeSubscriptionData = data
            }
        });
        builder.addCase(fetchFinanceDataAction.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(fetchFinanceTransactionAction.pending, (state) => {
            state.financeTransactionLoader = true;
        });
        builder.addCase(fetchFinanceTransactionAction.fulfilled, (state, action) => {
            state.financeTransactionLoader = false;
            const data = action?.payload?.data
            state.financeTransactionData = data
        });
        builder.addCase(fetchFinanceTransactionAction.rejected, (state) => {
            state.financeTransactionLoader = false;
        });
    }
})

export const fetchFinanceDataAction = createAsyncThunk('finance', async (params, thunkApi) => {
    try {
        const queryParam = '1'
        if (queryParam?.length) {
            const { data: responseData } = await axios.get(`/admin/report/revenue`,
                { headers: { 'content-type': 'application/json' }, params })
            if (responseData)
                responseData.type = params.type
            return responseData
        }
        return []
    } catch (e) {
        console.log(e)
        return thunkApi.rejectWithValue(e)
    }
});

export const fetchFinanceTransactionAction = createAsyncThunk('financeTransaction', async (data, thunkApi) => {
    try {
        const { filterQueryParam } = store.getState().finance
        const { limit, pageNo } = data
        const { data: responseData } = await axios.get(`/admin/transaction-history?clubId=PSM_HSR&pageSize=${limit}&pageNo=${pageNo}${filterQueryParam}`,
            { headers: { 'content-type': 'application/json' } })
        return responseData
    } catch (e) {
        console.log(e)
        return thunkApi.rejectWithValue(e)
    }
});

export const { setFilterQueryParam } = financeSlice.actions

export default financeSlice.reducer;
import { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Stack, Checkbox, Button } from '@mui/material';
import Iconify from '../../../components/Iconify';
import { AddEventDialog } from './add-event-dialog';
import config from './config.json';


export const EventFilters = ({ filterGender, filterAge, filterCategory, onFilterChange, onAddEvent }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { GENDER_OPTIONS, AGE_OPTIONS, CATEGORY_OPTIONS } = config;
  const getOptionLabels = (selected, options) => {
    if (selected.includes('all')) {
      return 'All';
    }
    return selected
      .map(value => options.find(option => option.value === value)?.label)
      .filter(Boolean)
      .join(', ');
  };

  const handleAddClick = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Stack direction="row" spacing={2} sx={{ p: 3 }}>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Gender</InputLabel>
          <Select
            multiple
            value={filterGender || []}
            label="Gender"
            onChange={(e) => onFilterChange('gender', e.target.value)}
            renderValue={(selected) => getOptionLabels(selected, GENDER_OPTIONS)}
          >
            {GENDER_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox
                  disableRipple
                  size="small"
                  checked={filterGender?.includes(option.value)} />
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Age</InputLabel>
          <Select
            multiple
            value={filterAge || []}
            label="Age"
            onChange={(e) => onFilterChange('ageGroup', e.target.value)}
            renderValue={(selected) => getOptionLabels(selected, AGE_OPTIONS)}
          >
            {AGE_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox
                  disableRipple
                  size="small"
                  checked={filterAge?.includes(option.value)} />
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Category</InputLabel>
          <Select
            multiple
            value={filterCategory || []}
            label="Category"
            onChange={(e) => onFilterChange('category', e.target.value)}
            renderValue={(selected) => getOptionLabels(selected, CATEGORY_OPTIONS)}
          >
            {CATEGORY_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox
                  disableRipple
                  size="small"
                  checked={filterCategory?.includes(option.value)} />
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={handleAddClick}
        >
          Add Event
        </Button>
      </Stack>
      <AddEventDialog
        open={openDialog}
        onClose={handleDialogClose}
        onAdd={onAddEvent}
        mode="add"
      />
    </>
  );
};

export default EventFilters;
import { useState, useRef, useEffect } from 'react';
import { Box, Stepper, Step, StepLabel, Button, Card, Grid, Stack } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
import { PATH_DASHBOARD } from '../../../routes/paths';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { useCreateEvent } from './useCreateEvent';
import { useDispatch, useSelector } from '../../../redux/store';
import { setCurrentStepById, setSteps } from '../../../redux/slices/eventCreate';
import { getStepConfiguration } from './stepConfigurations';
import useResponsive from '../../../hooks/useResponsive';
import Scrollbar from '../../../components/Scrollbar';

const ActionButtons = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        justifyContent: 'flex-end',
        '& .MuiButton-root': {
          minWidth: 200,
        }
      }}
    >
      {children}
    </Box>
  );
};

export const EventCreateView = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { stepper: { currentStepId, steps } } = useSelector((state) => state.eventCreate);
  const { currentStep } = useCreateEvent();

  useEffect(() => {
    const stepConfigurations = getStepConfiguration();
    dispatch(setSteps(stepConfigurations));
  }, [dispatch]);

  const stepConfigurations = getStepConfiguration();
  const currentStepConfig = stepConfigurations[currentStep];

  const basicDetailsRef = useRef(null);
  const subEventRef = useRef(null);
  const rulesRef = useRef(null);
  const faqsRef = useRef(null);
  const contactDetailsRef = useRef(null);
  const reqQuesRef = useRef(null);
  const reviewRef = useRef(null);
  const noticeRef = useRef(null)

  const refs = {
    'basic-details': basicDetailsRef,
    'sub-events': subEventRef,
    'rules': rulesRef,
    'faqs': faqsRef,
    'contact-details': contactDetailsRef,
    'registration-questions': reqQuesRef,
    'notice': noticeRef,
    'review': reviewRef,
  };

  const renderNavigationButtons = () => {
    if (!currentStepConfig) return null;

    const stepButtons = currentStepConfig?.buttons;

    return (
      <Box
        sx={{
          display: 'flex',
          gap: 2,
        }}
      >
        {stepButtons
          .filter((button) => button.show)
          .map((buttonConfig, index) => {
            const { action, label, variant, disabled, flex } = buttonConfig;
            const onClick = () => {
              if (action === 'next') {
                refs[currentStepId]?.current?.onNextStep();
              } else if (action === 'back') {
                const currentIndex = currentStep;
                if (currentIndex > 0) {
                  dispatch(setCurrentStepById(steps[currentIndex - 1].id));
                }
              }
            };

            return (
              <Button
                key={index}
                variant={variant}
                onClick={onClick}
                disabled={disabled}
                sx={{
                  flex: flex || 1,
                  height: theme.spacing(6),
                  textTransform: 'none',
                }}
              >
                {label}
              </Button>
            );
          })}
      </Box>
    );
  };

  return (
    <>
      <HeaderBreadcrumbs
        heading="Create a new event"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Event', href: PATH_DASHBOARD.general.events },
          { name: 'New event' },
        ]}
        sx={{
          width: '100%',
        }}
      />

      <Box sx={{ display: 'flex', height: 'calc(100vh - 360px)', justifyContent: 'flex-end' }}>
        <Box
          sx={{
            width: '20%',
          }}
        >
          <Stepper
            activeStep={currentStep}
            orientation="vertical"
            sx={{
              '& .MuiStepLabel-root': {
                py: 0,
              },
            }}
          >
            {stepConfigurations.map((step) => (
              <Step key={step.id}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Card
          sx={{
            width: '100%',
            height: 'calc(100vh - 260px)',
            pb: 3.5
          }}
        >
          <Scrollbar 
            sx={{
              flex: 1,
              px: 2,
              height: '100%',
              '& .simplebar-content': {
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }
            }}
          >
            {currentStepConfig?.getComponent(refs[currentStepId])}
          </Scrollbar>
        </Card>
        </Box>
        <Box 
          sx={{ 
            position: 'fixed', 
            bottom: 0,
            right: 0,
            width: '80%',
            zIndex: 1000,
            mb: 1,
            p: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            backdropFilter: 'blur(6px)',
            backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
            borderRadius: 1
          }}
        >
          <ActionButtons>
            {renderNavigationButtons()}
          </ActionButtons>
        </Box>
    </>
  );
};

export default EventCreateView;



import { useSelector, useDispatch } from 'react-redux';
import { Grid, Card, CardMedia, Typography, Container, Stack, IconButton, Tooltip } from '@mui/material';
import Iconify from '../../../components/Iconify';
import { removeImage } from '../../../redux/slices/organizationSlice';

export const ListGallery = () => {
    const dispatch = useDispatch();
    const { galleryImages } = useSelector((state) => state.organization);

    const handleDeleteImage = (imageId) => {
        dispatch(removeImage(imageId));
    };

    return (
        <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
                <Typography variant="h4">
                    Gallery ({galleryImages?.length || 0} images)
                </Typography>
            </Stack>

            <Grid container spacing={3}>
                {galleryImages?.map((image) => (
                    <Grid item xs={12} sm={6} md={4} key={image.id}>
                        <Card sx={{ position: 'relative' }}>
                            <CardMedia
                                component="img"
                                height="200"
                                image={image.preview}
                                alt={image.name}
                            />
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    top: 8,
                                    right: 8,
                                    bgcolor: 'background.paper',
                                    '&:hover': {
                                        bgcolor: 'error.lighter',
                                    },
                                }}
                                size="small"
                                onClick={() => handleDeleteImage(image.id)}
                            >
                                <Tooltip title="Delete image">
                                    <Iconify icon="eva:trash-2-fill" width={20} height={20} />
                                </Tooltip>
                            </IconButton>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default ListGallery;
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Card,
  Container,
  Typography,
  Stack,
  Button,
  Snackbar,
  Alert
} from '@mui/material';
import { UploadMultiFile } from '../../../components/upload';
import { setImages, addImages } from '../../../redux/slices/organizationSlice';

export const CreateGallery = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { images } = useSelector((state) => state.organization);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleDropMultiFile = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
      id: Math.random().toString(36).substr(2, 9),
      name: file.name,
      size: file.size,
      type: file.type
    }));    
    dispatch(setImages(newFiles));
  };

  const handleUploadFiles = () => {
    if (images && images.length > 0) {
      const imagesToUpload = images.map(image => ({
        ...image,
        id: Math.random().toString(36).substr(2, 9),
      }));

      dispatch(addImages(imagesToUpload));
      dispatch(setImages([]));
      setOpenSnackbar(true);
    }
  };

  const handleRemoveFile = (inputFile) => {
    const filteredFiles = images.filter((file) => file.id !== inputFile.id);
    dispatch(setImages(filteredFiles));
  };

  const handleRemoveAllFiles = () => {
    dispatch(setImages([]));
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleViewGallery = () => {
    navigate('/dashboard/gallery/list');
  };

  return (
    <>
      <Container>
        <Card sx={{ p: 3 }}>
          <Typography variant="h4" sx={{ mb: 3 }}>
            Upload Images
          </Typography>

          <Stack spacing={3}>
            <UploadMultiFile
              showPreview
              files={images}
              onDrop={handleDropMultiFile}
              onUpload={handleUploadFiles}
              onRemove={handleRemoveFile}
              onRemoveAll={handleRemoveAllFiles}
              accept={{
                'image/*': ['.jpeg', '.jpg', '.png']
              }}
              maxSize={3145728}
            />
          </Stack>
        </Card>
      </Container>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: '100%' }}
          action={
            <Button 
              color="inherit" 
              size="small"
              onClick={handleViewGallery}
            >
              VIEW
            </Button>
          }
        >
          Images uploaded successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateGallery;

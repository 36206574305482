import { Suspense, lazy, useEffect } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// layouts
import Event from '../pages/dashboard/Event';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import RegisteredUsers from '../pages/dashboard/RegisteredUsers';
import AEF from '../sections/assessment/editor';
import { getUserOrganization } from '../redux/actions/organizationAction';
import useAuth from '../hooks/useAuth';
import PayoutList from '../pages/dashboard/PayoutList';
import PayoutDetails from '../pages/dashboard/PayoutDetails';
import EventPlayers from '../pages/dashboard/EventPlayers';
import { EventCreateView } from '../pages/dashboard/createEvent/event-create-view';
import { CreateGallery} from '../pages/dashboard/gallery/create-gallery';
import { ListGallery } from '../pages/dashboard/gallery/list-gallery'
import PlayerAnalyticsComparison from '../pages/dashboard/PlayerAnalyticsComparison';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {

  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserOrganization());
    }
  }, [isAuthenticated])


  return useRoutes([
    {
      path: 'psm',
      children: [
        {
          path: 'assessment/:assessmentId',
          element: <AuthGuard>
            <AEF />
          </AuthGuard>
        }
      ],
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'home', element: <GeneralApp /> },
        { path: 'plan-families', element: <ProductFamilyList /> }
      ],
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        // { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: '', element: <GeneralApp /> },
        { path: 'profile', element: <UserAccount /> },

        {
          path: 'playerAnalytics',
          children: [
            { element: <Navigate to="/dashboard/playerAnalytics/overview" replace />, index: true },
            { path: 'overview', element: <GeneralPlayerAnalytics /> },
            { path: 'diff', element: <PlayerAnalyticsComparison /> },
            { path: 'allPlayers', element: <PlayerList /> },
          ]
        },
        {
          path: 'finance',
          children: [
            { element: <Navigate to="/dashboard/finance/overview" replace />, index: true },
            { path: 'overview', element: <GeneralFinance /> },
            { path: 'allTransactions', element: <TransactionsList /> },
          ]
        },
        {
          path: 'payouts',
          children: [
            { path: 'list', element: <PayoutList /> },
            { path: ':payId/details', element: <PayoutDetails /> },
          ],
        },
        {
          path: 'events',
          children: [
            { element: <Navigate to="/dashboard/events/overview" replace />, index: true },
            { path: 'overview', element: <Event /> },
            { path: 'eventPlayers/:id', element: <EventPlayers /> },
            { path: 'create', element: <EventCreateView /> },
            // { path: ':name/edit', element: <Event /> },
          ]
        },
        {
          path: 'gallery',
          children: [
            { element: <Navigate to="/dashboard/gallery/list" replace />, index: true },
            { path: 'create', element: <CreateGallery /> },
            { path: 'list', element: <ListGallery /> }
          ]
        }
      ],
    },
    {
      path: '/plans',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      )
    },
    {
      path: '/charges',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      )
    },
    {
      path: '/coupons',
      element: (
        <AuthGuard>
          <GeneralApp />
        </AuthGuard>
      )
    },
    { path: 'dashboard/coming-soon', element: <ComingSoon /> },
    // {
    //   path: '*',
    //   element: <LogoOnlyLayout />,
    //   children: [
    //     { path: '404', element: <NotFound /> },
    //     { path: '*', element: <Navigate to="/404" replace /> },
    //   ],
    // },
    {
      path: 'proposal',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Proposal /> },
      ],
    },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralFinance = Loadable(lazy(() => import('../pages/dashboard/GeneralFinance')));
const GeneralPlayerAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralPlayerAnalytics')));
const TransactionsList = Loadable(lazy(() => import('../pages/dashboard/TransactionsList')));
const PlayerList = Loadable(lazy(() => import('../pages/dashboard/PlayerList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const ProductFamilyList = Loadable(lazy(() => import('../pages/productCatalog/ProductFamilyList')));
const Proposal = Loadable(lazy(() => import('../pages/Proposal')));

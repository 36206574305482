import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from '../../../redux/store';
import { 
    setCurrentStepById,
    updateSteps,
    setBasicDetails,
    setSubEvents,
    setRules,
    setFaqs,
    setContactDetails,
    setRegQuestions,
    resetEventCreate,
    setNotices,
} from '../../../redux/slices/eventCreate';

export const useCreateEvent = () => {
    const dispatch = useDispatch();
    const { stepper } = useSelector((state) => state.eventCreate);
    const { currentStepId, steps } = stepper;

    const getCurrentStepIndex = useCallback(() => 
        steps.findIndex(step => step.id === currentStepId)
    , [currentStepId, steps]);

    const nextStep = useCallback(() => {
        const currentIndex = getCurrentStepIndex();
        if (currentIndex < steps.length - 1) {
            dispatch(setCurrentStepById(steps[currentIndex + 1].id));
        }
    }, [dispatch, getCurrentStepIndex, steps]);

    const prevStep = useCallback(() => {
        const currentIndex = getCurrentStepIndex();
        if (currentIndex > 0) {
            dispatch(setCurrentStepById(steps[currentIndex - 1].id));
        }
    }, [dispatch, getCurrentStepIndex, steps]);


    const updateStepsState = useCallback((steps) => {
        dispatch(updateSteps(steps));
    }, [dispatch]);

    const handleBasicDetails = useCallback((data) => {
        dispatch(setBasicDetails(data));
    }, [dispatch]);

    const handleSubEvents = useCallback((data) => {
        dispatch(setSubEvents(data));
    }, [dispatch]);

    const handleRules = useCallback((data) => {
        dispatch(setRules(data));
    }, [dispatch]);

    const handleFaqs = useCallback((data) => {
        dispatch(setFaqs(data));
    }, [dispatch]);

    const handleContactDetails = useCallback((data) => {
        dispatch(setContactDetails(data));
    }, [dispatch]);

    const handleRegQuestions = useCallback((data) => {
        dispatch(setRegQuestions(data));
    }, [dispatch]);

    const resetForm = useCallback(() => {
        dispatch(resetEventCreate());
    }, [dispatch]);

    const handleNotices = useCallback((data) => {
        dispatch(setNotices(data));
    }, [dispatch]);

    const submitEvent = useCallback((eventData) => {
        try {
            const data = {
                ...eventData,
                subEvents: eventData.subEvents.map(({ id, ...event }) => event)
            };
            
            console.log('Event Data:', data);
            return true;
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
    }, []);

    return { 
        currentStep: getCurrentStepIndex(), 
        stepper, 
        nextStep, 
        prevStep, 
        updateStepsState,
        handleBasicDetails,
        handleSubEvents,
        handleRules,
        handleFaqs,
        handleContactDetails,
        handleRegQuestions,
        resetForm,
        submitEvent,
        handleNotices,
    };
};

import { createSlice,createAction, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import axios from 'axios';

const initialState = {
  stepper: {
    currentStepId: 'basic-details',
    steps: [],
},
  basicDetails: {
    name: '',
    eventType: '',
    eventMode: '',
    clubId: null,
    description: '',
    about: '',
    sport: '',
    startDate: null,
    endDate: null,
    registrationStartDate: null,
    registrationEndDate: null,
    maxRegistrations: '',
    selectedImages: []
  },
  subEvents: [],
  rules: [],
  faqs: [
    { question: '', answer: '' }
  ],
  contactDetails: [
    { name: '', phone: '', email: '' }
  ],
  regQuestions: [
    {
      question: '',
      answerType: 'text',
      options: [],
      isMandatory: false
    }
  ],
  notices: [
    { 
      description: '', 
      fromDate: null,
      toDate: null 
    }
  ],
};

const eventCreateSlice = createSlice({
  name: 'eventCreate',
  initialState,
  reducers: {
    setCurrentStepById: (state, action) => {
      state.stepper.currentStepId = action.payload;
  },
    updateSteps: (state, action) => {
      state.stepper.steps = action.payload;
    },
    setBasicDetails: (state, action) => {
      state.basicDetails = {
        ...state.basicDetails,
        ...action.payload,
      };
    },
    setSubEvents: (state, action) => {
      state.subEvents = action.payload;
    },
    setRules: (state, action) => {
      state.rules = action.payload;
    },
    setFaqs: (state, action) => {
      state.faqs = action.payload;
    },
    setContactDetails: (state, action) => {
      state.contactDetails = action.payload;
    },
    resetEventCreate: () => initialState,
    setRegQuestions: (state, action) => {
      state.regQuestions = action.payload;
    },
    addSubEvent: (state, action) => {
      state.subEvents.push({
        ...action.payload,
        id: state.subEvents.length + 1
      });
    },
    updateSubEvent: (state, action) => {
      const index = state.subEvents.findIndex(event => event.id === action.payload.id);
      if (index !== -1) {
        state.subEvents[index] = action.payload;
      }
    },
    deleteSubEvent: (state, action) => {
      state.subEvents = state.subEvents.filter(event => event.id !== action.payload);
    },
    bulkUpdateSubEvents: (state, action) => {
      const { ids, updates } = action.payload;
      state.subEvents = state.subEvents.map(event => 
        ids.includes(event.id) ? { ...event, ...updates } : event
      );
    },
    resetSubEvents: (state) => {
      state.subEvents = [];
    },
    setNotices: (state, action) => {
      state.notices = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(setSteps, (state, action) => {
            state.stepper.steps = action.payload;
        })
      }
});

export const {
  setActiveStep,
  setBasicDetails,
  setSubEvents,
  setRules,
  setFaqs,
  setContactDetails,
  resetEventCreate,
  setCurrentStepById,
  updateSteps,
  setRegQuestions,
  addSubEvent,
  updateSubEvent,
  deleteSubEvent,
  bulkUpdateSubEvents,
  resetSubEvents,
  setNotices,
} = eventCreateSlice.actions;

export const setSteps = createAction('eventCreate/setSteps');

export default eventCreateSlice.reducer;
// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

const MyAvatar = ({ ...other }) => {
  const { user } = useAuth();
  return (
    <Avatar
      src={user?.profileImageUrl}
      alt={user?.firstName}
      color={user?.profileImageUrl ? 'default' : createAvatar(user?.firstName).color}
      {...other}
    >
      {createAvatar(user?.firstName).name}
    </Avatar>
  );
};

export default MyAvatar;

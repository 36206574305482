import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios'


const eventSlice = createSlice({
    name: 'event',
    initialState: {
        eventData: [],
        isLoading: false,
        error: null,
        playersData: []
    },
    reducers: {
        setEventData: (state, action) => {
            state.eventData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchEventData.pending, (state) => {
            state.isLoading = true
            state.error = null
        })
        builder.addCase(fetchEventData.fulfilled, (state, action) => {
            state.isLoading = false
            state.eventData = action.payload
            state.error = null
        })
        builder.addCase(fetchEventData.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error
            state.eventData = []
        })
        builder.addCase(fetchPlayersData.pending, (state) => {
            state.isLoading = true
            state.error = null
        })
        builder.addCase(fetchPlayersData.fulfilled, (state, action) => {
            state.isLoading = false
            state.playersData = action.payload
            state.error = null
        })  
        builder.addCase(fetchPlayersData.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error
            state.playersData = []
        })
    }
})


export const fetchEventData = createAsyncThunk('event', async (params, thunkApi) => {
    try {
        const { data: responseData } = await axios.get('/admin/event?orgId=PSM')
        return responseData.data.rows
    } catch (e) {
        return thunkApi.rejectWithValue(e)
    }
})

export const fetchPlayersData = createAsyncThunk('players', async (eventId, thunkApi) => {
    try {
        const { data: responseData } = await axios.get(`/admin/event/${eventId}/players?eventId=${eventId}`)
        return responseData.data
    } catch (e) {
        return thunkApi.rejectWithValue(e)
    }
})
export const { setEventData } = eventSlice.actions

export default eventSlice.reducer

import { useTranslation } from 'react-i18next';
// '@mui
import { enUS, deDE, frFR } from '@mui/material/locale';

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'PSM CUCT',
    value: 'cuct',
    systemValue: enUS,
    icon: 'https://image.shutterstock.com/shutterstock/photos/2223144279/display_1500/stock-photo-psm-logo-template-psm-initials-letter-logo-concept-psm-letter-design-psm-letter-monogram-logo-2223144279.jpg',
  },
  {
    label: 'PSM HSR',
    value: 'hsr',
    systemValue: deDE,

    icon: 'https://image.shutterstock.com/shutterstock/photos/2223144279/display_1500/stock-photo-psm-logo-template-psm-initials-letter-logo-concept-psm-letter-design-psm-letter-monogram-logo-2223144279.jpg',
  },
  {
    label: 'PSM YELAHANKA',
    value: 'yel',
    systemValue: frFR,
    icon: 'https://image.shutterstock.com/shutterstock/photos/2223144279/display_1500/stock-photo-psm-logo-template-psm-initials-letter-logo-concept-psm-letter-design-psm-letter-monogram-logo-2223144279.jpg',
  },
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];

  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { store } from '../store';
import axios from '../../utils/axios'

const productFamilySlice = createSlice({
    name: 'productFamily',
    initialState: {
        isLoading: false,
        pf: {}
    },
    reducers: {
        setFilterQueryParam: (state, action) => {
            state.filterQueryParam = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProductFamilyAction.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchProductFamilyAction.fulfilled, (state, action) => {
            state.isLoading = false;
            state.pf = action?.payload?.data
        });
        builder.addCase(fetchProductFamilyAction.rejected, (state) => {
            state.isLoading = false;
        });
    }
})

export const fetchProductFamilyAction= createAsyncThunk('productFamily', async (type, thunkApi) => {
    try {
        const queryParam = '1'
        if (queryParam?.length) {
            const { data: responseData } = await axios.get(`/admin/p/plan_family`,
                { headers: { 'content-type': 'application/json' } })
            if (responseData)
                responseData.type = type
            return responseData
        }
        return []
    } catch (e) {
        console.log(e)
        return thunkApi.rejectWithValue(e)
    }
});

export const { setFilterQueryParam } = productFamilySlice.actions

export default productFamilySlice.reducer;